import React, { useEffect, useState } from "react";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { connect } from 'react-redux'
import "moment/locale/ka"
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { faqService } from "../models/faq/service";
import { QueryOptions } from "../helpers/query.options";
import { FAQ } from "../models/faq/faq";


const useStyles = makeStyles((theme: Theme) =>

    createStyles({
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
          },
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        gridList: {
            width: '100%',
            height: 450,
        },
        icon: {
            color: 'rgba(255, 255, 255, 0.54)',
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        leftcolumn: {
            padding: 10
        }
    })
);

const FaqPage = (props) => {
    let { id }: any = useParams();
    const classes = useStyles();
    const [items, setItems] = useState<FAQ[]>([])
    const getData = () => {
        const options = new QueryOptions()
        faqService.listWithCounter(options).then(r => {
            setItems(r.current)
        })
    }

    useEffect(() => {
        getData()

    }, [])


    return <div className={classes.root}>
        <Grid container spacing={0}>
            <Grid item xs={12} lg={3} spacing={6} style={{ padding: 20 }}></Grid>
            <Grid item xs={12} lg={6} spacing={6} style={{ padding: 20 }}>
                <div className={classes.root}>
                    {items?.map((i, index) => {
                        return  <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>{i.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                            <div dangerouslySetInnerHTML={{__html: i.text}}></div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    })
                    }
                    
                   
                </div>
            </Grid>

        </Grid>
    </div >
}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(FaqPage)