import { Dialog, Grid } from "@material-ui/core";
import { connect } from 'react-redux'
import React from "react"
import DocumentShareForm from "../forms/DocumentShareForm";
import CloseIcon from '@material-ui/icons/Close';
import { setSnackBar } from '../../models/application/applicationActions'





const DocumentShareDialog = (props) => {
    const { item, onClose, onReload } = props
    return <div>
        
        <Dialog
            maxWidth='md'
            style={{ padding: 20, minWidth: 800 }}
            open={props.onOpen} onClose={onClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <div style={{ position: 'absolute', right: 10, top: 10 }}>
                <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        onClose(false)
                    }} />
            </div>
                <div style={{ paddingTop: 20, paddingLeft: 20, paddingBottom: 0 }}>  გააზიარე დოკუმენტი</div>
                <DocumentShareForm  onReload={onReload} onClose={onClose} item={item}/>  
            
        </Dialog>
    </div>
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(DocumentShareDialog)