import { SnackbarOrigin } from "@material-ui/core"

export const SET_SNACK_BAR = "SET_SNACK_BAR"

export interface State extends SnackbarOrigin {
    open: boolean;
    text: string;
}


export const setSnackBar = (snackBar: State) => {
    return { type: SET_SNACK_BAR, content: { snackBar} } 
}
