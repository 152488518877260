import {
    Button,
    Checkbox,
    createStyles,
    Dialog,
    FormControlLabel,
    FormGroup,
    Grid,
    makeStyles,
    SnackbarOrigin, Switch, TextField, Theme, Typography
} from "@material-ui/core";
import { ErrorMessage, Form, Formik, useField } from "formik"
import { connect } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from "react"
import MomentUtils from '@date-io/moment'
import "moment/locale/ka"
import moment from "moment"
import { validationSchemas } from "../../helpers/validations"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { QueryOptions } from "../../helpers/query.options"
import CloseIcon from '@material-ui/icons/Close';
import { setSnackBar } from '../../models/application/applicationActions'
import { TemporaryTransferRequest } from "../../models/request/request";
import { temporaryTransferRequestService } from "../../models/request/service";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { EmbeddedPosition } from "../../models/user/user";
import { positionService } from "../../models/user/service";
import { branchService } from "../../models/branch/service";
import { Branch } from "../../models/branch/branch";

export interface State extends SnackbarOrigin {
    open: boolean;
    text: string;
}



const MyTextArea = ({ label, ...props }) => {
    const [field, meta] = useField(props as any);
    return (
        <>
            <TextField {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </>
    );
};


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        formControl: {
            minWidth: '100%',
        },
        selectEmpty: {
            marginTop: theme.spacing(10),
        },
    }),
);

const TemporaryTransferRequestForm = (props) => {
    moment.locale("ka")
    const { onOpen, onClose, item, setSnackBar } = props
    const [notes, setNotes] = useState([{ text: "" }])
    const [branches, setBranches] = useState<Branch[]>([])
    const [toDateOpen, setToDateOpen] = useState(false)
    const [branch, setBranch] = useState<Branch>()
    const [positions, setPositions] = useState<EmbeddedPosition[]>([])
    const [position, setPosition] = useState<EmbeddedPosition | null>()
    const classes = useStyles()
    const [localValues, setLocalValues] = useState<any>()
    const [ttr, setTtr] = useState<TemporaryTransferRequest>()


    const handleClose = () => {
        onClose({ open: false, item: null, reload: true })
    }

    const [fromDate, setFromDate] = useState<Date | null>(
        null
    );
    const [toDate, setToDate] = useState<Date | null>(
        null
    );
    useEffect(() => {
        console.log()
    }, [item])

    const getValues = (values) => {
        const noteArray = []
        for (var i = 0; i < notes.length; i++) {
            noteArray.push(values[`note_${i}`])
        }
        const ttro = new TemporaryTransferRequest()
        ttro.employee = ttr.employee.id
        ttro.responsible = ttr.responsible.id
        ttro.fromDate = values.fromDate
        if (!toDateOpen) {
            ttro.toDate = values.toDate
        }
        ttro.toPosition = values.toPosition
        ttro.fromPosition = ttr.employee.position.id
        ttro.notes = noteArray.toString()
        ttro.salary = values.salary
        ttro.fromBranch = ttr.employee.position.branch.id
        ttro.toBranch = values.toBranch
        ttro.responsibilityDischarge = values.responsibilityDischarge
        ttro.carriesOutBothDuties = values.carriesOutBothDuties
        temporaryTransferRequestService.create(ttro).then((resp: any) => {
            if (!resp.status) {
                handleClose()
                props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'ჩანაწერი დაემატა წარმატებით', open: true })
                setTimeout(() => {
                    props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                }, 5000)
            } else {
                let text = 'დაფიქსირდა შეცდომა'
                props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text, open: true })
                setTimeout(() => {
                    props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                }, 5000)
            }
        })

    }

    const getPositions = (search) => {
        const options = new QueryOptions();
        if (search && search.length) {
            options.search = search
        }
        options.pageSize = 100
        options.sort = "name"
        options.order = 'asc'
        positionService.listWithCounter(options).then(r => {
            setPositions(r.current)
        })
    }
    const getBranches = (search) => {
        const options = new QueryOptions();
        if (search && search.length) {
            options.search = search
        }
        options.pageSize = 100
        options.sort = "uid"
        options.order = 'asc'
        branchService.listWithCounter(options).then(r => {
            setBranches(r.current)
        })
    }

    useEffect(() => {
        getPositions(null)
        getBranches(null)
        if (item) {
            setTtr(null)
            setTtr(item)
        }
    }, [item])

    useEffect(() => {

    }, [localValues])

    useEffect(() => {
        if (item) {
            setLocalValues({
                responsible: item?.responsible?.fullName,
                employee: item?.employee?.fullName,
                fromDate: new Date(),
                toDate: new Date(),
                fromPosition: `${item?.employee?.position?.currentPosition?.name} (${item?.employee?.position?.currentPosition?.department?.name})`,
                fromBranch: `${item.employee?.position?.branch.uid}`,
                toBranch: "",
                responsibilityDischarge: false,
                carriesOutBothDuties: false,
                salary: '',
                toPosition: ""

            })
        }
    }, [item])

    const formik = <Formik validationSchema={validationSchemas['temporaryTransferRequest']}
        initialValues={localValues}
        onSubmit={(values)=> {
            getValues(values)
        }}>
        {({ values, handleSubmit, handleChange, handleBlur, setFieldValue, errors }: any) => (
            <Form onSubmit={handleSubmit}>
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    {console.log(errors)}
                    <Grid item lg={6}>
                        <TextField
                            style={{ width: '100%', }}
                            id="outlined-basic"
                            label={'თანამშრომელი'}
                            variant="outlined"
                            type="text"
                            disabled={true}
                            name="employee"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.employee} />
                    </Grid>
                    <Grid item lg={6}>
                        <TextField
                            style={{ width: '100%', height: 20 }}
                            id="outlined-basic"
                            label={'განაცხადზე პასუხისმგებელი'}
                            variant="outlined"
                            type="text"
                            disabled={true}
                            name="responsible"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.responsible} />
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="employee" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="responsible" >
                            {msg => <span className="error" style={{ marginTop: 10 }}>{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6}>
                        <TextField
                            style={{ width: '100%', height: 20 }}
                            id="outlined-basic"
                            label={'მიმდინარე ფილიალი'}
                            variant="outlined"
                            type="text"
                            disabled={true}
                            name="from_branch"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={`${values.fromBranch}`} />
                    </Grid>
                    <Grid item lg={6} style={{ marginTop: -16 }}>
                        {positions?.length &&
                            <Autocomplete
                                onInputChange={(event, value) => {
                                    setPosition(null)
                                }}
                                onChange={(event, value) => {
                                    const [selected] = branches.filter(option => `${option.uid}` === value)
                                    if (selected) {
                                        setFieldValue("toBranch", selected.id)

                                    } else {
                                        setBranch(null)
                                    }
                                }}
                                freeSolo
                                disableClearable
                                options={branches?.map((option) => `${option.uid}`)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="ახალი ფილიალი"
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{ ...params.InputProps, type: 'search' }}
                                    />
                                )}
                            />
                        }
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}></Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="toBranch" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6}>
                        <TextField
                            style={{ width: '100%', height: 20 }}
                            id="outlined-basic"
                            label={'მიმდინარე პოზიცია'}
                            variant="outlined"
                            type="text"
                            disabled={true}
                            name="from_position"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={`${values.fromPosition}`} />
                    </Grid>
                    <Grid item lg={6} style={{ marginTop: -16 }}>
                        {positions?.length &&
                            <Autocomplete
                                onInputChange={(event, value) => {
                                    setPosition(null)
                                }}
                                onChange={(event, value) => {
                                    const [selected] = positions.filter(option => `${option.name} (${option.department.name})` === value)
                                    if (selected) {
                                        setFieldValue("toPosition", selected.id)
                                        setPosition(selected)
                                    } else {
                                        setPosition(null)
                                    }
                                }}
                                freeSolo
                                disableClearable
                                options={positions?.map((option) => `${option.name} (${option.department.name})`)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="toPosition"
                                        label="ახალი პოზიცია"
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{ ...params.InputProps, type: 'search' }}
                                    />
                                )}
                            />
                        }
                    </Grid>
                    <Grid item lg={12} style={{ padding: 3, paddingLeft: 20 }}>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={values.responsibilityDischarge}
                                    onChange={(event, value) => {
                                        setFieldValue("responsibilityDischarge", value)
                                        console.log(event, value)
                                    }}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />}
                                label="თავისუფლდება ძირითადი პოზიციის ფუნქცია-მოვალეობებისგან"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item lg={12} style={{ padding: 3, paddingLeft: 20 }}>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={values.carriesOutBothDuties}
                                    onChange={(event, value) => {
                                        setFieldValue("carriesOutBothDuties", value)
                                        console.log(event, value)
                                    }}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />}
                                label="ასრულებს ორივე პოზიციის ფუნქცია მოვალეობებს"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}></Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="toPosition" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={3} style={{ padding: 3, paddingLeft: 20 }}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                format="DD-MM-yyyy"
                                margin="normal"
                                name="fromDate"
                                clearable
                                id="date-picker-inline"
                                label="დაწყების თარიღი"
                                value={values.fromDate}
                                onChange={(date: any) => {
                                    try {
                                        setFieldValue("fromDate", date._d)
                                        setLocalValues({ ...localValues, fromDate: date._d })
                                        setFromDate(date._d)
                                    } catch {

                                    }
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item lg={1} style={{ padding:  10 }}>
                        <div style={{fontSize: 8}}>დასრულების თარიღი ღიაა</div>
                        <Switch
                            disabled={false}
                            color="default"
                            checked={toDateOpen}
                            onChange={(event) => {
                                setToDateOpen(event.target.checked)
                            }}
                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                        />
                    </Grid>
                    <Grid item lg={3} style={{ padding: 3, paddingLeft: 20 }}>
                    {!toDateOpen && <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                format="DD-MM-yyyy"
                                margin="normal"
                                clearable
                                name="toDate"
                                id="date-picker-inline"
                                label="დასრულების თარიღი"
                                value={values.toDate}
                                onChange={(date: any) => {
                                    try {
                                        setFieldValue("toDate", date._d)

                                    } catch {

                                    }
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>}
                    </Grid>
                    
                    <Grid item lg={4}>
                        <MyTextArea
                            name="salary"
                            type="number"
                            style={{ width: '100%', marginTop: 4 }}
                            id="outlined-multiline-static"
                            label="შრომის ანაზღაურება"
                            placeholder="შეივანეთ შრომის ანაზღაურება დროებით პოზიციაზე"
                            rows={3}
                            variant="outlined" />

                    </Grid>
                    <Grid container lg={12} style={{ padding: 3, paddingLeft: 20 }}>
                        <AddCircleIcon onClick={() => {
                            if (notes.length < 4) {
                                setNotes([...notes, { text: '' }])
                            }
                        }} />
                    </Grid>
                    <Grid container lg={12} style={{ padding: 3, paddingLeft: 20 }}>
                        {notes.map((note, index) => {
                            return <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                                <MyTextArea
                                    name={`note_${index}`}
                                    style={{ width: '100%', marginTop: 4 }}
                                    id="outlined-multiline-static"
                                    label="კომენტარი"
                                    placeholder="კომენტარი"
                                    rows={3}
                                    variant="outlined" />
                            </Grid>
                        })}
                    </Grid>
                    <Grid lg={4} sm={1} md={1}></Grid>
                    <Grid container lg={8} sm={11} md={11} spacing={1} style={{ marginTop: 40 }}>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                            <Button color="secondary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                onClick={() => onClose(false)}>გაქმება</Button>
                        </Grid>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 10 }}>
                            <Button color="primary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                type="submit">გაგზავნა</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Form>
        )}
    </Formik>
    return <div>

        <Dialog
            maxWidth='md'
            style={{ padding: 20 }}
            open={props.onOpen} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <div style={{ position: 'absolute', right: 10, top: 10 }}>
                <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        handleClose()
                    }} />
            </div>
            <div style={{ padding: 20 }}>
                <Typography variant="h5" component="div">
                    პოზიციის დროებითი ცვლილების/შეთავსების ფორმა
                </Typography>
                {localValues ? formik : <div></div>}

            </div>

        </Dialog>
    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(TemporaryTransferRequestForm)