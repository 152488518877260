import React, { useState, useEffect } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button, Paper } from '@material-ui/core'
import NewsDialog from './Dialogs/NewsDialog';
import { QueryOptions } from '../helpers/query.options';
import { bannerService } from '../models/banner/service';
import { Banner } from '../models/banner/banner';


export default function CarouselComponent(props: any) {
    const [dialog, setDialog] = useState({ open: false, item: null, reload: false });
    const [items, setItems] = useState<Banner[]>([])

    const getBanners = () => {
        const options = new QueryOptions()
        options.order = "desc"
        options.sort = "created"
        bannerService.listWithCounter(options).then(r => {
            setItems(r.current)
        })
    }

    useEffect(() => {
        getBanners()
    }, [])

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };


    return (

        <div style={{ padding: 10 }}>
            <NewsDialog onOpen={dialog.open} onClose={setDialog} item={dialog.item} />
            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 1
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 1
                    }
                }}
                showDots
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {items.map((item, i) => <div key={i}>
                    <Item key={i}
                        dialog={dialog}
                        onSetDialog={setDialog}
                        item={item} /></div>)}


            </Carousel>
        </div>
    )
}

function Item(props: any) {
    return (
        <div style={{ padding: 10, position: 'relative' }} >
            <h2>{props.item.name}</h2>
            <img src={props.item.image} style={{ width: '100%' }} />
            <Button
                onClick={() => {
                    props.onSetDialog({ ...props.dialog, item: props.item, open: true })
                }}
                className="checkButton"
                color="secondary"
                style={{ position: 'absolute', bottom: 30, right: 30 }}>
                დეტალები
            </Button>
        </div>
    )
}