import { Resource } from '../resource'
import { Serializer } from '../serializer'
import { Training } from './training'



export class TrainingSerializer implements Serializer {
    fromJson(json: any) {
        const tr = new Training()
        tr.name = json.name
        tr.videoId = json.video_id
        return tr
    }
    toJson(item: any) {
        return {
            
        }
    }
}
