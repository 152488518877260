import { Branch } from '../branch/branch'
import { City } from '../city/city'
import { NameIdItem } from '../common/common'
import { Country } from '../country/country'
import { Resource } from '../resource'

export class UserRole extends Resource {
	name: string | any
	permissions: UserPermissions
}

export class UserPermissions extends Resource {
	read: boolean | any
	write:boolean | any
	delete: boolean | any
	update: boolean | any
	export : boolean | any
}
export class PersonalInfo extends Resource {
	birthDate: any
	citizenship: string 
	maritalStatus: string
}

export class EmbeddedPosition extends Resource  {
	department: NameIdItem
	name: string
}
export class PreviousPosition extends Resource  {
	caption: string
	dateFrom: Date
	dateTo: Date
	info: string
	position: EmbeddedPosition
}
export class Position extends Resource {
	currentPosition: EmbeddedPosition
	level: string
	branch: Branch
	previousPositions: PreviousPosition[]
}
export class User extends Resource {
    phone: string
	image: any
	birthDayYear: number
	photo: string
	contact: Contact
	yearsInCompany: number
	personalInfo: PersonalInfo 
	position: Position
	fullName: string
	joined: Date
    personalId: string
    birthDate: string
    firstName: string
    lastName: string
    email: string
    password: string
    confirmPassword: string
	accessToken: string
	roles : UserRole[] 
	name: string 
	title: string 
	selected?: boolean
	children: User[] 
	naviEmployee: NaviEmployee
	

	setToken(token: string) {
		this.accessToken = token
		this.save()
	}

	update(accessToken: string) {
		this.accessToken = accessToken
	}
    
	delete() {
		localStorage.removeItem("currentUser")
		this.accessToken = undefined
	}

	save() {
		localStorage.setItem("currentUser", JSON.stringify(this))
	}

  	async load() {
		let parsedUser: any = await localStorage.getItem("currentUser")
		if (parsedUser) {
			parsedUser = JSON.parse(parsedUser)
		}
		if (parsedUser) {
			this.id = parsedUser.id
			this.phone = parsedUser.phone
			this.firstName = parsedUser.firstName
			this.fullName = parsedUser.fullName
			this.photo = parsedUser.photo
			this.lastName = parsedUser.lastName
			this.personalInfo = parsedUser.personalInfo
			this.accessToken = parsedUser.accessToken
			this.personalId = parsedUser.personalId
			this.position = parsedUser.position
			this.joined = parsedUser.joined
			this.naviEmployee = parsedUser.naviEmployee
			this.roles = parsedUser.roles
			this.contact = parsedUser.contact
			this.yearsInCompany = parsedUser.yearsInCompany
			this.email = parsedUser.email
		}

		return this
	}

	updateUser(user: User) {
		this.id = user?.id
		this.personalId = user?.personalId
		this.firstName = user.firstName
		this.accessToken = user.accessToken
		this.lastName = user.lastName
		this.fullName = user.fullName
		this.phone = user.phone
		this.naviEmployee = user.naviEmployee
		this.fullName = user.fullName
		this.photo = user.photo
		this.contact = user.contact
		this.position = user.position
		this.joined = user.joined
		this.yearsInCompany = user.yearsInCompany
		this.personalInfo = user.personalInfo
		this.roles = user.roles
		this.email = user.email
		this.save()
		return this
	}
}

export class Contact extends Resource{
    companyAddress:  EmbeddedAddress | any
    corporateEmail:  string | any
    extension:       string | any
    officePhone:     string | any
    personalAddress: EmbeddedAddress | any
    personalEmail:   string | any
    personalMobile:  string | any
    personalPhone:   string | any
	showPersonalEmail: boolean | any
	showPersonalPhone: boolean | any
}
export class NaviEmployee extends Resource{
    address: string
	birthDate: string
	birthDay: string
	gender: NameIdItem
	isActive: boolean
	jobTitle: string
	jobTitleCode: NameIdItem
	joined: string
	orgUnitCode: NameIdItem
	orgUnitName: string
	
}



export class EmbeddedAddress extends Resource{
    city:        string;
    country:     Country;
    postalCode: string;
    street:      string;
    street2:     string;
}

