import {
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select, Switch, TextField, Theme
} from "@material-ui/core";
import { ErrorMessage, Form, Formik, useField } from "formik"
import { connect } from 'react-redux'

import React, { useEffect, useState } from "react"
import MomentUtils from '@date-io/moment'
import { validationSchemas } from "../../helpers/validations"

import { QueryOptions } from "../../helpers/query.options"
import { setSnackBar } from '../../models/application/applicationActions'

import { CV, CVEducation, Degree, FieldOfStudy } from "../../models/cv/cv";
import { countryService } from "../../models/country/service";
import { Country } from "../../models/country/country";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "moment/locale/ka"
import moment from "moment"
import { cvService, degreeService, fieldOfStudyService } from "../../models/cv/service";

const EducationForm = (props) => {
    moment.locale("ka")
    const [locale, setLocale] = useState("ka")
    const { onClose, item, setSnackBar } = props
    const [degrees, setDegrees] = useState<Array<Degree>>([])
    const [mode, setMode] = useState('edit')
    const [fieldsOfStudy, setFieldsOfStudy] = useState<Array<FieldOfStudy>>([])
    const [countries, setCountries] = useState<Array<Country>>([])
    const [localValues, setLocalValues] = useState(null as any)
    let error: string = null

    const getValues = (values) => {

        const edu = new CVEducation()
        edu.startDate = `${values.startDate.getFullYear()}-${String(Number(values.startDate.getMonth() + 1))}`
        edu.endDate = `${values.endDate.getFullYear()}-${String(Number(values.endDate.getMonth() + 1))}`
        edu.degree = { id: values.degree, name: '' }
        edu.fieldOfStudy = values.fieldOfStudy
        edu.school = values.school
        edu.country = { uid: values.country, id: values.country, name: '' }
        edu.id = item ? item.id : null
        edu.grade = values.grade
        edu.isCurrent = values.isCurrent
        const cv = new CV()
        cv.educations = [edu]

        if (mode === 'create') {
            cvService.create(cv).then(resp => {
                if (resp.id) {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'გამოცდილება წარმატებით დაემატა.', open: true })
                    setTimeout(() => {
                        setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                    }, 5000)
                    onClose()
                }
            })
        } else {
            cvService.updateNoForm(cv).then(resp => {
                if (resp.id) {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'გამოცდილება წარმატებით განახლდა.', open: true })
                    setTimeout(() => {
                        setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                    }, 5000)
                    onClose()
                }
            })
        }
    }

    useEffect(() => {
        setLocalValues({ name: '' })
        if (!item) {
            setMode('create')
        }
    }, [item])
    useEffect(() => {
        const options = new QueryOptions()
        options.pageSize = 140
        degreeService.list(options).then(d => {
            setDegrees(d.results)
        })
        fieldOfStudyService.list(options).then(d => {
            setFieldsOfStudy(d.results)
        })
        countryService.listWithCounter(options).then(e => {
            setCountries(e.current)
        })

    }, [])


    const formik = <Formik validationSchema={validationSchemas['education']}
        initialValues={item ? {
            ...item,
            country: item.country.id,
            degree: item.degree.id,
            fieldOfStudy: item.fieldOfStudy,
            startDate: new Date(`${item.startDate}-17T03:24:00`),
            endDate: new Date(`${item.endDate}-17T03:24:00`),
        } : {
            isCurrent: false,
            startDate: new Date(`${new Date().getUTCFullYear() - 1}-12-17T03:24:00`),
            endDate: new Date()
        }}
        onSubmit={getValues}>
        {({ values, handleSubmit, handleChange, setFieldValue, handleBlur, errors }: any) => (
            <Form onSubmit={(evt) => {
                console.log(errors, values)
                const [e] = Object.keys(errors);
                if (e) {
                    error = e
                } else {
                    error = null
                }
                handleSubmit(evt)
            }}>
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    <Grid item lg={7}>
                        <TextField
                            style={{ width: '100%', }}
                            id="outlined-basic"
                            label="სასწავლო დაწესებულება"
                            variant="outlined"
                            type="text"
                            name="school"
                            value={values.school}
                            onChange={handleChange}
                            onBlur={handleBlur} />
                    </Grid>
                    <Grid item lg={5} style={{}}>
                        <FormControl variant="outlined" style={{ minWidth: '100%' }}>
                            <TextField
                                style={{ width: '100%', }}
                                id="outlined-basic"
                                label="ფაკულტეტი"
                                variant="outlined"
                                type="text"
                                name="fieldOfStudy"
                                value={values.fieldOfStudy}
                                onChange={handleChange}
                                onBlur={handleBlur} />
                        </FormControl>
                    </Grid>

                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="school" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="country" >
                            {msg => <span className="error" style={{ marginTop: 10 }}>{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={4} style={{}}>
                        <FormControl variant="outlined" style={{ minWidth: '100%' }}>
                            <InputLabel id="select-degree">წოდება</InputLabel>
                            <Select
                                name="degree"
                                labelId="degree-label"
                                id="degree"
                                value={values.degree}
                                onChange={(event, value) => {
                                    const [selected] = degrees.filter(option => `${option.id}` === event.target.value)
                                    if (selected) {
                                        setFieldValue("degree", selected.id)
                                    }
                                }}
                                label="განათლების ხარისხი">
                                {degrees.map((option, index) => {
                                    return <MenuItem value={option.id} key={index} style={{ color: option.id === 'empty' ? 'darkred' : 'black' }}>
                                        <em>{option.name}</em>
                                    </MenuItem>

                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={4} style={{}}>
                        <FormControl variant="outlined" style={{ minWidth: '100%' }}>
                            <InputLabel id="select-country">ქვეყანა</InputLabel>
                            <Select
                                name="country"
                                labelId="country-label"
                                id="country"
                                value={item ? item.country.id : values.country}
                                onChange={(event, value) => {
                                    const [selected] = countries.filter(option => `${option.id}` === event.target.value)
                                    if (selected) {
                                        setFieldValue("country", selected.id)
                                    }
                                }}
                                label="ქვეყანა">
                                {countries.map((option, index) => {
                                    return <MenuItem value={option.id} key={index} style={{ color: option.id === 'empty' ? 'darkred' : 'black' }}>
                                        <em>{option.name}</em>
                                    </MenuItem>

                                })}
                            </Select>
                        </FormControl>

                    </Grid>
                    <Grid item lg={4}>
                        <TextField
                            style={{ width: '100%', height: 20 }}
                            id="outlined-basic"
                            label="საშუალო ქულა"
                            variant="outlined"
                            type="number"
                            name="grade"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.grade} />
                    </Grid>

                    <Grid item lg={12} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="grade" >
                            {msg => <span className="error" style={{ marginTop: 10 }}>{msg}</span>}
                        </ErrorMessage>
                    </Grid>

                    <Grid item lg={3} style={{ padding: 3, paddingLeft: 20 }}>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
                            <DatePicker
                                maxDate={new Date()}
                                minDate={new Date('1950-12-17T03:24:00')}
                                views={['year', "month"]}
                                label="დაწყების თარიღი"
                                value={values.startDate}
                                onChange={(value) => {
                                    setFieldValue("startDate", value.toDate())
                                }}
                                animateYearScrolling
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item lg={2} style={{ padding: 3, paddingLeft: 20 }}>
                        <FormGroup>
                            <FormControlLabel control={<Switch
                                value={values.isCurrent}
                                checked={values.isCurrent}
                                onChange={(e, value) => {
                                    e.persist()
                                    setFieldValue("isCurrent", value)
                                }}
                            />} label="მიმდინარეა " />

                        </FormGroup>
                    </Grid>


                    <Grid item lg={3} style={{ padding: 3, paddingLeft: 20 }}>
                        {!values.isCurrent ?
                            <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
                                <DatePicker
                                    maxDate={new Date()}
                                    minDate={new Date('1950-12-17T03:24:00')}
                                    views={["month", 'year']}
                                    label="დასრულების თარიღი"
                                    value={values.endDate}
                                    onChange={(value) => {
                                        setFieldValue("endDate", value.toDate())
                                    }}
                                    animateYearScrolling
                                />
                            </MuiPickersUtilsProvider> : <></>}
                    </Grid>
                    {error && <Grid container lg={12} sm={11} md={11} >
                        <span style={{ color: 'red' }}>
                            {errors[error]}
                        </span>
                    </Grid>}
                    <Grid lg={4} sm={1} md={1}></Grid>
                    <Grid lg={4} sm={1} md={1}></Grid>
                    <Grid container lg={8} sm={11} md={11} style={{ padding: 20, marginTop: 20 }}>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                            <Button color="secondary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                onClick={() => onClose(false)}>გაქმება</Button>
                        </Grid>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 10 }}>
                            <Button color="primary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                type="submit">დადასტურება</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Form>
        )}
    </Formik>
    return <div style={{ padding: 20 }}>
        {localValues ? formik : <div></div>}
    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(EducationForm)