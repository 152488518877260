import React from 'react';

import { Button, Card, CardContent, CardHeader, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 20,
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  }),
);

export default function BranchCard(props) {
  const classes = useStyles();

  const { clicked, icon, branch } = props
  const history = useHistory();
  return <Card className={classes.root} >

    <CardHeader
      title={branch?.uid}
      subheader={`${branch?.address?.street}`}
    />
    <CardContent className="emp-card">
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        <b>ქალაქი: </b>
        {branch?.address?.city.name}
      </Typography>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        <b>ტელ.: </b>
        {branch?.phone}
      </Typography>
      <Typography className={classes.title} color="textSecondary">
        <b>ღიაა: </b>
        {branch?.openTime} - {branch?.closeTime}
      </Typography>
      <br />
      <Button
        color="primary"
        style={{ backgroundColor: '#b9262d', color: 'white' }}
        onClick={() => {
          history.push(`/branch/${branch.id}`)
        }} >
        დეტალები
      </Button>
    </CardContent>
  </Card>

}

