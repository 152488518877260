


import { ResourceProvider } from "../../providers/resource.provider"
import { Banner } from "./banner"
import { BannerSerializer } from "./serialize"

export class BannerProvider extends ResourceProvider<Banner> {
    constructor() {
        super("rest_banner", new BannerSerializer())
    }
}

export const bannerService = new BannerProvider()

