import React, { useEffect, useState } from "react";
import { register } from "../models/user/authActions";
import { connect } from 'react-redux'
import OrgChart from "../components/OrgChart/OrgChart";
import { QueryOptions } from "../helpers/query.options";
import { chartService } from "../models/user/service";


const OrganisationalChart = (props) => {
    const [data, setData] = useState([])
    const getTreeForChart = () => {
        chartService.list(new QueryOptions()).then(users => {
            if (users?.results) {
                setData(users.results[0])
            }
        })
    }
    useEffect(() => {
        getTreeForChart()
    }, [])
    return (<OrgChart data={data} />);
}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { register })(OrganisationalChart)