import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import "moment/locale/ka"
import moment from "moment"

import { connect } from 'react-redux'
import { Button, Grid, TableHead, Typography } from '@material-ui/core';
import { setSnackBar } from '../../models/application/applicationActions'
import { environment } from '../../environment/environment';

import { ConfirmationDialog } from '../Dialogs/ConfirmationDialog';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteIcon from '@material-ui/icons/Delete';
import { RequestedVacationDay, VacationRequest } from '../../models/request/request';



const BASE_URL = environment.basePath


const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});



moment.locale("ka")

const RequestedVacationDaysTable = (props) => {
    const { days, onDelete, item, user } = props
    const classes = useStyles2();
    const [items, setItems] = useState<RequestedVacationDay[]>([])
    const [localItem, setItem] = useState<VacationRequest>()
    const [dialog, setDialog] = useState({ open: false, item: null, reload: false });
    const [confirmation, setConfirmation] = useState({ open: false, text: '', callback: null })
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    let emptyRows

    useEffect(() => {
        if (days) {
            setItems(days)
        }
    }, [days])
    useEffect(() => {
        if (item) {
            setItem(item)
        }
    }, [item])

    useEffect(() => {
        if (items.length) {
            emptyRows = rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);
        }
    }, [items])

    const tableContent = (<TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
                <TableRow>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        მოთხოვნის სახეობა
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', width: 200 }}>
                        თარიღი
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        დადასტურებულია
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        გამოყენებულია
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        გაუქმებულია
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        შეცვლა
                    </TableCell>

                </TableRow>
            </TableHead>
            <TableBody>
                {
                    (rowsPerPage > 0
                        ? items?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : items
                    ).map((item: RequestedVacationDay) => (
                        <TableRow key={item.id}>
                            <TableCell component="th" scope="row" style={{ width: 200 }}>
                                {localItem?.type?.name}
                            </TableCell>
                            <TableCell style={{ width: 60 }} align="center">
                                {item.dayTimeStamp}
                            </TableCell>
                            <TableCell style={{ width: 60 }} align="center">
                                {item.isConfirmed ? 'დადასტურებულია' : "დასადასტურებელია"}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {item.isConsumed ? "გამოყენებულია" : "გამოუყენებელია"}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {item.isCanceled ? 'გაუქმებულია' : "არაა გაუქმებულია"}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">

                            {!item.isCanceled && localItem?.employee.id === user.id && <DeleteIcon style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setConfirmation({
                                            text: 'ნამდვილად გსურთ დადასტურებული შვებულების დღის გაუქმება?', open: true, callback: (value) => {
                                                if (value) {
                                                    onDelete(item.id)
                                                }
                                                setConfirmation({ text: '', callback: null, open: false })
                                            }
                                        })
                                    }} />
                            }
                            </TableCell>


                        </TableRow>
                    ))}
                {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </TableContainer>)

    return <Fragment>
        <ConfirmationDialog
            title="ჩანაწერის გაუქმება"
            open={confirmation.open}
            text={confirmation.text} callback={confirmation.callback} />

        {items.length > 0 ? <div>
            <h4 style={{padding: 10}}>
                დადასტურებული მოთხოვნილი შვებულების დღეები</h4><br/>
            {tableContent}</div> : <div style={{ marginTop: 20 }}>
            
            <Typography variant="h6" style={{padding: 40}}>
                ჩანაწერები არ მოიძებნა
            </Typography>

        </div>}

    </Fragment >
}

const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user
    }
}

export default connect(mapStateToProps, { setSnackBar })(RequestedVacationDaysTable)