import { Address } from "../address/address";
import { NameIdItem } from "../common/common";
import { Resource } from "../resource";
import { User } from "../user/user";

export class Branch extends Resource {
  navId: string;
  uid: string;
  address: Address;
  type: NameIdItem;
  openTime: string;
  workingDayGroup: string;
  closeTime: string;
  phone: string;
  managers: User[];
  salesManager: User;
}

export class BranchWorkingHoursTable extends Resource {
  name: string;
  owner: User;
}
