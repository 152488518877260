import React, { Fragment, useEffect, useState } from "react"
import { Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from "@material-ui/core"


import { connect } from 'react-redux'

import '../../components/styles.css'
import { authenticate } from '../../models/user/authActions'
import LoginForm from "../forms/loginForm"


const LoginFormDialog = (props: any) => {
    const { onOpen, onClose, authenticate, goToRegister } = props
    const [open, setOpen] = useState(false as any)
    const handleCloseDialog = () => { onClose(false) }
    const handleClose = () => {
        onClose(false)
    }
    useEffect(() => {
        setOpen(onOpen)
    }, [onOpen])

    const getValues = (values) => {
        authenticate(values.email, values.password)
    }

    return (
        <Dialog open={props.onOpen} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <DialogTitle id="form-dialog-title"> შესვლა</DialogTitle>
            <DialogContent className="dialog">
                <DialogContentText>
                    <Typography variant="h6" onClick={() => { goToRegister(true) }}>
                        გაიარეთ რეგისტრაცია
                    </Typography>
                </DialogContentText>
                <LoginForm dialogMode={true}/>
            </DialogContent>

        </Dialog>)
}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { authenticate })(LoginFormDialog)