import { NameIdItem } from "../common/common";
import { Resource } from "../resource";
import { EmbeddedPosition, User } from "../user/user";
import {Address} from '../address/address'

export class Vacancy extends Resource {
    responsible: User
    created: string | any
    address:          Address;
    arrangementType: NameIdItem;
    notes:            any[];
    position:         EmbeddedPosition
    requiredSkills: NameIdItem[]
    salary:           NameIdItem
    salaryAmount: string
    userSubscribed: boolean
}
export class VacancySubscription extends Resource {
    created: string | any
    vacancy: Vacancy
    empooyee: User 
}
