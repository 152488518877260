import { createStyles, Dialog, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { connect } from 'react-redux'
import React, { useEffect, useState } from "react"
import CloseIcon from '@material-ui/icons/Close';
import { setSnackBar } from '../../models/application/applicationActions'
import { RequestedVacationDay, VacationRequest } from "../../models/request/request";
import RequestedVacationDaysTable from "../Table/RequestedVacationDaysTable";


const RequestedVacationDaysDialog = (props) => {
    const {onClose, items, item, onDelete} = props
    const [localItems, setLocalItems] = useState<RequestedVacationDay[]>()
    const [localItem, setLocalItem] = useState<VacationRequest>()

    useEffect(() => {
        if (items) {
            setLocalItems(items)
        }
    }, [items])
    
    useEffect(() => {
        console.log(item)
        if (item) {
            setLocalItem(item)
        }
    }, [item])

    const handleClose = () => {
        onClose({ open: false, item: null, reload: true })
    }

    return <div>
        <Dialog
            maxWidth='md'
            style={{ padding: 20 }}
            open={props.onOpen} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <div style={{ position: 'absolute', right: 10, top: 10 }}>
                <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        handleClose()
                    }} />
            </div>
            <RequestedVacationDaysTable days={localItems} item={localItem} onDelete={onDelete}/>
            
        </Dialog>
    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(RequestedVacationDaysDialog)