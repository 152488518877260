import { BranchSerializer } from "../branch/serializer";
import {
  NameIdItemSerializer,
  nameIdItemSerializer,
} from "../common/serializer";
import { Serializer } from "../serializer";
import { UserSerializer } from "../user/serializer";
import { WorkingDayHoursSerializer } from "../WorkingDayHour/serializer";
import {
  BusinessTripRequest,
  DismissRequest,
  DocumentRequest,
  FromPosition,
  RequestConfirmation,
  RequestedVacationDay,
  TemporaryTransferRequest,
  TransferRequest,
  VacationRequest,
} from "./request";

export class DismissRequestSerializer implements Serializer {
  fromJson(json: any) {
    const dr = new DismissRequest();
    dr.reason = json.reason;
    if (json.employee) {
      const es = new UserSerializer();
      dr.employee = es.fromJson(json.employee);
    }
    if (json.status) {
      dr.confirmationStatus = nameIdItemSerializer.fromJson(json.status);
    }

    if (json.responsible) {
      const es = new UserSerializer();
      dr.responsible = es.fromJson(json.responsible);
    }
    if (json.from_position) {
      const fps = new FromPositionSerializer();
      dr.fromPosition = fps.fromJson(json.from_position);
    }
    dr.id = json.id;
    dr.dueDate = json.due_date;
    dr.created = json.created;
    return dr;
  }

  toJson(item: DismissRequest) {
    const obj: any = {
      employee: item.employee,
      responsible: item.responsible,
      due_date: item.dueDate,
      reason: item.reason,
    };
    return obj;
  }
}
export class DocumentRequestSerializer implements Serializer {
  fromJson(json: any) {
    const dr = new DocumentRequest();
    dr.text = json.text;
    if (json.employee) {
      const es = new UserSerializer();
      dr.employee = es.fromJson(json.employee);
    }

    if (json.responsible) {
      const es = new UserSerializer();
      dr.responsible = es.fromJson(json.responsible);
    }
    dr.created = json.created;
    return dr;
  }

  toJson(item: DocumentRequest) {
    const obj: any = {
      text: item.text,
      employee: item.employee.id,
      with_salary: item.withSalary,
    };
    return obj;
  }
}
export class TemporaryTransferRequestSerializer implements Serializer {
  fromJson(json: any) {
    const ttr = new TemporaryTransferRequest();
    ttr.id = json.id;
    if (json.employee) {
      const es = new UserSerializer();
      ttr.employee = es.fromJson(json.employee);
    }
    if (json.status) {
      ttr.confirmationStatus = nameIdItemSerializer.fromJson(json.status);
    }
    if (json.salary) {
      ttr.salary = json.salary;
    }

    ttr.carriesOutBothDuties = json.carries_out_both_duties;
    ttr.responsibilityDischarge = json.responsibility_discharge;

    if (json.responsible) {
      const es = new UserSerializer();
      ttr.responsible = es.fromJson(json.responsible);
    }
    if (json.from_position) {
      const fps = new FromPositionSerializer();
      ttr.fromPosition = fps.fromJson(json.from_position);
    }
    if (json.from_branch) {
      const bs = new BranchSerializer();
      ttr.fromBranch = bs.fromJson(json.from_branch);
    }
    if (json.to_position) {
      const fps = new FromPositionSerializer();
      ttr.toPosition = fps.fromJson(json.to_position);
    }
    if (json.to_branch) {
      const bs = new BranchSerializer();
      ttr.toBranch = bs.fromJson(json.to_branch);
    }
    if (json.notes) {
      ttr.notes = json.notes;
    }

    ttr.fromDate = new Date(json.from_date * 1000);
    ttr.toDate = new Date(json.to_date * 1000);
    ttr.created = new Date(json.created * 1000);
    return ttr;
  }

  toJson(item: TemporaryTransferRequest) {
    const obj: any = {
      employee: item.employee,
      responsible: item.responsible,
      from_date: item.fromDate,
      to_date: item.toDate,
      to_position: item.toPosition,
      from_position: item.fromPosition,
      salary: item.salary,
      from_branch: item.fromBranch,
      to_branch: item.toBranch,
      notes: item.notes,
      responsibility_discharge: item.responsibilityDischarge,
      carries_out_both_duties: item.carriesOutBothDuties,
    };
    return obj;
  }
}
export class TransferRequestSerializer implements Serializer {
  fromJson(json: any) {
    const ttr = new TransferRequest();
    ttr.id = json.id;
    ttr.reason = json.reason;
    ttr.salary = json.salary;
    if (json.employee) {
      const es = new UserSerializer();
      ttr.employee = es.fromJson(json.employee);
    }
    if (json.status) {
      ttr.confirmationStatus = nameIdItemSerializer.fromJson(json.status);
    }
    if (json.responsible) {
      const es = new UserSerializer();
      ttr.responsible = es.fromJson(json.responsible);
    }
    if (json.from_position) {
      const fps = new FromPositionSerializer();
      ttr.fromPosition = fps.fromJson(json.from_position);
    }
    if (json.from_branch) {
      const bs = new BranchSerializer();
      ttr.fromBranch = bs.fromJson(json.from_branch);
    }
    if (json.to_position) {
      const fps = new FromPositionSerializer();
      ttr.toPosition = fps.fromJson(json.to_position);
    }
    if (json.to_branch) {
      const bs = new BranchSerializer();
      ttr.toBranch = bs.fromJson(json.to_branch);
    }

    ttr.dueDate = json.due_date;
    ttr.created = json.created;
    return ttr;
  }

  toJson(item: TransferRequest) {
    const obj: any = {
      employee: item.employee,
      responsible: item.responsible,
      due_date: item.dueDate,
      to_position: item.toPosition,
      from_position: item.fromPosition,
      from_branch: item.fromBranch,
      to_branch: item.toBranch,
      salary: item.salary,
      reason: item.reason,
    };
    return obj;
  }
}
export class BusinessTripRequestSerializer implements Serializer {
  fromJson(json: any) {
    const r = new BusinessTripRequest();
    r.id = json.id;
    r.reason = json.reason;
    r.transportationFee = json.transportation_fee;
    r.gasolineCost = json.gasoline_cost;
    r.transport = nameIdItemSerializer.fromJson(json.transport);
    r.id = json.id;
    r.created = json.created;
    r.fromDate = json.from_date;
    r.destination = json.destination;
    r.toDate = json.to_date;

    if (json.status) {
      r.confirmationStatus = nameIdItemSerializer.fromJson(json.status);
    }
    if (json.employee) {
      const es = new UserSerializer();
      r.employee = es.fromJson(json.employee);
    }
    r.accommodationFee = json.accommodation_fee;
    if (json.status) {
      r.confirmationStatus = nameIdItemSerializer.fromJson(json.status);
    }
    if (json.responsible) {
      const es = new UserSerializer();
      r.responsible = es.fromJson(json.responsible);
    }
    return r;
  }

  toJson(item: BusinessTripRequest) {
    const obj: any = {
      from_date: item.fromDate,
      to_date: item.toDate,
      destination: item.destination,
      employee: item.employee,
      reason: item.reason,
      gasoline_cost: item.gasolineCost,
      transport: item.transport,
      transportation_fee: item.transportationFee,
      accommodation_fee: item.accommodationFee,
    };
    return obj;
  }
}

export class FromPositionSerializer implements Serializer {
  fromJson(json: any) {
    const dr = new FromPosition();
    if (json.branch) {
      const branchSerializer = new BranchSerializer();
      dr.branch = branchSerializer.fromJson(json.branch);
    }
    if (json.supervisor) {
      const userSerializer = new UserSerializer();
      dr.supervisor = userSerializer.fromJson(json.supervisor);
    }
    return dr;
  }

  toJson(item: any) {
    const obj: any = {};
    return obj;
  }
}

export class VacationRequestSerializer implements Serializer {
  fromJson(json: any) {
    const vr = new VacationRequest();
    if (json.type) {
      const ser = new NameIdItemSerializer();
      vr.type = ser.fromJson(json.type);
    }
    if (json.status) {
      vr.confirmationStatus = nameIdItemSerializer.fromJson(json.status);
    }
    if (json.employee) {
      const userSerialiser = new UserSerializer();
      vr.employee = userSerialiser.fromJson(json.employee);
    }
    vr.requestedDays = json.requested_days;
    vr.daysLeft = json.days_left;
    vr.totalDays = json.total_days;
    vr.fromDate = json.from_date;
    vr.toDate = json.to_date;
    vr.id = json.id;
    vr.workingDays = json.working_days;
    vr.created = json.created;
    if (json.used_holidays) {
      const serializer = new WorkingDayHoursSerializer();
      vr.usedHolidays = json.used_holidays.map((uh) => serializer.fromJson(uh));
    }
    return vr;
  }

  toJson(item: VacationRequest) {
    const obj: any = {
      validation: item.validation,
      from_date: item.fromDate,
      to_date: item.toDate,
      employee: item.employee,
      type: item.type,
      chosen_days: item.chosenDays,
      used_holiday: item.usedHolidays?.length
        ? item.usedHolidays.toString()
        : null,
    };
    return obj;
  }
}

export class RequestConfirmationSerializer implements Serializer {
  fromJson(json: any) {
    const requestConfirmation = new RequestConfirmation();
    requestConfirmation.status = json.confirmation_status;
    requestConfirmation.requestId = json?.request?.id;
    return requestConfirmation;
  }

  toJson(item: RequestConfirmation) {
    const obj: any = {
      status: item.status,
      request_id: item.requestId,
    };
    return obj;
  }
}

export class RequestedVacationDaySerializer implements Serializer {
  fromJson(json: any) {
    const day = new RequestedVacationDay();
    day.dayTimeStamp = json.day_time_stamp;
    day.id = json.id;
    day.isCanceled = json.is_canceled;
    day.isConsumed = json.is_consumed;
    day.isConfirmed = json.is_confirmed;

    return day;
  }

  toJson(item: RequestedVacationDay) {
    const obj: any = {
      is_canceled: item.isCanceled,
    };
    return obj;
  }
}
