import { Serializer } from '../serializer'
import { Location } from '../location/location'

export class LocationSerializer implements Serializer {
    fromJson(json: any) {
        const location = new Location()
        location.lat = json.lat
        location.lng = json.lng
        return location
    }

    toJson(item: any) {
        const obj: any = {}
        return obj
    }
}