import { ResourceProvider } from "../../providers/resource.provider";
import { NameIdItem } from "../common/common";
import { NameIdItemSerializer } from "../common/serializer";
import { BusinessTripRequest, DismissRequest, DocumentRequest, RequestConfirmation, RequestedVacationDay, TemporaryTransferRequest, TransferRequest, VacationRequest } from "./request";
import { DismissRequestSerializer, 
        RequestConfirmationSerializer, 
        DocumentRequestSerializer,
        TemporaryTransferRequestSerializer, 
        TransferRequestSerializer, 
        BusinessTripRequestSerializer,
        VacationRequestSerializer, 
        RequestedVacationDaySerializer} from "./serializer";



export class DismissRequestProvider extends ResourceProvider<DismissRequest> {
    constructor() {
        super("rest_dismiss_request", new DismissRequestSerializer())
    }
}
export class DocumentRequestProvider extends ResourceProvider<DocumentRequest> {
    constructor() {
        super("rest_document_request", new DocumentRequestSerializer())
    }
}
export class BusinessTripRequestProvider extends ResourceProvider<BusinessTripRequest> {
    constructor() {
        super("rest_business_trip_request", new BusinessTripRequestSerializer())
    }
}
export class RequestConfirmationStatusProvider extends ResourceProvider<NameIdItem> {
    constructor() {
        super("rest_request_confirmation_type", new NameIdItemSerializer())
    }
}
export class TemporaryTransferRequestProvider extends ResourceProvider<TemporaryTransferRequest> {
    constructor() {
        super("rest_temporary_transfer_request", new TemporaryTransferRequestSerializer())
    }
}
export class TransferRequestProvider extends ResourceProvider<TransferRequest> {
    constructor() {
        super("rest_transfer_request", new TransferRequestSerializer())
    }
}
export class VacationRequestTypeProvider extends ResourceProvider<NameIdItem> {
    constructor() {
        super("rest_vacation_type", new NameIdItemSerializer())
    }
}
export class VacationRequestProvider extends ResourceProvider<VacationRequest> {
    constructor() {
        super("rest_vacation_request", new VacationRequestSerializer())
    }
}
export class TemporaryTransferRequestConfirmationProvider extends ResourceProvider<RequestConfirmation> {
    constructor() {
        super("rest_temporary_transfer_request_confirmation", new RequestConfirmationSerializer())
    }
}
export class TransferRequestConfirmationProvider extends ResourceProvider<RequestConfirmation> {
    constructor() {
        super("rest_transfer_request_confirmation", new RequestConfirmationSerializer())
    }
}
export class DismissRequestConfirmationProvider extends ResourceProvider<RequestConfirmation> {
    constructor() {
        super("rest_dismiss_request_confirmation", new RequestConfirmationSerializer())
    }
}
export class VacationRequestConfirmationProvider extends ResourceProvider<RequestConfirmation> {
    constructor() {
        super("rest_vacation_request_confirmation", new RequestConfirmationSerializer())
    }
}
export class BusinessTripRequestConfirmationProvider extends ResourceProvider<RequestConfirmation> {
    constructor() {
        super("rest_business_trip_request_confirmation", new RequestConfirmationSerializer())
    }
}
export class TranportProvider extends ResourceProvider<NameIdItem> {
    constructor() {
        super("rest_transport_type", new NameIdItemSerializer())
    }
}
export class RequestedVacationDayProvider extends ResourceProvider<RequestedVacationDay> {
    constructor() {
        super("rest_requested_vacation_day", new RequestedVacationDaySerializer())
    }
}


export const dismissRequestService = new DismissRequestProvider()
export const documentRequestService = new DocumentRequestProvider()
export const businessTripRequestService = new BusinessTripRequestProvider()
export const vacationRequestTypeService = new VacationRequestTypeProvider()
export const vacationRequestService = new VacationRequestProvider()
export const temporaryTransferRequestService = new TemporaryTransferRequestProvider()
export const transferRequestService = new TransferRequestProvider()
export const tranportService = new TranportProvider()

export const requestConfirmationStatusService = new RequestConfirmationStatusProvider()
export const temporaryTransferRequestConfirmationService = new TemporaryTransferRequestConfirmationProvider()
export const transferRequestConfirmationService = new TransferRequestConfirmationProvider()
export const dismissRequestConfirmationService = new DismissRequestConfirmationProvider()
export const vacationRequestConfirmationService = new VacationRequestConfirmationProvider()
export const businessTripRequestConfirmationService = new BusinessTripRequestConfirmationProvider()

export const requestedVacationDayService = new RequestedVacationDayProvider()
