import { Dialog, Button } from "@material-ui/core"
import { connect } from 'react-redux'
import React from "react"

import { setSnackBar } from '../../models/application/applicationActions'


const TermsDialog = (props) => {
    const { item, onCloseTerms, onReload } = props
    return <div>

        <Dialog
            maxWidth='md'
            style={{ padding: 20, minWidth: 800 }}
            open={props.onOpen} onClose={onCloseTerms} aria-labelledby="form-dialog-title" disableBackdropClick={true}>

            <div style={{ paddingTop: 20, paddingLeft: 20, paddingBottom: 0 }}>
                <p style={{ textAlign: 'center' }}><strong>თანხმობის ფორმა (ICR - პორტალი)</strong></p>

                <p>წინამდებარე თანხმობის ღილაკზე დაჭერით მომხმარებელი აცხადებს, რომ იგი სრულად გაეცნო და ეთანხმება:</p>

                <ul>
                    <li>&nbsp; შპს &bdquo;აი სი არ ტრეიდის&ldquo; (შემდგომში კომპანია) ინტერნეტგვერდის, შემდგომში &bdquo;ICR - პორტალი&ldquo;-ის მოხმარების და ფუნქციონირების წესებსა და პირობებს</li>
                    <li>&nbsp;&bdquo;ICR - პორტალი&ldquo;-ს პოლიტიკას პერსონალური მონაცემების დაცვასთან დაკავშირებით</li>
                    <li>&nbsp;&bdquo;ICR - პორტალ&ldquo;-ზე განთავსებული ნებისმიერი სახის ინფორმაციის კონფიდენციალურობის დაცვასთან დაკავშირებით;</li>
                    <li>&nbsp;&bdquo;ICR - პორტალი&ldquo;-ს მხოლოდ სამუშაო და მარკეტინგული მიზნებიდან გამომდინარე გამოყენებასთან დაკავშირებით;</li>
                    <li>&nbsp;ამასთანავე - ადასტურებს შემდეგს:</li>
                </ul>

                <ol>
                    <li>იგი ინფორმირებულია, რომ &bdquo;ICR - პორტალი&ldquo;-ს მიზნებიდან გამომდინარე განხორციელდება ყველა პერსონალური მონაცემის და დოკუმენტაციისდამუშავება, რომლის განთავსებაც და შეყვანაც მოხდება მომხმარებლის მიერ, ამასთანავე ისეთი სახის ინფორმაციის, რომლის შევსებაც და განთავსებაც მოხდება კომპანიის უფლებამოსილი თანამშრომლების მიერ და თანხმობას აცხადებს აღნიშნულ მოქმედებებთან დაკავშირებით (შემდგომში &bdquo;თანხმობა&ldquo;).</li>
                    <li>&nbsp;იგი იღებს პასუხისმგებლობას მის მიერ განთავსებული ინფორმაციის სისწორესა და პორტალზე ატვირთული დოკუმენტების ავთენტურობასთან დაკავშირებით.</li>
                </ol>

            </div>
            <div style={{ width: '50%', marginLeft: '25%', marginBottom: 30}} onClick={() => {onCloseTerms(false)}}>
                <Button color="primary" style={{width: '100%'}}> 
                    ვეთანხმები
                </Button>
            </div>

        </Dialog>
    </div>
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(TermsDialog)