export interface QueryBuilder {
	toQueryMap: () => Map<string, string>
	toQueryString: () => string
}

export class QueryOptions implements QueryBuilder {
	public id: string | undefined
	public pageNumber: number | undefined
	public pageSize: number | undefined
	public pageIndex: number | undefined
	public order: string | undefined
	public ordering: string | undefined
	public employee: string | undefined
	public file: string | undefined
	public sort: string | undefined
	public tags: string | undefined
	public search: string | undefined
	public type: string | undefined
	public new: boolean | undefined
	public list: boolean
	public managers: boolean
	public branch: string | undefined
	public level: string | undefined
	public birthDate: string | undefined
	public date: string | undefined
	public workingDay: boolean | undefined
	constructor() { }

	toQueryMap() {
		const queryMap = new Map<string, string>()
		if (this.pageNumber !== undefined) {
			queryMap.set('pageIndex', `${this.pageNumber}`)
		}

		if (this.pageSize !== undefined) {
			queryMap.set('pageSize', `${this.pageSize}`)
		}
		if (this.birthDate !== undefined) {
			queryMap.set('birth_date', `${this.birthDate}`)
		}
		if (this.new !== undefined) {
			queryMap.set('new', `${this.new}`)
		}
		if (this.managers !== undefined) {
			queryMap.set('managers', `${this.managers}`)
		}
		if (this.tags !== undefined) {
			queryMap.set('tags', `${this.tags}`)
		}
		if (this.level !== undefined) {
			queryMap.set('level', `${this.level}`)
		}
		if (this.list !== undefined) {
			queryMap.set('list', `${this.list}`)
		}
		if (this.type !== undefined) {
			queryMap.set('type', `${this.type}`)
		}
		if (this.file !== undefined) {
			queryMap.set('file', `${this.file}`)
		}
		if (this.search !== undefined) {
			queryMap.set('search', `${this.search}`)
		}
		if (this.date !== undefined) {
			queryMap.set('date', `${this.date}`)
		}
		if (this.workingDay !== undefined) {
			queryMap.set('working_day', `${this.workingDay}`)
		}

		if (this.branch !== undefined) {
			queryMap.set('branch', `${this.branch}`)
		}

		if (this.pageIndex !== undefined) {
			queryMap.set('pageIndex', `${this.pageIndex}`)
		}

		if (this.employee !== undefined) {
			queryMap.set('employee', `${this.employee}`)
		}

		if (this.order) {
			queryMap.set('order', this.order)
		}

		if (this.ordering) {
			queryMap.set('ordering', this.ordering)
		}

		if (this.id) {
			queryMap.set('id', `${this.id}`)
		}

		if (this.sort !== undefined) {
			queryMap.set('sort', `${this.sort}`);
		}
		return queryMap
	}

	toQueryString() {
		let queryString = ''

		this.toQueryMap().forEach((value: string, key: string) => {
			queryString = queryString.concat(`${key}=${value}&`)
		})

		return queryString.substring(0, queryString.length - 1)
	}
}