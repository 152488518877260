import React, { Fragment, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import DatePicker from "react-datepicker";


export default function DatePickers(props) {
    const {taken} = props
    const [startDate, setStartDate] = useState(new Date());
    const [highlights, setHighlights] = useState<Date[]>([]);
    const today = new Date()
    const yesterday = new Date(today)
    const nextMonth = new Date(today)

    useEffect(() => {
        console.log(taken)
    }, [taken])
    nextMonth.setDate(today.getDate() + 30)
    yesterday.setDate(yesterday.getDate() - 1)

    today.toDateString()
    yesterday.toDateString()

    const setDates = (date) => {
        if (!highlights.length) {
            setHighlights([date])
            setStartDate(date)
        } else {
            setTimeout(()=>{
                const [today] = highlights 
                const diff = date.getTime() - today.getTime()
                const days = Math.round(diff / (1000 * 3600 * 24))
                const daysArray = []
                for (var i = 1; i - 1 < days; i++) {
                    const nextDay = new Date(today.getTime())
                    nextDay.setDate(today.getDate() + i)
                    daysArray.push(nextDay)
                }
                setHighlights([...daysArray])        
                setStartDate(date)
            }, 400)
        }
    }
    
    return <Fragment >
        <Grid container xs={12} lg={12}>
            <Grid item xs={12} lg={12}>
                <DatePicker
                    autoFocus={false}
                    openToDate={today}
                    name="შვებულება"
                    minDate={new Date(`${new Date().getFullYear()}-01-01T00:00:00`)}
                    maxDate={new Date()}
                    inline={true}
                    selected={startDate}
                    highlightDates={taken?.length ? taken.map(t => new Date(t)) : highlights}
                    monthsShown={2}
                    onChange={setDates} />
            </Grid>
        </Grid>
    </Fragment>



}

