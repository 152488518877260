import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import WorkingHoursEditForm from '../../pages/Branch/WorkingHours/WorkingHourForm';
import { WorkingDayHours } from '../../models/WorkingDayHour/wokringDay';
import { QueryOptions } from '../../helpers/query.options';
import { workingDayHoursService } from '../../models/WorkingDayHour/service';
import "moment/locale/ka"
import moment from "moment"

import { connect } from 'react-redux'
import { Button, Chip, Grid, Menu, MenuItem, TableHead, Typography } from '@material-ui/core';
import { getBranchEmployees } from '../../models/user/service';
import DeleteIcon from '@material-ui/icons/Delete';
import { ConfirmationDialog } from '../Dialogs/ConfirmationDialog';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SimpleMenu from '../Menu/SimpleMenu';
import { useHistory } from "react-router-dom";
import FaceIcon from '@material-ui/icons/Face';
import { setSnackBar } from '../../models/application/applicationActions'

import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import { User } from '../../models/user/user';
import { canPerformAction } from '../../routing';
import { environment } from '../../environment/environment';
import { TablePaginationActions } from '../Common/Table/Pagination';
import { branchWorkingHoursTableService } from '../../models/branch/service';
import { BranchWorkingHoursTable } from '../../models/branch/branch';

const BASE_URL = environment.basePath


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

moment.locale("ka")


const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

const WorkiingDayHoursTable = (props) => {

  let history = useHistory()
  let query = useQuery();
  const classes = useStyles2();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dialog, setDialog] = useState({ open: false, item: null, reload: false });
  const [page, setPage] = useState(0);
  const [tables, setTables] = useState<BranchWorkingHoursTable[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [entries, setEntries] = useState([])
  const [branchEmployees, setBranchEmployees] = useState([] as any)
  const [filterEmployee, setFilterEmployee] = useState({} as User)
  const [employeeId, setEmployeeId] = useState(null as any)
  const [confirmation, setConfirmation] = useState({ open: false, text: '', callback: null })
  const [user, setUser] = useState({} as any)
  const [link, setLink] = useState(`${BASE_URL}/assets/files/2021_5.xls?date=`)
  const [linkJune, setLinkJune] = useState(`${BASE_URL}/assets/files/2021_6.xls?date=`)
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, entries.length - page * rowsPerPage);

  const handleClose = (item) => {
    setAnchorEl(null);
  };

  const getTables = () => {
    const options = new QueryOptions()
    options.sort = "year"
    options.order = "desc"
    branchWorkingHoursTableService.listWithCounter(options).then(r => {
      if (r.current && r.current.length) {
        setTables(r.current)
      }
    })
  }

  useEffect(() => {
    getTables()
    const inter = setInterval(() => {
      const d = new Date()
      setLink(`${BASE_URL}/assets/files/${user?.position?.branch?.id}_2021_5.xls?date=${d.getTime()}`)
      setLinkJune(`${BASE_URL}/assets/files/${user?.position?.branch.id}_2021_6.xls?date=${d.getTime()}`)
    }, 1500)

    const unlisten = history.listen((location, action) => {
      const { search } = location
      if (search) {
        const employee = location?.search?.split("?")[1].split("=")[1]
        setEmployeeId(employee)
        return
      }
      setEmployeeId(null)
    });
    const employee = query.get("employee")
    if (employee) {
      setEmployeeId(employee)
    }
    return () => {
      clearInterval();
      return unlisten
    }

  }, [])

  useEffect(() => {
    if (filterEmployee) {
      loadWorkingDays(filterEmployee?.id)
    } else {
      loadWorkingDays(null)
    }
  }, [filterEmployee])


  useEffect(() => {
    getEmployees()
    const [employee] = branchEmployees.filter(e => e.id === employeeId)
    setFilterEmployee(employee)
  }, [employeeId])

  useEffect(() => {
    if (props.user) {
      setUser(props.user)
    }
  }, [props.user])

  useEffect(() => {
    if (dialog.reload) {
      setDialog({ ...dialog, reload: false, open: false, item: null })
      loadWorkingDays(null)
    }
  }, [dialog])

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const DeletionCell = ({ item }) => {
    return <TableCell component="th" scope="row" style={{ width: 60 }} onClick={() => {
      setConfirmation({
        text: 'ნამდვილად გსურთ ჩანაწერის წაშლა?', open: true, callback: (value) => {
          if (value) {
            workingDayHoursService.delete(item.id).then((r) => {
              if (r.status == 200) {
                loadWorkingDays(filterEmployee?.id)
                props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'ჩანაწერი წაიშალა წარმატებით.', open: true })
              } else {
                let text = 'დაფიქსირდა შეცდომა'
                if (r.status === 403) {
                  text = 'თქვენ არ გაქვთ წაშლის უფლება'
                }
                props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text, open: true })
              }
            })
          }
          setConfirmation({ text: '', callback: null, open: false })
        }
      })
    }}>
      <DeleteIcon style={{ cursor: "pointer" }} />
    </TableCell>
  }

  const getEmployees = () => {
    const options = new QueryOptions();
    options.workingDay = true
    getBranchEmployees(options).then((employees: User[]) => {

      setBranchEmployees(employees)
      if (employeeId) {
        const [employee] = employees?.filter(e => e.id === employeeId)
        setFilterEmployee(employee)
      }
    })
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const loadWorkingDays = (employee) => {
    const options = new QueryOptions();
    if (employee) {
      options.employee = employee
    }

    options.sort = "end_time"
    options.order = 'desc'
    workingDayHoursService.listWithCounter(options).then(r => {
      if (!r.status && r.current) {
        setEntries(r.current)
        const [employee] = branchEmployees.filter(e => e.id === employeeId)
        setFilterEmployee(employee)
      }
    })
  }

  const showNewItem = (employee) => {
    const item = new WorkingDayHours()
    item.employee = employee
    item.branch = employee?.position?.branch
    item.responsible = props?.user
    console.log(item, employee)
    setDialog({ open: true, item, reload: false })
  }

  const tableContent = (<TableContainer component={Paper}>
    <ConfirmationDialog open={confirmation.open} text={confirmation.text} callback={confirmation.callback} />

    <Table className={classes.table} aria-label="custom pagination table">
      <TableHead>
        <TableRow>
          {canPerformAction(user, 'working_day_supervisor', 'delete') &&
            <TableCell style={{ fontWeight: 'bold' }}></TableCell>
          }
          <TableCell style={{ fontWeight: 'bold' }}>თანამშრომელი</TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>სტრ. ერთეული</TableCell>
          <TableCell align="center" style={{ fontWeight: 'bold' }}>
            საათები
          </TableCell>
          <TableCell align="center" style={{ fontWeight: 'bold' }}>დაწყება</TableCell>
          <TableCell align="center" style={{ fontWeight: 'bold' }}>დამთავრება</TableCell>
          <TableCell align="center" style={{ fontWeight: 'bold' }}>დეტალები</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>

        {
          (rowsPerPage > 0
            ? entries.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : entries
          ).map((item: WorkingDayHours) => (
            <TableRow key={item.id}>
              {canPerformAction(user, 'working_day_supervisor', 'delete') &&
                <DeletionCell item={item} />
              }

              <TableCell component="th" scope="row" style={{ width: 200 }} onClick={() => {

                //setDialog({ open: true, item, reload: false })

              }}>
                {item.employee.fullName}
              </TableCell>
              <TableCell style={{ width: 60 }} align="center">
                {item.branch.uid}
              </TableCell>
              <TableCell style={{ width: 60 }} align="center">
                {item.hours}
              </TableCell>
              <TableCell style={{ width: 160 }} align="center">
                {item.startTime}
              </TableCell>

              <TableCell style={{ width: 160 }} align="center">
                {item.endTime}
              </TableCell>
              <TableCell style={{ width: 260 }} align="center">
                {item?.option?.name}
              </TableCell>
            </TableRow>
          ))}
        {emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={3}
            count={entries.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />

        </TableRow>
      </TableFooter>
    </Table>

  </TableContainer>)

  return <Fragment>
    <Grid container
      style={{ textAlign: 'center', justifyContent: 'center' }}
      direction="row"
      lg={12} >
      <h2>
        სამუშაო დროის აღრიცხვა
      </h2>

    </Grid>
    <WorkingHoursEditForm onOpen={dialog.open} onClose={() => {
      setDialog({ open: false, item: null, reload: false })

    }} item={dialog.item} />
    <Grid lg={3}></Grid>
    <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 20 }} lg={6}>
      <Grid item lg={3}>
        {canPerformAction(user, 'working_day_supervisor', 'write') &&
          <SimpleMenu title="ახალი ჩანაწერი" icon={<AddCircleIcon style={{ fontSize: 30 }} />} items={branchEmployees} clicked={(item) => {
            showNewItem(item)
          }} />
        }
      </Grid>
      {true && <Grid item lg={2} style={{ textAlign: "right" }}>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          გადმოწერა
        </Button>
        {tables.length > 0 && <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {tables?.map((item, index) => {
            return <MenuItem key={1} onClick={() => {
              const d = new Date()
              const branchId = props.user.position.branch.id
              window.location.href = `${BASE_URL}/assets/files/working_hours/${item.id}.xls?date=${d.getTime()}`

              setAnchorEl(null);
            }}>
              {item?.owner?.fullName} - {item.name}, {item.id.split("_")[1]}
            </MenuItem>
          })}
        </Menu>}

      </Grid>}
      <Grid item lg={4} style={{ textAlign: "right" }}>
        {filterEmployee &&
          <Chip
            icon={<FaceIcon />}
            label={filterEmployee.fullName}
            onDelete={() => {
              history.push(`/workingDayHoursForm`)
            }}
          />}

      </Grid>

      {canPerformAction(user, 'working_day_supervisor', 'write') && <Grid item lg={3} style={{ textAlign: "right" }}>
        <SimpleMenu title="გაფილტრვა" icon={<AddCircleIcon style={{ fontSize: 30 }} />} items={branchEmployees} clicked={(item) => {
          history.push(`/workingDayHoursForm?employee=${item.id}`)
        }} />
      </Grid>}
    </Grid>
    <Grid container lg={12}>
      <Grid lg={12}>
        {entries.length > 0 ? <div>{tableContent}</div> : <div style={{ marginTop: 20 }}>
          <Typography variant="h6">
            ჩანაწერები არ მოიძებნა
          </Typography>

        </div>}
      </Grid>
    </Grid>


  </Fragment >
}

const mapStateToProps = (state: any) => {
  return {
    user: state?.auth?.user
  }
}

export default connect(mapStateToProps, { setSnackBar })(WorkiingDayHoursTable)