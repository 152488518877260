import React, { useEffect, useState } from "react"
import { Dialog, DialogActions, Grid, TextField } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { Form, Formik, ErrorMessage } from "formik"
import { connect } from 'react-redux'
import { validationSchemas } from "../../helpers/validations"
import '../../components/styles.css'
import { authenticate, login } from '../../models/user/authActions'
import {
    login as loginUser
} from "../../models/user/service"
import { User } from "../../models/user/user"
import cookie from 'react-cookies'
import { setSnackBar } from '../../models/application/applicationActions'

const LoginForm = (props: any) => {
    const { onOpen, onClose, setSnackBar, dialogMode = false,  } = props
    const [open, setOpen] = useState(false as any)
    const [tokenMode, setTokenMode] = useState(false)
    const [wrongCredentials, setWrongCredentials] = useState(false)
    const handleCloseDialog = () => { onClose(false) }
    const handleClose = () => {
        onClose(false)
    }
    useEffect(() => {
        setOpen(onOpen)
    }, [onOpen])

    const getValues = (values) => {

        loginUser(values.personalId, values.password, values.token).subscribe((resp: any) => {
            if (resp.accessToken) {
                let user = new User()
                user = user.updateUser(resp)
                props.login(user)
                var now = new Date();
                const x = now.getTimezoneOffset() / 60 * -1
                const inOneHour = new Date(now.getTime() + 1 * 60 * 60 * 1000)
                cookie.save('user', user.id, { expires: inOneHour })
            }
            if (resp.status === 401) {
                setWrongCredentials(true)
            }
            if (resp.status === 402) {
                setTokenMode(true)   
                setSnackBar({ vertical: 'bottom', horizontal: 'center', text: resp.response.message, open: true })
                setTimeout(()=> {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: resp.response.message, open: false })
                }, 3000)
            }
        })
    }

    return (
        <Formik
            validationSchema={validationSchemas['login']}
            initialValues={{ personalId: '', password: '' }}
            onSubmit={getValues}>
            {({ values, handleSubmit, setFieldValue, handleChange, handleBlur }: any) => (
                <Form onSubmit={handleSubmit}>
                    <Grid container >
                        <Grid item lg={12}>
                            <TextField
                                style={{ width: '90%', marginTop: 10 }}
                                id="outlined-basic"
                                label={'პირადი ნომერი'}
                                variant="outlined"
                                type="text"
                                name="personalId"
                                onChange={(event) => {
                                    setWrongCredentials(false)
                                    handleChange(event)
                                }}
                                onBlur={handleBlur}
                                value={values.email}
                            />
                            <ErrorMessage name="personalId" >
                                {msg => <span className="error">{msg}</span>}
                            </ErrorMessage>

                            <TextField
                                style={{ width: '90%', marginTop: 10 }}
                                id="outlined-basic"
                                label={'პაროლი'}
                                variant="outlined"
                                type="password"
                                name="password"
                                onChange={(event) => {
                                    setWrongCredentials(false)
                                    handleChange(event)
                                }}
                                onBlur={handleBlur}
                                value={values.password}
                            />
                            <ErrorMessage name="password" >
                                {msg => <span className="error">{msg}</span>}
                            </ErrorMessage>

                        </Grid>
                        {tokenMode && <Grid item lg={12}>
                            <TextField
                                style={{ width: '90%', marginTop: 10 }}
                                id="outlined-basic"
                                value={values.token}
                                label={'მიღებული 4 ნიშნა კოდი'}
                                variant="outlined"
                                type="number"
                                name="token"
                                onChange={(event) => {
                                    console.log(event.target.value)
                                    setFieldValue("token", event.target.value)
                                }}
                                onBlur={handleBlur}
                            />
                        </Grid>}
                        {wrongCredentials && <span className="error">
                            მონაცემები არასწორია</span>}
                    </Grid>
                    <DialogActions>
                        {dialogMode &&
                            <Button onClick={handleCloseDialog} color="primary">
                                გაუქმება
                            </Button>

                        }
                        <Button color="primary"
                            type="submit">
                            შესვლა
                        </Button>
                    </DialogActions>
                </Form>
            )}
        </Formik>)

}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { authenticate, login, setSnackBar })(LoginForm)