

import { ResourceProvider } from "../../providers/resource.provider"
import { NameIdItemSerializer } from "../common/serializer"
import { Country } from "./country"





export class CountryProvider extends ResourceProvider<Country> {
    constructor() {
        super("rest_country", new NameIdItemSerializer())
    }
}


export const countryService = new CountryProvider()
