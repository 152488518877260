import { environment } from '../../environment/environment'
import { BranchSerializer } from '../branch/serializer'
import {NameIdItemSerializer } from '../common/serializer'
import { countrySerializer } from '../country/serializer'
import { Serializer } from '../serializer'
import { User, UserRole, UserPermissions, PersonalInfo, PreviousPosition,
    EmbeddedPosition, Position, EmbeddedAddress, Contact, NaviEmployee} from './user'
const BASEAPIURL = environment.basePath

export class UserPermissionsSerializer implements Serializer {
    fromJson(array: any[]) {
        const permissions = new UserPermissions()
        const roles = ["read", "write", "update", "delete", "export"]
        for (var x = 0; x < roles.length; x++) {
            const [role] = array.filter(p => roles[x] === p.name);
            if (role) {
                permissions[roles[x]] = true
                
            } else {
                permissions[roles[x]] = false
            }
        }
        return permissions
    }

    toJson(user: User) {
        const obj: any = {}
        
        return obj
    }
}
export class UserRoleSerializer implements Serializer {
    fromJson(json: any) {
        const userRole = new UserRole()
        userRole.name = json.name
        const serializer = new UserPermissionsSerializer()
        userRole.permissions = serializer.fromJson(json.permissions)
        return userRole
    }

    toJson(user: User) {
        const obj: any = {}
        
        return obj
    }
}

export class UserSerializer implements Serializer {
    fromJson(json: any) {
        const user = new User()
        if (json.token) {
            user.accessToken = json.token
        }
        if (json.user){
            json = json.user
        }
        if (json.navi_employee) {
            const naviEmplSerializer = new NaviEmployeeSerializer()
            user.naviEmployee = naviEmplSerializer.fromJson(json.navi_employee)
        } 
        
        if (json.contact) {
            const contactSerializer = new ContactSerializer()
            user.contact = contactSerializer.fromJson(json.contact)
        }
        if (json.image_filename) {
            user.photo = `${environment.basePath}/${environment.imagesPath}/employees/${json.image_filename}` 
        } else  {
            user.photo = null
        }
        if (json.birth_day_year) {
            user.birthDayYear = json.birth_day_year
        }
        if (json.personal_info) {
            const piSerializer = new PersonalInfoSerializer()
            user.personalInfo = piSerializer.fromJson(json.personal_info)
        }
        if (json.position) {
            const positionSerializer = new PositionSerializer()
            user.position = positionSerializer.fromJson(json.position)
        } 
        user.id = json.id
        if (json.joined) {
            user.joined = json.joined
            
        } else {
            user.yearsInCompany = null
        }
        user.firstName = json.firstNamefirst_name
        user.lastName = json.last_name
        user.firstName = json.first_name

        user.email = json.email
        if (json.children) {
            user.children = json.children.map(s => this.fromJson(s))
        }
        if (json.title) {
            user.title = json.title
        }
        if (json.name) {
            user.name = json.name
        }
        user.fullName = `${json.first_name} ${json.last_name}`
        user.phone = json.phone
        user.personalId = json.personal_id
        if (json.roles) {
            const rolesSerializer = new UserRoleSerializer()
            user.roles = json.roles.map(r => rolesSerializer.fromJson(r))
        }
        return user
    }

    toJson(user: User) {
        const obj: any = {}
        obj.id = user.id
        if (user.firstName) {
            obj.first_name = user.firstName
        }
        if (user.lastName) {
            obj.last_name = user.lastName
        }
        if (user.image) {
            obj.image = user.image
        }
        if (user.personalId) {
            obj.personal_id = user.personalId
        }
        if (user.email) {
            obj.email = user.email
        }
        if (user.phone) {
            obj.phone = user.phone
        }
        if (user.password) {
            obj.password = user.password
        }
        if (user.contact) {
            const cSerializer = new ContactSerializer()
            obj.contact = cSerializer.toJson(user.contact)
        }
        return obj
    }
}

export class EmbeddedPositionSerializer implements Serializer {
    fromJson(json: any) {
        const position = new EmbeddedPosition()
        if (json.department) {
            const ds = new NameIdItemSerializer()
            position.department = ds.fromJson(json.department)

        }
        position.id = json.id
        position.name = json.name
        
        return position
    }

    toJson(item: any) {
        return item
    }
}
export class NaviEmployeeSerializer implements Serializer {
    fromJson(json: any) {
        const employee = new NaviEmployee()
        const nameIdItemSerializer = new NameIdItemSerializer()
        employee.id = json.id
        employee.address = json.address
        employee.birthDate = json.birth_date
        employee.birthDay = json.birth_day
        if (json.gender) {
            employee.gender = nameIdItemSerializer.fromJson(json.gender)
        }
        employee.isActive = json.is_active
        employee.jobTitle = json.job_title
        employee.jobTitleCode = nameIdItemSerializer.fromJson(json.job_title_code)
        employee.joined = json.joined
        employee.orgUnitCode = nameIdItemSerializer.fromJson(json.org_unit_code)
        employee.orgUnitName = json.org_unit_name
        return employee
    }

    toJson(item: any) {
        return item
    }
}
export class PreviousPositionSerializer implements Serializer {
    fromJson(json: any) {
        const position = new PreviousPosition()
        position.caption = json.caption
        position.dateFrom = new Date(json.date_from * 1000)
        position.dateTo = new Date(json.date_to * 1000)
        position.info = json.info
        if (json.position) {
            const embSerializer = new EmbeddedPositionSerializer()
            position.position = embSerializer.fromJson(json.position)
        }
        return position
    }

    toJson(item: any) {
        return item
    }
}
export class PositionSerializer implements Serializer {
    fromJson(json: any) {
        const position = new Position()
        if (json.current_position) {
            const embSerializer = new EmbeddedPositionSerializer()
            position.currentPosition = embSerializer.fromJson(json.current_position)
        }
        const branchSerializer = new BranchSerializer()
        
        if (json.branch && json.branch.id) {
            position.branch = branchSerializer.fromJson(json.branch)
        }

        position.level = json.level
        if (json.previous_positions) {
            const embSerializer = new PreviousPositionSerializer()
            position.previousPositions = json.previous_positions.map(p => embSerializer.fromJson(p))
        }
        return position
    }

    toJson(item: any) {
        return item
    }
}

export class PersonalInfoSerializer implements Serializer {
    fromJson(json: any) {
        const info = new PersonalInfo()
        const dt = new Date(json.birth_date * 1000)
        info.birthDate = json.birth_date
        info.citizenship = json.citizenship
        info.maritalStatus = json.marital_status
        return info
    }

    toJson(item: any) {
        return item
    }
}
export class EmbeddedAddressSerializer implements Serializer {
    fromJson(json: any) {
        const embedded = new EmbeddedAddress()
        embedded.city = json.city
        if (json.country) {
            embedded.country  = countrySerializer.fromJson(json.country)
        }
        embedded.postalCode = json.postal_code
        embedded.street = json.street
        embedded.street2 = json.street2
        return embedded
    }

    toJson(item: any) {
        return item
    }
}

export class ContactSerializer implements Serializer {
    fromJson(json: any) {
        const contact = new Contact()
        contact.showPersonalEmail = json.show_personal_email
        contact.showPersonalPhone = json.show_personal_phone
        if (json.company_address) {
            const caSerializer = new EmbeddedAddressSerializer()
            contact.companyAddress = caSerializer.fromJson(json.company_address)
        }
        if (json.personal_address) {
            const paSerializer = new EmbeddedAddressSerializer()
            contact.personalAddress = paSerializer.fromJson(json.personal_address)
        }
        contact.extension = json.extension
        contact.corporateEmail = json.corporate_email
        contact.officePhone = json.office_phone
        contact.personalEmail = json.personal_email
        contact.personalMobile = json.personal_mobile
        contact.personalPhone = json.personal_phone
        return contact
    }

    toJson(item: Contact) {
        let address: any= {}
        if (item.personalAddress){
            address.street = item.personalAddress.street
            address.street2 = item.personalAddress.street2
            address.city_string = item.personalAddress.city
        }
        const obj =  {
            personal_phone: item.personalPhone,
            personal_mobile: item.personalMobile,
            personal_email: item.personalEmail,
            show_personal_email : item.showPersonalEmail ? item.showPersonalEmail : false,
            show_personal_phone : item.showPersonalPhone ? item.showPersonalPhone : false,
            personal_address: address
        }
        return obj
    }
}

export const employeeSerializer = new UserSerializer()