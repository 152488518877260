import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import "moment/locale/ka"
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import moment from "moment"
import { connect } from 'react-redux'
import { Grid, TableHead, Typography } from '@material-ui/core';
import { setSnackBar } from '../../models/application/applicationActions'
import { ConfirmationDialog } from '../../components/Dialogs/ConfirmationDialog';
import { environment } from '../../environment/environment';
import { RequestConfirmation, VacationRequest } from '../../models/request/request';
import { QueryOptions } from '../../helpers/query.options';
import { requestConfirmationStatusService, requestedVacationDayService, vacationRequestConfirmationService, vacationRequestService } from '../../models/request/service';
import VacationRequestDialog from '../../components/Dialogs/VacationRequestDialog';
import { createBrowserHistory } from "history";
import { canPerformAction } from '../../routing';
import SimpleMenu from '../Menu/SimpleMenu';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CreateIcon from '@material-ui/icons/Create';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { User } from '../../models/user/user';
import { getBranchEmployees } from '../../models/user/service';
import { useLocation } from 'react-router';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import RequestedVacationDaysDialog from '../Dialogs/RequestedVacationDaysDialog';

const BASE_URL = environment.basePath


const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
})

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

moment.locale("ka")

const VacationRequestTableListComponent = (props) => {
    const { setSnackBar, user, id } = props
    let location = useLocation()
    let query = useQuery()
    const history = createBrowserHistory()
    const classes = useStyles2();
    const [items, setItems] = useState<VacationRequest[]>([]);
    const [employeeId, setEmployeeId] = useState<string>()
    const [employees, setEmployees] = useState<User[]>([]);
    const [employee, setEmployee] = useState<User>();
    const [dialog, setDialog] = useState({ open: false, item: null, reload: false });
    const [requestedVacatoinDaysdialog, setRequestedVacatoinDaysDialog] = useState({ open: false, items: null, reload: false, item: null });
    const [confirmation, setConfirmation] = useState({ open: false, text: '', callback: null, item: null })
    const [statusList, setStatusList] = useState({} as any)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    let emptyRows

    const loadStatuses = () => {
        const options = new QueryOptions()
        requestConfirmationStatusService.list(options).then(list => {
            setStatusList(list.results)
        })
    }

    const reloadRequests = () => {
        const userId = location.pathname.split("/")[2]
        loadRequests(history.location.pathname === '/vacationRequests' ? null : userId)
    }

    useEffect(() => {
        if (employeeId) {
            loadRequests(employeeId)
        }
    }, [employeeId])

    useEffect(() => {
        if (location) {
            reloadRequests()
        }
    }, [location])

    const getRequestedDays = (item: VacationRequest) => {
        const options = new QueryOptions()
        options.sort = 'date'
        options.order = 'desc'
        requestedVacationDayService.readListWithCounter(options, item.id).then(r => {
            setRequestedVacatoinDaysDialog({ item: item, items: r.current, text: '', open: true, callback: null } as any)
        })
    }

    useEffect(() => {
        loadStatuses()
        const options = new QueryOptions();

        if (history.location.pathname === '/vacationRequests') {
            options.workingDay = true
        }


        const emp_id = query.get("employee")

        if (emp_id) {
            setEmployeeId(emp_id)
        }
        options.workingDay = true
        getBranchEmployees(options).then((employees: User[]) => {
            setEmployees(employees)
            if (emp_id) {
                const [filtered] = employees.filter(e => e.id === emp_id)
                if (filtered) {
                    setEmployee(filtered)
                }
            }
        })

    }, [])

    const updateStatus = (status: string, requestId: string) => {
        const confirmation = new RequestConfirmation()
        confirmation.status = status
        confirmation.requestId = requestId
        vacationRequestConfirmationService.create(confirmation).then(r => {
            if (r.requestId) {
                setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'სტატუსი განახლდა წარმატებით', open: true })
                setTimeout(() => {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'სტატუსი განახლდა წარმატებით', open: false })
                }, 3000)
                reloadRequests()
            }
        })
    }


    const loadRequests = (employeeId) => {
        const options = new QueryOptions()
        options.sort = "created"
        if (employeeId) {
            options.employee = employeeId
        }
        options.pageSize = 100
        options.order = "desc"
        vacationRequestService.listWithCounter(options).then(r => {
            setItems(r.current)
        })
    }


    const showNewItem = (employee) => {
        setDialog({ open: true, reload: false, item: { employee } })
    }

    const onReload = () => {
        reloadRequests()
    }

    useEffect(() => {
        if (items.length) {
            emptyRows = rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);
        }
    }, [items])

    const tableContent = (<TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
                <TableRow>
                    <TableCell align="center" style={{ fontWeight: 'bold', width: 120 }}>
                        თამამშრომელი
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', width: 120 }}>
                        შექმნის თარიღი
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        შვებულების სახეობა
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', width: 120 }}>
                        დაწყების თარიღი
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        დასრულების თარიღი
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        დღეების რაოდენობა
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        სამუშაო დღეები
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        დარჩენილი / გამოსაყენებელი დღეები
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        სტატუსი
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        ჩამოტვირთვა
                    </TableCell>
                    {history.location.pathname !== '/vacationRequests' &&
                        <TableCell align="center" style={{ fontWeight: 'bold' }}>
                            ცვლილება
                        </TableCell>}
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        დეტალები
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    (rowsPerPage > 0
                        ? items?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : items
                    ).map((item: VacationRequest) => (
                        <TableRow key={item.id}>
                            <TableCell component="th" scope="row" >
                                {item.employee.fullName}
                            </TableCell>
                            <TableCell component="th" scope="row" >
                                {item.created}
                            </TableCell>
                            <TableCell style={{ width: 130 }} align="center">
                                {item.type.name}
                            </TableCell>
                            <TableCell align="center">
                                {item.fromDate}
                            </TableCell>
                            <TableCell align="center">
                                {item.toDate}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {item.requestedDays?.length}
                                
                            </TableCell>
                            <TableCell align="center">
                                {item.workingDays}
                            </TableCell>
                            <TableCell align="center">
                                {item.daysLeft}
                            </TableCell>
                            <TableCell align="center">
                                {item?.confirmationStatus?.id ? item?.confirmationStatus.name : 'დასადასტურებელია'}
                            </TableCell>
                            <TableCell style={{ width: 260 }} align="center">
                                <CloudDownloadIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        window.open(`${BASE_URL}/assets/files/requests/${item.id}.pdf`, '_blank');
                                    }}
                                />
                            </TableCell>
                            {history.location.pathname === '/vacationRequests' && <TableCell style={{ width: 260 }} align="center">
                                {canPerformAction(user, 'request_confirmation', 'write') && item?.employee.id !== user.id &&
                                    <SimpleMenu title="სტატუსის შეცვლა"
                                        icon={<CreateIcon style={{ fontSize: 30 }} />} items={statusList.map(s => { return { fullName: s.name, id: s.id } })} clicked={(i) => {
                                            setConfirmation({ text: `მოთხოვნის სტატუსი გახდება: ${i.fullName}`, open: true, callback: null, item: { status: i.id, requestId: item.id } })
                                        }} />
                                }
                            </TableCell>}
                            {history.location.pathname !== '/vacationRequests' && item?.confirmationStatus.name === 'დადასტურებული' && <TableCell style={{ width: 260 }} align="center">
                                {canPerformAction(user, 'request_confirmation', 'write') && item?.employee.id !== user.id &&
                                    <SimpleMenu title="სტატუსის შეცვლა"
                                        icon={<CreateIcon style={{ fontSize: 30 }} />} items={statusList.filter(s => s.name === 'გაუქმებული').map(s => { return { fullName: s.name, id: s.id } })} clicked={(i) => {
                                            setConfirmation({ text: `მოთხოვნის სტატუსი გახდება: ${i.fullName}`, open: true, callback: null, item: { status: i.id, requestId: item.id } })
                                        }} />
                                }
                            </TableCell>}
                            {item?.confirmationStatus.name === 'დადასტურებული' && item?.employee.id === user.id && <TableCell style={{ width: 260 }} align="center">
                                <EditOutlinedIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        getRequestedDays(item)
                                    }} />

                            </TableCell>}
                        </TableRow>
                    ))}
                {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </TableContainer>)

    return <Fragment>
        <ConfirmationDialog open={confirmation.open} text={confirmation.text} callback={(c) => {
            if (c) {
                const { status, requestId } = confirmation.item
                updateStatus(status, requestId)
            }
            setConfirmation({ text: '', open: false, callback: null } as any)
        }} />
        <RequestedVacationDaysDialog
            onDelete={(id) => {
                requestedVacationDayService.delete(id).then(r => {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'მოთხოვნილი დღე გაუქმებულია', open: true })
                    setTimeout(() => {
                        setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                    }, 3000)
                    setRequestedVacatoinDaysDialog({ ...requestedVacatoinDaysdialog, open: false })
                    reloadRequests()
                })
            }}
            onOpen={requestedVacatoinDaysdialog.open}
            onClose={(open) => {
                setRequestedVacatoinDaysDialog({ ...requestedVacatoinDaysdialog, open: false })
            }}
            items={requestedVacatoinDaysdialog.items}
            item={requestedVacatoinDaysdialog.item}
        />
        <VacationRequestDialog
            onOpen={dialog.open} onClose={(open) => {
                onReload()
                setDialog({ ...dialog, open })
            }} item={dialog.item} />
        <Grid container lg={12}>
            <Grid item lg={12} style={{ textAlign: 'center' }}>
                <h3>შვებულების მოთხოვნები</h3>
            </Grid>
            {
                history.location.pathname === '/vacationRequests' ?
                    <Grid lg={1}></Grid> :
                    <></>
            }


            <Grid container lg={history.location.pathname === '/vacationRequests' ? 12 : 12}>
                {history.location.pathname === '/vacationRequests' ? <Grid item lg={4} spacing={0} style={{ padding: 10 }}>
                    {canPerformAction(user, 'vacation_request', 'write') &&
                        <SimpleMenu title="ახალი მოთხოვნა" icon={<AddCircleIcon style={{ fontSize: 30 }} />} items={[...[user], ...employees.filter(e => e.id !== user?.id)]} clicked={(item) => {
                            showNewItem(item)
                        }} />
                    }

                </Grid> :
                    user?.id === id ? <Grid item lg={4} spacing={0} style={{ padding: 10 }}>
                        {canPerformAction(user, 'vacation_request', 'write') &&
                            <SimpleMenu title="ახალი მოთხოვნა" icon={<AddCircleIcon style={{ fontSize: 30 }} />} items={[...[user], ...employees.filter(e => e.id !== user?.id)]} clicked={(item) => {
                                showNewItem(item)
                            }} />
                        }

                    </Grid> : <></>
                }
                {history.location.pathname === '/vacationRequests' && <Grid item lg={4}>
                    <Autocomplete
                        id="თანამშრომლები"
                        options={employees}
                        inputValue={employeeId ? employee?.fullName : ''}
                        getOptionLabel={(option) => option.fullName}
                        style={{ width: 300 }}
                        onChange={(event, value: User) => {
                            if (value) {
                                setEmployeeId(value.id)
                                setEmployee(value)
                                history.push(`/vacationRequests?employee=${value.id}`)
                                //loadRequests(value.id)
                            } else {
                                history.push(`/vacationRequests`)
                                setEmployee(null)
                                setEmployeeId(null)
                                loadRequests(null)
                            }
                        }}
                        onInputChange={(event, newValue) => {
                            history.push(`/vacationRequests`)
                            setEmployee(null)
                            setEmployeeId(null)
                            loadRequests(null)
                        }}
                        renderInput={(params) => <TextField {...params} label="თანამშრომლები" variant="outlined" />}
                    />
                </Grid>}

                {items.length > 0 ? <div style={{ paddingBottom: 50 }}>{tableContent}</div> : <div style={{ marginTop: 20 }}>
                    <Typography variant="h6">
                        ჩანაწერები არ მოიძებნა
                    </Typography>
                </div>}
            </Grid>
        </Grid>
    </Fragment >
}

const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user
    }
}

export default connect(mapStateToProps, { setSnackBar })(VacationRequestTableListComponent)