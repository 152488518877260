import React, { useEffect, useState } from "react";
import '../../components/styles.css'
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import "react-datepicker/dist/react-datepicker.css";
import { User } from "../../models/user/user";
import { useParams } from "react-router";
import { temporaryTransferRequestService } from "../../models/request/service";
import { TemporaryTransferRequest } from "../../models/request/request";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        leftcolumn: {
            padding: 10
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        }
    })
);






const TemporaryTransferRequestDetailsPage = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [entry, setEntry] = React.useState<TemporaryTransferRequest>();
    let { id } : any = useParams();
    

    
    
    
    
    const [user, setUser] = useState<User>()

    const getItem = (id) => {
        temporaryTransferRequestService.read(id).then((entry: TemporaryTransferRequest)  => {
              setEntry(entry)
          })
    }

    useEffect(() => {
        setUser(props.auth?.user)
    }, [props.auth])
    useEffect(() => {
        getItem(id)
    }, [])
    const bull = <span className={classes.bullet}>•</span>;
    const card = <Card className={classes.root}>
        <CardContent>
            <Grid container lg={12}>
                <Grid item lg={2}>

                </Grid>
                
            </Grid>
            <Grid container lg={12} style={{ height: 150, padding: 10 }}>
                
                <Grid item lg={5}>

                </Grid>
            </Grid>

        </CardContent>
    </Card>
    const container = <div className={classes.root}>
        <Grid container spacing={0}>
            <Grid item xs={12} lg={2} spacing={6} style={{ padding: 20 }}>
            </Grid>
            <Grid item xs={12} lg={8} style={{ padding: 20 }}>
                
            </Grid>
            <Grid item xs={12} lg={2}>

            </Grid>
        </Grid>
    </div>




    return container


}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, {})(TemporaryTransferRequestDetailsPage)