import { ajax } from "rxjs/ajax"
import { map, catchError } from "rxjs/operators"
import { environment } from "../../environment/environment"
import { of } from "rxjs"
import { EmbeddedPositionSerializer, UserSerializer } from "./serializer"
import { Position, User } from "./user"
import { ResourceProvider } from "../../providers/resource.provider"

const API_BASE_URL = environment.basePath

export const login = (username: string, password: string, token: string) => {
    const url = `${API_BASE_URL}/login_user`
    const userSerializer = new UserSerializer()
    const headers = {
        "Content-Type": "application/json"
    }
    const body = { username, password, token }
    return ajax({ url, method: "POST", headers, body }).pipe(
        map((resp: any) => userSerializer.fromJson(resp.response) as User),
        catchError((error) => {
            return of(error);
        })
    )
};

const getToken = async () => {
    const user: User | null = await JSON.parse(localStorage.getItem("currentUser") ? localStorage.getItem("currentUser") : "{}");
    if (!user?.accessToken) {
        return 'app'
    }
    return user.accessToken;
}

export const logout = async () => {
    const token = await getToken()
    const headers = { "Accept": "*/*", "Content-Type": "application/json" }
    if (token && token.length) {
        headers["Authorization"] = `Bearer ${token}`
    }
    const url = `${API_BASE_URL}/logout_user`
    return ajax({ url, method: "DELETE", headers }).pipe(map((resp: any) => resp),
        catchError((error) => {
            return of(error)
        })
    ).toPromise();
}

export class UserListProvider extends ResourceProvider<User> {
    constructor() {
        super("rest_employee_list", new UserSerializer())
    }
}
export class UserProvider extends ResourceProvider<User> {
    constructor() {
        super("rest_employee", new UserSerializer())
    }
}
export class UserUpdateProvider extends ResourceProvider<User> {
    constructor() {
        super("rest_update_employee", new UserSerializer())
    }
}
export class UserRegistrationProvider extends ResourceProvider<User> {
    constructor() {
        super("rest_user", new UserSerializer())
    }
}
export class ChartProvider extends ResourceProvider<User> {
    constructor() {
        super("rest_chart", new UserSerializer())
    }
}
export class PositionProvider extends ResourceProvider<Position> {
    constructor() {
        super("rest_position", new EmbeddedPositionSerializer())
    }
}
export class BranchEmployeeProvider extends ResourceProvider<User> {
    constructor() {
        super("rest_employee_list", new UserSerializer())
    }
}


export const userListService = new UserListProvider()
export const branchEmployeeService = new BranchEmployeeProvider()
export const userService = new UserProvider()
export const userUpdateService = new UserUpdateProvider()
export const userRegistrationService = new UserRegistrationProvider()
export const chartService = new ChartProvider()
export const positionService = new PositionProvider()

export const getBranchEmployees = (options) => new Promise((resolves, rejects) => {
    if (options.workingDay) {
        userListService.list(options).then(r => {
            if (r.results) {
                resolves(r.results)
            } else {
                rejects({})
            }
        })
    } else {
        userListService.listWithCounter(options).then(r => {
            if (r.results) {
                resolves(r.results)
            } else {
                rejects({})
            }
        })
    }
})