import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Form, Formik, ErrorMessage } from "formik";
import { connect } from 'react-redux'
import { validationSchemas } from "../../helpers/validations";
import '../../components/styles.css'
import { setSnackBar } from '../../models/application/applicationActions'
import { NameIdItem, PasswordRestore } from "../../models/common/common";
import { forgotPasswordService, passwordRestoreService } from "../../models/common/service";
import TermsDialog from '../../components/Dialogs/Terms'


const ForgotPasswordFormDialog = (props: any) => {
    const [termsOpen, setTermsOpen] = React.useState(false)
    const { onOpen, onClose, setSnackBar, auth } = props
    const [tokenMode, setTokenMode] = useState(false)
    const [notFound, setNotFound] = useState(false)
    const [tokenInvalid, setTokenInvalid] = useState(false)
    let error: string = null
    const [open, setOpen] = useState(false as any)
    const handleCloseDialog = () => {
        setTokenMode(false)
        onClose(false)
    }
    const handleClose = () => {
        onClose(false)
    }
    useEffect(() => {
        setOpen(onOpen)
    }, [onOpen])

    const getValues = (values) => {
        const nameIdItem = new NameIdItem()
        nameIdItem.id = values.personalId
        forgotPasswordService.create(nameIdItem).then((resp: any) => {
            if (resp?.status === 402) {
                setNotFound(true)
            }
            if (resp?.id) {
                setTermsOpen(true)
            }
        });
    }
    const getValuesRestore = (values) => {
        const item = new PasswordRestore()
        item.personalId = values.personalId
        item.password = values.password
        item.token = values.token
        passwordRestoreService.create(item).then((resp: any) => {
            if (resp?.status === 402) {
                setTokenInvalid(true)
            }
            if (resp?.personalId) {
                onClose(false)
                props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'პაროლი განახლებულია წარმატებით', open: true })
                setTimeout(() => {
                    props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                }, 4000)
            }
        });
    }

    return (
        <Dialog open={props.onOpen} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <TermsDialog onOpen={termsOpen} onCloseTerms={(resp) => {
                setTokenMode(true)
                setTermsOpen(resp)
            }} />
            <DialogTitle id="form-dialog-title">პაროლის აღდგენა</DialogTitle>
            <DialogContent className="dialog">
                {!tokenMode && <Formik
                    validationSchema={validationSchemas['forgotPassword']}
                    initialValues={{ personalId: '' }}
                    onSubmit={getValues}>
                    {({ values, handleSubmit, errors, touched, validateForm, setFieldValue, handleChange, handleBlur }: any) => (
                        <Form onSubmit={(event) => {
                            handleSubmit(event)
                        }}>
                            <Grid container style={{ width: 300 }}>
                                <Grid item lg={12} spacing={3}>
                                    <TextField
                                        style={{ width: '90%', marginTop: 10 }}
                                        id="outlined-basic"
                                        label={'პირადი ნომერი'}
                                        variant="outlined"
                                        type="text"
                                        name="personalId"
                                        onChange={(value) => {
                                            setNotFound(false)
                                            handleChange(value)
                                        }}
                                        onBlur={handleBlur}
                                        value={values.personalId}
                                    />

                                </Grid>
                                <Grid item lg={12} spacing={3}>
                                    {notFound && <span className="error">
                                        პირადობის ნომერი არ მოიძებნა

                                    </span>}
                                    <ErrorMessage name="personalId" >
                                        {msg => <span className="error">{msg}</span>}
                                    </ErrorMessage>
                                </Grid>
                            </Grid>
                            <DialogActions>

                                <Button onClick={handleCloseDialog} color="primary">
                                    გაუქმება
                                </Button>
                                <Button color="primary"
                                    type="submit">
                                    გავაგზავნოთ
                                </Button>
                            </DialogActions>
                        </Form>


                    )}
                </Formik>}
                {tokenMode && <Formik
                    validationSchema={validationSchemas['restorePassword']}
                    initialValues={{ personalId: '' }}
                    onSubmit={getValuesRestore}>
                    {({ values, handleSubmit, errors, touched, validateForm, setFieldValue, handleChange, handleBlur }: any) => (
                        <Form onSubmit={(event) => {
                            const [e] = Object.keys(errors);
                            if (e) {
                                error = e
                            } else {
                                error = null
                            }
                            console.log(error)
                            if (!Object.keys(errors).length) {
                                handleSubmit(event)
                            }
                        }}>
                            <Grid container >
                                {tokenMode && <Grid item lg={6}>
                                    <TextField
                                        style={{ width: '90%', marginTop: 10 }}
                                        id="outlined-basic"
                                        value={values.token}
                                        label={'მიღებული 4 ნიშნა კოდი'}
                                        variant="outlined"
                                        type="number"
                                        name="token"
                                        onChange={(event) => {
                                            console.log(event.target.value)
                                            setFieldValue("token", event.target.value)
                                        }}
                                        onBlur={handleBlur}
                                    />
                                </Grid>}
                                <Grid item lg={6} spacing={3}>
                                    <TextField
                                        style={{ width: '90%', marginTop: 10 }}
                                        id="outlined-basic"
                                        label={'პირადი ნომერი'}
                                        variant="outlined"
                                        type="text"
                                        name="personalId"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.personalId}
                                    />
                                    <ErrorMessage name="personalId" >
                                        {msg => <span className="error">{msg}</span>}
                                    </ErrorMessage>
                                </Grid>
                                <Grid item lg={6} spacing={3} className="text-field">
                                    <TextField
                                        style={{ width: '90%', marginTop: 10 }}
                                        id="outlined-basic"
                                        label={'პაროლი'}
                                        variant="outlined"
                                        type="password"
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                    />
                                    <ErrorMessage name="password" >
                                        {msg => <span className="error">{msg}</span>}
                                    </ErrorMessage>
                                </Grid>
                                <Grid item lg={6} spacing={3}>
                                    <TextField
                                        style={{ width: '90%', marginTop: 10 }}
                                        id="outlined-basic"
                                        label={'პაროლის დადასტურება'}
                                        variant="outlined"
                                        type="password"
                                        name="confirmpassword"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.confirmpassword}
                                    />
                                    <ErrorMessage name="confirmpassword" >
                                        {msg => <span className="error">{msg}</span>}
                                    </ErrorMessage>
                                </Grid>
                                <Grid item lg={12} spacing={3}>
                                    {tokenInvalid && <span className="error">
                                        მონაცემები არ არის სწორი. სცადეთ თავიდან.

                                    </span>}
                                    {error && <span className="error">
                                        შეავსეთ ყველა ველი
                                    </span>}

                                </Grid>
                            </Grid>
                            <DialogActions>

                                <Button onClick={handleCloseDialog} color="primary">
                                    გაუქმება
                                </Button>
                                <Button color="primary"
                                    type="submit">
                                    გავაგზავნოთ
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>}
            </DialogContent>

        </Dialog>)
}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { setSnackBar })(ForgotPasswordFormDialog)