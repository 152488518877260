import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function SimpleMenu(props) {
  const {clicked, icon, items, title} = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item) => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button 
        variant="contained"
        color="primary"
        aria-controls="simple-menu" 
        aria-haspopup="true" onClick={handleClick}
        endIcon={icon}
      >
        {title}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items?.length > 0 && items.map((item, index) => {
            return <MenuItem key={index} onClick={() => {
              clicked(item);
              setAnchorEl(null);
            }}>{item.fullName}</MenuItem>
        })}
      </Menu>
    </div>
  );
}