import { Dialog, Grid } from "@material-ui/core";
import { connect } from 'react-redux'
import React, { useEffect } from "react"
import CloseIcon from '@material-ui/icons/Close';
import { setSnackBar } from '../../models/application/applicationActions'
import SkillForm  from "../forms/SkillForm";


const SkillFormDialog = (props) => {
    
    const { existing, onClose, item,} = props
    
    return <div>
        <Dialog
            maxWidth='md'
            style={{ padding: 20 }}
            open={props.onOpen} onClose={onClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <div style={{ position: 'absolute', right: 10, top: 10 }}>
                <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                            onClose(false)
                    }} />
            </div>

            <Grid container spacing={2} lg={12} style={{marginTop: 0, padding: 10}}>        
                <div style={{paddingTop: 20, paddingLeft: 20, paddingBottom: 0}}> დავამატოთ ახალი ჩანაწერი</div>
                <SkillForm item={item} onClose={(r) => onClose(r)} existing={existing}/>
            </Grid>
        </Dialog>
    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(SkillFormDialog)