import { createStyles, Dialog, Grid, makeStyles, SnackbarOrigin, Theme, Typography } from "@material-ui/core";

import { connect } from 'react-redux'
import React, { useEffect, useState } from "react"
import CloseIcon from '@material-ui/icons/Close';
import { setSnackBar } from '../../models/application/applicationActions'
import { News } from "../../models/news/news";


export const EventDialog = (props) => {
    
    const { onOpen, onClose, item, setSnackBar, auth } = props
    const [localItem, setLocalItem] = useState<News>()

    useEffect(() => {
        if (item) {
            setLocalItem(item)
        }
    }, [item])

    const handleClose = () => {
        onClose({ open: false, item: null, reload: true })
    }

    return <div>
        <Dialog
            maxWidth='md'
            style={{ padding: 20 }}
            open={props.onOpen} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <div style={{ position: 'absolute', right: 10, top: 10 }}>
                <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        handleClose()
                    }} />
            </div>
            {localItem && <div style={{ width: 1800, padding: 20 }}>
                <Grid container spacing={0} lg={12} style={{}}>
                    <Grid item lg={3}>
                        { localItem.image &&
                            <img src={localItem.image} style={{width: '100%'}}/>

                        }
                        <div style={{ padding: 10 }}>
                            
                            <Typography variant="h5" component="div"> 
                                <b> {localItem.title} </b>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item lg={3}></Grid>
                </Grid>
                <Grid container spacing={0} lg={6}>
                    <Grid item lg={12}>
                        <div style={{ padding: 10 }}>
                            <Typography variant="h5" component="div">
                                <br />
                                <br />
                                <span dangerouslySetInnerHTML={{__html: localItem.description}}></span>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>}
        </Dialog>
    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(EventDialog)