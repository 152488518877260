import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import "moment/locale/ka"

import moment from "moment"
import CreateIcon from '@material-ui/icons/Create';
import { connect } from 'react-redux'
import { TableHead, Typography } from '@material-ui/core';
import { setSnackBar } from '../../models/application/applicationActions'
import { environment } from '../../environment/environment';
import { CVExperience } from '../../models/cv/cv';
import ExperienceFormDialog from '../CV/ExperienceFormDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import { cvService } from '../../models/cv/service';
import { ConfirmationDialog } from '../Dialogs/ConfirmationDialog';


const BASE_URL = environment.basePath


const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});



moment.locale("ka")

const ExperienceTableListComponent = (props) => {
    const { experiences, onReload, setSnackBar, user, id } = props
    const classes = useStyles2();
    const [items, setItems] = useState<CVExperience[]>([]);
    const [confirmation, setConfirmation] = useState({ open: false, text: '', callback: null })
    const [dialog, setDialog] = useState({ open: false, item: null, reload: false });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    let emptyRows

    useEffect(() => {
        if (experiences) {
            setItems(experiences)
        }
    }, [experiences])

    useEffect(() => {
        if (items.length) {
            emptyRows = rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);
        }
    }, [items])

    const tableContent = (<TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
                <TableRow>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        დაკავებული პოზიცია
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: 'bold' }}>
                        კომპანია
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: 'bold' }}>
                        ქვეყანა
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        დაწყების თარიღი
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        დასრულების თარიღი
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>დეტალები</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    (rowsPerPage > 0
                        ? items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : items
                    ).map((item: CVExperience) => (
                        <TableRow key={item.id}>
                            <TableCell align="center">
                                {item.title}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.company}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.country.name}
                            </TableCell>
                            <TableCell align="center">
                                {item.startDate}
                            </TableCell>
                            <TableCell align="center">
                                {item.isCurrent ? 'მიმდინარე' : item.endDate}
                            </TableCell>
                            <TableCell align="center">
                                <DeleteIcon style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setConfirmation({
                                            text: 'ნამდვილად გსურთ ჩანაწერის წაშლა?', open: true, callback: (value) => {
                                                if (value) {
                                                    cvService.delete(`experiences_${item.id}`).then(resp => {
                                                        if (resp.id) {
                                                            onReload()
                                                            setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'სამუშაო გამოცდილება წარმატებით წაიშალა.', open: true })
                                                        }
                                                    })
                                                }
                                                setConfirmation({ text: '', callback: null, open: false })
                                            }
                                        })
                                    }} />
                                <CreateIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => { setDialog({ open: true, reload: false, item }) }}
                                />
                            </TableCell>

                        </TableRow>
                    ))}
                {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </TableContainer>)

    return <Fragment>
        <ConfirmationDialog
            title="ჩანაწერის წაშლა"
            open={confirmation.open}
            text={confirmation.text} callback={confirmation.callback} />
        <ExperienceFormDialog onOpen={dialog.open} onClose={(open) => {
            onReload()
            setDialog({ ...dialog, open })
        }} item={dialog.item} />
        { user?.id === id  && <Button color="primary" variant="outlined" onClick={() => {
            setDialog({ open: true, reload: false, item: null })
        }}>
            ახალი ჩანაწერი
        </Button>}
        {items.length > 0 ? <div>{tableContent}</div> : <div style={{ marginTop: 20 }}>
            <Typography variant="h6">
                ჩანაწერები არ მოიძებნა
                </Typography>

        </div>}


    </Fragment >
}

const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user
    }
}

export default connect(mapStateToProps, { setSnackBar })(ExperienceTableListComponent)