import { Dialog, Grid } from "@material-ui/core";
import YouTube from 'react-youtube';
import { connect } from 'react-redux'
import React, { useEffect, useState } from "react"
import CloseIcon from '@material-ui/icons/Close';
import { setSnackBar } from '../../models/application/applicationActions'
import { News } from "../../models/news/news";


const TrainingDialog = (props) => {
    const { onClose, item } = props
    const [localItem, setLocalItem] = useState<News>()
    const opts: any = {


        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };
    useEffect(() => {
        if (item) {
            setLocalItem(item)
        }
    }, [item])



    const handleClose = () => {
        onClose({ open: false, item: null, reload: true })
    }


    return <div>
        <Dialog
            maxWidth='md'
            style={{ padding: 20 }}
            open={props.onOpen} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <div style={{ position: 'absolute', right: 10, top: 10 }}>
                <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        handleClose()
                    }} />
            </div>

            <Grid container spacing={0} lg={12} style={{padding: 40}}>
                <YouTube videoId={item?.videoId} opts={opts} />

            </Grid>


        </Dialog>
    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(TrainingDialog)