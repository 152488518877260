import { NameIdItemSerializer } from "../common/serializer"
import { Resource } from "../resource"
import { UserSerializer } from "../user/serializer"
import { EmployeeFile, EmployeeFileShare } from "./document"

export class EmployeeFileSerializer extends Resource {
    fromJson(json: any) {
        const f = new EmployeeFile()
        f.name = json.name
        f.id = json.id
        const serializer = new NameIdItemSerializer()
        const userSerializer = new UserSerializer()
        if (json.employee) {
            f.employee = userSerializer.fromJson(json.employee)
        }
        f.filename = json.filename
        if (json.tags){
            f.tags = json.tags.map(t => serializer.fromJson(t))
        }
        f.created = json.created
        f.ext = json.ext
        
        return f
    }
    toJson(item: EmployeeFile) {
        return {
            tags: item.tags,
            name: item.name, 
            file: item.file
        }
    }
}
export class EmployeeFileShareSerializer extends Resource {
    fromJson(json: any) {
        const fs = new EmployeeFileShare()
        const serializer = new EmployeeFileSerializer()
        fs.file = serializer.fromJson(json.file)
        fs.id = json.id
        fs.created = json.created
        const userSerializer = new UserSerializer()
        if (json.employee) {
            fs.employee = userSerializer.fromJson(json.employee)
        }
        
        return fs
    }
    toJson(item: EmployeeFileShare) {
        return {
            employees: item.employees,
            file_id: item.file
        }
    }
}