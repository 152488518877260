import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { QueryOptions } from '../../helpers/query.options';
import "moment/locale/ka"
import moment from "moment"
import { connect } from 'react-redux'
import { Grid, TableHead, TextField, Typography } from '@material-ui/core';
import { userListService } from '../../models/user/service';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SimpleMenu from '../../components/Menu/SimpleMenu';
import { useHistory } from "react-router-dom";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { setSnackBar } from '../../models/application/applicationActions'
import CreateIcon from '@material-ui/icons/Create';
import {
    useLocation
} from "react-router-dom";
import { User } from '../../models/user/user';
import { canPerformAction } from '../../routing';
import { requestConfirmationStatusService, temporaryTransferRequestConfirmationService, temporaryTransferRequestService } from '../../models/request/service';
import { RequestConfirmation, TemporaryTransferRequest } from '../../models/request/request';

import { environment } from '../../environment/environment';
import TemporaryTransferRequestForm from './TemporaryTransferRequestForm';
import { TablePaginationActions } from '../../components/Common/Table/Pagination';
import { ConfirmationDialog } from '../../components/Dialogs/ConfirmationDialog';

const BASE_URL = environment.basePath
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

moment.locale("ka")


const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});

const TemporaryTransferRequestsTablePage = (props) => {
    let history = useHistory()
    let query = useQuery();
    const { setSnackBar } = props
    const classes = useStyles2();
    const [dialog, setDialog] = useState({ open: false, item: null, reload: false });
    const [page, setPage] = useState(0);
    const [employees, setEmployees] = useState<User[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [entries, setEntries] = useState<TemporaryTransferRequest[]>([])
    const [branchEmployees, setBranchEmployees] = useState([] as any)
    const [filterEmployee, setFilterEmployee] = useState({} as User)
    const [employeeId, setEmployeeId] = useState(null as any)
    const [confirmation, setConfirmation] = useState({ open: false, text: '', callback: null, item: null })
    const [user, setUser] = useState({} as any)
    const [statusList, setStatusList] = useState({} as any)

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, entries.length - page * rowsPerPage);

    const loadStatuses = () => {
        const options = new QueryOptions()
        requestConfirmationStatusService.list(options).then(list => {
            setStatusList(list.results)
        })
    }

    useEffect(() => {
        loadStatuses()
        const unlisten = history.listen((location, action) => {
            const { search } = location
            if (search) {
                const employee = location?.search?.split("?")[1].split("=")[1]
                setEmployeeId(employee)
                return
            }
            setEmployeeId(null)
        });
        const employee = query.get("employee")
        if (employee) {
            setEmployeeId(employee)
        }
        const options = new QueryOptions()
        options.workingDay = true
        getBranchEmployees(options).then((employees: User[]) => {
        setEmployees(employees)
        })
        return () => {
            clearInterval();
            return unlisten
        }
        

    }, [])

    useEffect(() => {
        if (filterEmployee) {
            loadRequests(filterEmployee?.id)
        } else {
            loadRequests(null)
        }
    }, [filterEmployee])

    useEffect(() => {
        getEmployees()
        const [employee] = branchEmployees.filter(e => e.id === employeeId)
        setFilterEmployee(employee)
    }, [employeeId])

    useEffect(() => {
        if (props.user) {
            setUser(props.user)
        }
    }, [props.user])

    useEffect(() => {
        if (dialog.reload) {
            setDialog({ ...dialog, reload: false, open: false, item: null })
            loadRequests(null)
        }
    }, [dialog])

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };



    const getEmployees = () => {
        const options = new QueryOptions();
        options.workingDay = true 
        getBranchEmployees(options).then((employees: User[]) => {
            setBranchEmployees(employees)
            if (employeeId) {
                const [employee] = employees?.filter(e => e.id === employeeId)
                setFilterEmployee(employee)
            }
        })
    }

    const getBranchEmployees = (options) => new Promise((resolves, rejects) => {
        userListService.list(options).then(r => {
            if (r.results) {
                resolves(r.results)
            } else {
                rejects({})
            }
        })
    })

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const updateStatus = (status: string, requestId: string) => {
        const confirmation = new RequestConfirmation()
        confirmation.status = status
        confirmation.requestId = requestId
        temporaryTransferRequestConfirmationService.create(confirmation).then(r => {
            if (r.requestId) {
                setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'სტატუსი განახლდა წარმატებით', open: true })
                setTimeout(() => {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                }, 5000)
                loadRequests(null)
            }
        })
    }
    const loadRequests = (employee) => {
        const options = new QueryOptions();
        if (employee) {
            options.employee = employee
        }
        options.sort = "created"
        options.order = 'desc'
        temporaryTransferRequestService.listWithCounter(options).then(r => {
            if (!r.status && r.current) {
                setEntries(r.current)
                const [employee] = branchEmployees.filter(e => e.id === employeeId)
                setFilterEmployee(employee)

            }
        })
    }

    const showNewItem = (employee) => {
        setDialog({ open: false, item: null, reload: false })
        const item = new TemporaryTransferRequest()
        item.employee = employee
        item.responsible = props?.user
        setDialog({ open: true, item, reload: false })
    }

    const tableContent = (<TableContainer component={Paper}>

        <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
                <TableRow>

                    <TableCell style={{ fontWeight: 'bold' }}>თანამშრომელი</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>პასუხისმგებელი</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>დაწყების თარიღი</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>დასრულების თარიღი</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>შექმნის თარიღი</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>სტატუსი</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>დეტალები</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold', width: 360 }}>ცვლილებები</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>

                {
                    (rowsPerPage > 0
                        ? entries.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : entries
                    ).map((item: TemporaryTransferRequest) => (
                        <TableRow key={item.id}>
                            <TableCell component="th" scope="row" style={{ width: 200 }} >
                                {item.employee.fullName}
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ width: 200 }} >
                                {item.responsible.fullName}
                            </TableCell>

                            <TableCell style={{ width: 160 }} align="center">
                                {moment(item?.fromDate).format('DD-MM-YYYY')}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {moment(item?.toDate).format('DD-MM-YYYY')}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {moment(item?.created).format('DD-MM-YYYY')}
                            </TableCell>

                            <TableCell style={{ width: 260 }} align="center">
                                {item?.confirmationStatus.name}
                            </TableCell>
                            <TableCell style={{ width: 260 }} align="center">
                                <CloudDownloadIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        window.open(`${BASE_URL}/assets/files/requests/${item.id}.pdf`, '_blank');
                                    }}
                                />
                            </TableCell>
                            <TableCell style={{ width: 460 }} align="center">
                                {canPerformAction(user, 'request_confirmation', 'write') && item?.employee.id !== user.id &&  
                                    <SimpleMenu title="სტატუსის შეცვლა"
                                        icon={<CreateIcon style={{ fontSize: 30 }} />} items={statusList.map(s => { return { fullName: s.name, id: s.id } })} clicked={(i) => {
                                            setConfirmation({ text: `მოთხოვნის სტატუსი გახდება: ${i.fullName}`, open: true, callback: null, item: { status: i.id, requestId: item.id } })

                                        }} />
                                }
                            </TableCell>

                        </TableRow>
                    ))}
                {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                    </TableRow>
                )}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={entries.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />

                </TableRow>
            </TableFooter>
        </Table>

    </TableContainer>)

    return <Fragment>

        <TemporaryTransferRequestForm onOpen={dialog.open} onClose={setDialog} item={dialog.item} />
        <ConfirmationDialog open={confirmation.open} text={confirmation.text} callback={(c) => {
            if (c) {
                const { status, requestId } = confirmation.item
                updateStatus(status, requestId)
            }
            setConfirmation({ text: '', open: false, callback: null } as any)
        }} />
        <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 20 }} lg={12}>

            <Grid item lg={12} style={{ textAlign: 'center' }}>
                <h3>დროებითი გადაყვანის მოთხოვნები</h3>
            </Grid>


            <Grid item lg={2}></Grid>
            <Grid item lg={2}>
                {canPerformAction(user, 'dismiss_request', 'write') &&
                    <SimpleMenu title="ახალი მოთხოვნა" icon={<AddCircleIcon style={{ fontSize: 30 }} />} items={employees} clicked={(item) => {
                        console.log(item)
                        showNewItem(item)
                    }} />
                }
            </Grid>
            <Grid item lg={4}>
                <Autocomplete
                    id="თანამშრომლები"
                    options={employees}
                    getOptionLabel={(option) => option.fullName}
                    style={{ width: 300 }}
                    onChange={(event, value) => {
                        if (value) {
                            const { id } = value as any
                            loadRequests(id)
                        } else {
                            loadRequests(null)
                        }
                    }}
                    renderInput={(params) => <TextField {...params} label="თანამშრომლები" variant="outlined" />}
                />
            </Grid>

        </Grid>
        <Grid container lg={12}>
            <Grid lg={1}></Grid>
            <Grid lg={10}>
                {entries.length > 0 ? <div>{tableContent}</div> : <div style={{ marginTop: 20 }}>
                    <Typography variant="h6">
                        ჩანაწერები არ მოიძებნა
                    </Typography>

                </div>}
            </Grid>
        </Grid>

    </Fragment >
}

const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user
    }
}

export default connect(mapStateToProps, { setSnackBar })(TemporaryTransferRequestsTablePage)