import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from "react-router-dom";
import { Avatar } from "@material-ui/core";

const propTypes = {
    nodeData: PropTypes.object.isRequired
};

const ChartCard = ({ nodeData }) => {
    let history = useHistory()
    const ITEM_HEIGHT = 30;
    const options = [
        'პროფილი'
    ];

    const [shown, setShown] = useState(false)
    const selectNode = () => {
        setShown(true)
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const clicked = () => {
        history.push(`/workingDayHoursForm?employee=${nodeData.id}`)
    }


    return (
        <div onClick={selectNode} style={{ position: 'relative' }}>
            <div className="position">
                <div style={{width: '100%', padding: 10, fontSize: 13}}>       
                    <div style={{width: '100%'}}>
                        <Avatar alt={nodeData.firstName} src={nodeData.photo} style={{width: 20, height: 20, position: 'absolute', left: 7, top: 10}}/>
                        <div style={{width: '80%', left: 20, position: 'absolute', top: 20}}>
                            {nodeData.title}
                        </div>
                    </div>
                </div>
                <div style={{ position: 'absolute', right: -5, top: 2 }}>
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        size="small"
                        style={{ color: 'white' }}
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: 140,
                            },
                        }}
                    >
                        {options.map((option) => (
                            <MenuItem key={option} selected={option === 'დეტალები'} onClick={() => {
                                if (option === 'ნამუშევარი დღეები') {
                                    clicked()
                                }
                                if (option === 'პროფილი') {
                                    history.push(`/profile/${nodeData.id}`)
                                }
                                handleClose()}} style={{fontSize: 12}}>
                                {option}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            </div>
            <div className="fullname">{nodeData.name}</div>
        </div>
    );
};

ChartCard.propTypes = propTypes;

export default ChartCard;
