import { Resource } from "../resource"
import { FAQ } from "./faq"


export class FAQSerializer extends Resource {
    fromJson(json: any) {
        const f = new FAQ()
        f.question = json.question
        f.text = json.text
        
        return f
    }
    toJson(item: any) {
        return {
            
        }
    }
}
