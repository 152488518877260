


import { ResourceProvider } from "../../providers/resource.provider"
import { NameIdItem } from "../common/common"
import { NameIdItemSerializer } from "../common/serializer"
import { TrainingSerializer } from "./serializer"
import { Training } from "./training"

export class TrainingProvider extends ResourceProvider<Training> {
    constructor() {
        super("rest_training", new TrainingSerializer())
    }
}


export class TrainingCategoryProvider extends ResourceProvider<NameIdItem> {
    constructor() {
        super("rest_training_category", new NameIdItemSerializer())
    }
}

export const trainingService = new TrainingProvider()
export const trainingCategoryService = new TrainingCategoryProvider()

