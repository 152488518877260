import {
    Button,
    Grid,
    Chip,
    TextField
} from "@material-ui/core";
import { Form, Formik } from "formik"
import { connect } from 'react-redux'

import React, { useEffect, useState } from "react"
import { validationSchemas } from "../../helpers/validations"
import { setSnackBar } from '../../models/application/applicationActions'
import SimpleMenu from '../../components/Menu/SimpleMenu';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { NameIdItem } from "../../models/common/common";
import { environment } from "../../environment/environment";
import { EmployeeFile } from "../../models/document/document";
import { employeeFileService } from "../../models/document/service";

const DocumentUploadForm = (props) => {

    const { onClose, item, setSnackBar, tag, tags, onReload } = props
    const [localValues, setLocalValues] = useState(null as any)
    const [localTags, setLocalTags] = useState<NameIdItem[]>()
    const [documentError, setDocumentError] = useState(false)
    const [file, setFile] = useState(null)
    const [selectedTags, setSelectedTags] = useState<NameIdItem[]>([])
    let inputElement

    useEffect(() => {
        setLocalValues({ name: '' })
    }, [item])

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClose = (item) => {
        setAnchorEl(null);
    };


    useEffect(() => {
        
        setLocalTags(tags)
        console.log(tags)
    }, [tags])

    const onDrop = (event) => {
        const [file] = event.target.files
        if (file) {
            if (environment.allowedDocumentFormats.indexOf(file.type) > -1) {
                if (file.size < 500000) {
                    setFile(file)
                } else {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'ფაილის ზომა დაშვებულზე მეტია', open: true })
                    setTimeout(() => {
                        setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                    }, 5000)
                }
            } else {
                setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'ფაილის ფორმატი არ არის დაშვებული', open: true })
                setTimeout(() => {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                }, 5000)
                console.log(file.type)
            }
        }
    }
    const getValues = (values) => {
        if (file) {
            const fileUpload = new EmployeeFile()
            fileUpload.name = values.name
            fileUpload.tags = values.tags
            fileUpload.file = file
            employeeFileService.createWithFile(fileUpload).then(r => {
                onReload()
                onClose(false)
                setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'დოკუმენტი აიტვირთა წარმატებით', open: true })
                setTimeout(() => {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'დოკუმენტი გაზიარებულია', open: false })
                }, 4000)
            })
        } else {
            setDocumentError(true)
        }
    }

    useEffect(() => {
        if (selectedTags?.length) {
            setLocalTags(tags.filter(t => selectedTags?.map(r => r.id).indexOf(t.id) < 0))
        }
    }, [selectedTags])

    const formik = <Formik validationSchema={validationSchemas['uploadDocument']}
        initialValues={{
            tags: ""
        }}
        onSubmit={getValues}>
        {({ values, handleSubmit, handleChange, setFieldValue, handleBlur, errors }: any) => (
            <Form onSubmit={(evt) => {
                console.log(errors, values)
                const [e] = Object.keys(errors);

                handleSubmit(evt)
            }}>
                <Grid container spacing={1} style={{}} lg={12}>
                    <input
                        style={{ visibility: 'hidden', position: 'absolute', top: -300 }}
                        ref={input => inputElement = input}
                        type="file" id="file" onChange={onDrop} name="image" />
                    {file && <Grid item lg={6}>
                        {file?.name}    
                    </Grid>}
                    {!file && <Grid item lg={6}>
                        <Button
                            onClick={() => {
                                inputElement.click()
                                setFieldValue("file",)
                            }}
                            style={{
                                position: 'relative',
                                top: 0,
                                height: 55,
                                width: '100%'
                            }}
                            color="primary" >ატვირთე დოკუმენტი</Button>
                    </Grid>}
                    <Grid item lg={6}>
                        <TextField
                            style={{ width: '100%', }}
                            id="outlined-basic"
                            label="დასახელება"
                            variant="outlined"
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur} />
                    </Grid>
                    <Grid item lg={12} >
                        {localTags.length > 0 &&
                            <SimpleMenu title="დავამატოთ თაგი"
                                icon={<AddCircleIcon style={{ fontSize: 30 }} />} items={localTags.map(r => { return { id: r.id, fullName: r.name, name: r.name } })} clicked={(t) => {
                                    setSelectedTags([...selectedTags, { ...t, name: t.name }])
                                    setFieldValue("tags", [...selectedTags, { ...t, name: t.name }].map(r => r.id).toString())
                                }} />}
                    </Grid>
                    
                    {selectedTags?.length > 0 && <Grid item lg={12}>
                        {selectedTags?.map((t, index) => {
                            return <Chip
                                label={t.name}
                                onDelete={() => {
                                    setSelectedTags([...selectedTags.filter(t_ => t_ !== t)])
                                    setFieldValue("tags", [...selectedTags.filter(t_ => t_ !== t)].map(r => r.id).toString())
                                }}
                            />
                        })}
                    </Grid>}
                    {documentError && <Grid item lg={12}>
                        <div className="error">
                            დოკუმენტი სავალდებულო ველია
                        </div>
                    </Grid>}

                    <Grid container lg={12} sm={11} md={12} style={{ padding: 20, marginTop: 20 }}>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                            <Button color="secondary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                onClick={() => onClose(false)}>გაქმება</Button>
                        </Grid>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 10 }}>
                            <Button color="primary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                type="submit">ავტვირთოთ</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Form>
        )}
    </Formik>
    return <div style={{ padding: 20 }}>



        {localValues ? formik : <div></div>}




    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(DocumentUploadForm)



