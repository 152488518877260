import React from 'react';

import { Avatar, Card, CardContent, CardHeader, createStyles, makeStyles, Theme, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 5,
            marginBottom: 20
        },
        avatar: {},
        media: {
            height: 0,
            paddingTop: '56.25%',
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    }),
);

export default function EmployeeCard(props) {
    const classes = useStyles();

    const { clicked, icon, employee } = props
    const history = useHistory();
    return <Card className={classes.root} style={{ alignItems: 'center', paddingTop: 0 }}>
        <Avatar
            alt={employee.fullName}
            src={employee.photo}
            className="avatar"
            style={{ width: 40, height: 40, marginLeft: 30, marginTop: 5 }} />
        <CardContent style={{ width: 120, overflow: 'hidden' }}>
            <div style={{ height: 60, display:'grid', minHeight:100 }}>
                <Typography className={classes.title} color="textSecondary" gutterBottom style={{fontSize:12, overflow: "hidden", textOverflow: "ellipsis", width: '100%'}}>
                    {employee.fullName}
                </Typography>
                <Typography className={classes.title} color="textSecondary" gutterBottom style={
                    {fontSize:10, fontWeight: 'bold', overflow: "hidden", textOverflow: "ellipsis", width: '100%'}}>
                    {employee.naviEmployee?.jobTitle}
                </Typography>
                <Typography className={classes.title} color="textSecondary">
                    <Button
                        color="primary"
                        style={{ backgroundColor: 'white', color: 'white', width: '100%', borderColor:  '#b9262d', borderRadius:3, borderWidth: 1}}
                        onClick={() => {
                            history.push(`/profile/${employee.id}`)
                        }} >
                        დეტალები
                    </Button>
                </Typography>

            </div>
        </CardContent>
    </Card>

}

