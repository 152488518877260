import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { QueryOptions } from "../../helpers/query.options"
import EventCard  from "../../components/Events/EventCard"
import "react-datepicker/dist/react-datepicker.css";
import { eventService, eventSubscriptionStatusService } from "../../models/event/service";
import { NameIdItem } from "../../models/common/common";
import { Grid } from "@material-ui/core";



const EventsSection = (props) => {
  const {setEvts} = props
  const [events, setEvents] = useState<Event[]>([])
  const [statuses, setStatuses] = useState<NameIdItem[]>([])
  useEffect(() => {
    getEventSubscriptionStatuses()
    getEvents()

  }, [])

  const getEvents = () => {
    const options = new QueryOptions()
    options.pageSize = 4
    options.sort = 'date'
    options.order= 'asc'
    eventService.listWithCounter(options).then(resp => {
      setEvents(resp.current)
      setEvts(resp.current)
    })
  }
  const getEventSubscriptionStatuses = () => {
    const options = new QueryOptions()
    options.pageSize = 20
    options.sort = 'sort'
    options.order= 'asc'
    options.date = ''
    eventSubscriptionStatusService.list(options).then(resp => {
        setStatuses(resp.results)
    })
  }
  return <Grid container lg={12} md={12} xs={12} style={{width: '100%'}}>
        { events?.length > 0 && events.map((event, index) => {
          return <Grid item lg={12} xs={12} sm={6} md={3} spacing={2} style={{margin: 0, padding: 5}}> <EventCard key={index} event={event} statuses={statuses}/></Grid>
        })}
  </Grid>
}


const mapStateToProps = (state: any) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, {})(EventsSection)