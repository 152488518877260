import { createStyles, Dialog, Grid, makeStyles, Select, Snackbar, SnackbarOrigin, Switch, TextField, Theme, Typography } from "@material-ui/core";
import { ErrorMessage, Form, Formik } from "formik"
import { connect } from 'react-redux'
import React, { useEffect, useState } from "react"
import MomentUtils from '@date-io/moment'
import "moment/locale/ka"
import moment from "moment"

import CloseIcon from '@material-ui/icons/Close';
import { setSnackBar } from '../../models/application/applicationActions'
import ExperienceForm  from "../../components/forms/ExperienceForm";


export interface State extends SnackbarOrigin {
    open: boolean;
    text: string;
}

const ExperienceFormDialog = (props) => {
    const { onOpen, onClose, item, setSnackBar, auth } = props
    
    return <div>
        <Dialog
            maxWidth='md'
            style={{ padding: 20 }}
            open={props.onOpen} onClose={onClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <div style={{ position: 'absolute', right: 10, top: 10 }}>
                <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                            onClose(false)
                    }} />
            </div>

            <Grid container spacing={2} lg={12} style={{marginTop: 0, padding: 10}}>
                <span style={{paddingTop: 20, paddingLeft: 20, paddingBottom: 0, fontSize: 18}}> სამუშაო გამოცდილება
                <br></br>
                <span style={{fontSize: 13}}>
                დავამატოთ ახალი ჩანაწერი
                </span>
                </span>        
                
                
                <ExperienceForm item={item} onClose={(r) => onClose(r)}/>
            </Grid>
        </Dialog>
    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(ExperienceFormDialog)