import React, { useEffect } from 'react';
import { Card, CardContent, CardHeader, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { environment } from '../../environment/environment';
import { GoogleMap, Marker, withGoogleMap, withScriptjs, InfoWindow } from "react-google-maps"


const useStyles = makeStyles((theme: Theme) =>

    createStyles({
        root: {
            marginBottom: 20,
            maxWidth: 345,
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    }),
);

export const GoogleMapContainer = (props) => {
    const { branches } = props
    let bounds: any = []

    const onMarkerClick = (branch) => {
        console.log(branch)
        branch.infoOpen = true
    }

    useEffect(() => {

    }, [branches])


    const MapWithAMarker: any = withScriptjs(withGoogleMap(props => {
        let latsSum = 0
        let lngsSum = 0
        if (branches) {
            for (var i = 0; i < branches?.length; i++) {
                bounds.push(
                    {
                        lat: branches[i].address.location.lat,
                        lng: branches[i].address.location.lng
                    }
                )
            }
            const lats = bounds.map(b => b.lat)
            lats.forEach(l => {
                latsSum += l
            })
            const lngs = bounds.map(b => b.lng)
            lngs.forEach(l => {
                lngsSum += l
            })
        }

        return <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: latsSum / bounds.length, lng: lngsSum / bounds.length }}
        >
            {branches?.map((branch, index) => {
                return <Marker

                    onClick={() => onMarkerClick(branch)}
                    key={index}
                    position={{ lat: branch.address.location.lat, lng: branch.address.location.lng }}
                >
                    <InfoWindow onCloseClick={() => { }}>
                        <div>
                            {branch?.uid}
                            <br />
                            {branch?.address.street}
                            <br />
                            <span>
                                <b>ტელ: </b>
                                {branch?.phone}
                            </span>
                            <br />
                            <span>
                                <b>ღიაა: </b>
                                {branch?.openTime} - {branch?.closeTime}
                            </span>

                        </div>
                    </InfoWindow>
                </Marker>

            })}
        </GoogleMap>
    }))

    return <div style={{ position: 'relative', top: 0, zIndex: 100, width: '100%' }}>
        {branches?.length && <MapWithAMarker
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${environment.GooleMapsKey}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px`, width: '100%', position: 'absolute', paddingBottom: 120 }} />}
            mapElement={<div style={{ height: `100%`, marginBottom: 220 }} />}
        />}

    </div>


}

