

import { ResourceProvider } from "../../providers/resource.provider"
import { NameIdItem } from "../common/common"
import { NameIdItemSerializer } from "../common/serializer"
import { Branch, BranchWorkingHoursTable } from "./branch"
import { BranchSerializer, BranchWorkingHoursTableSerializer } from "./serializer"


export class BranchProvider extends ResourceProvider<Branch> {
    constructor() {
        super("rest_branch", new BranchSerializer())
    }
}
export class BranchTypeProvider extends ResourceProvider<NameIdItem> {
    constructor() {
        super("rest_branch_type", new NameIdItemSerializer())
    }
}
export class WeekDayProvider extends ResourceProvider<NameIdItem> {
    constructor() {
        super("rest_week_day", new NameIdItemSerializer())
    }
}
export class BranchWorkingDaysTableProvider extends ResourceProvider<BranchWorkingHoursTable> {
    constructor() {
        super("rest_branch_working_hour_table", new BranchWorkingHoursTableSerializer())
    }
}

export const branchWorkingHoursTableService = new BranchWorkingDaysTableProvider()
export const branchService = new BranchProvider()
export const branchTypeService = new BranchTypeProvider()
export const weekDayService = new WeekDayProvider()
