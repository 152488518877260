import React from "react";
import "./App.css";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Header from "./components/header";
import Footer from "./components/footer";
import { Provider } from 'react-redux'
import { store } from './redux/store'
import {
  BrowserRouter as Router
} from "react-router-dom";
import { createBrowserHistory } from "history";
import Routing from './routing'


export const customHistory = createBrowserHistory();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    leftcolumn: {
      padding: 10
    }
  })
);



function App() {
  const classes = useStyles();

  return (
    <Provider store={store}>
      <div className="App" style={{ padding: 0 }}>
        <Router forceRefresh={false}>
          <Header />
          <div style={{ padding: 20 }}>
            <Routing style={{ overflow: 'hidden' }} />

          </div>

          <Footer />
        </Router>
      </div>
    </Provider>
  );
}

export default App;
