import { Dialog, Grid, SnackbarOrigin } from "@material-ui/core";
import { connect } from 'react-redux'
import React, { useEffect } from "react"
import "moment/locale/ka"
import CloseIcon from '@material-ui/icons/Close';
import { setSnackBar } from '../../models/application/applicationActions'
import EducationForm  from "../../components/forms/EducationForm";


export interface State extends SnackbarOrigin {
    open: boolean;
    text: string;
}

const EducationFormDialog = (props) => {
    
    const {onClose, item} = props
    
    useEffect(() => {
        if (item) {
            
        }
    }, [item])


    return <div>
        <Dialog
            maxWidth='md'
            style={{ padding: 20 }}
            open={props.onOpen} onClose={onClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <div style={{ position: 'absolute', right: 10, top: 10 }}>
                <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                            onClose(false)
                    }} />
            </div>

            <Grid container spacing={2} lg={12} style={{marginTop: 0, padding: 10}}>        
                <div style={{paddingTop: 20, paddingLeft: 20, paddingBottom: 0}}> დავამატოთ ახალი ჩანაწერი</div>
                <EducationForm item={item} onClose={(r) => onClose(r)}/>
            </Grid>
        </Dialog>
    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(EducationFormDialog)