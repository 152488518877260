import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TablePaginationActions } from './../../components/Common/Table/Pagination'
import { QueryOptions } from '../../helpers/query.options';

import "moment/locale/ka"
import moment from "moment"

import { connect } from 'react-redux'
import { Grid, TableHead, Typography } from '@material-ui/core';
import { setSnackBar } from '../../models/application/applicationActions'

import {
    BrowserRouter as Router,
    useLocation
} from "react-router-dom";

import { vacancyService } from '../../models/vacancy/service';
import { Vacancy } from '../../models/vacancy/vacancy';
import { Button } from '@material-ui/core';
import VacancyDialog from '../../components/Dialogs/VacancyDialog';

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});



moment.locale("ka")

const VacancyTableListPage = (props) => {
    const classes = useStyles2();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [dialog, setDialog] = useState({ open: false, item: null, reload: false });
    const [page, setPage] = useState(0);
    const [vacancies, setVacancies] = useState<Vacancy[]>([])
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, vacancies?.length - page * rowsPerPage);

    const handleClose = (item) => {
        setAnchorEl(null);
    };
    React.useEffect(() => {
        loadVacancies()
    }, [])

    const loadVacancies = () => {

        const options = new QueryOptions()
        options.sort = 'created'
        options.order = 'desc'
        options.pageSize = 30
        vacancyService.listWithCounter(options).then(r => {
            if (r) {
                setVacancies(r.current)
            }
        })
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const tableContent = (<TableContainer component={Paper}>
        {vacancies?.length > 0 && <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>პოზიცია</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        ხელმძღვანელი
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        განთავსების თარიღი
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        გამოწერილია
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        დეტალები
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>

                {
                    (rowsPerPage > 0
                        ? vacancies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : vacancies
                    ).map((item: Vacancy) => (
                        <TableRow key={item.id}>

                            <TableCell component="th" scope="row" style={{ width: 200 }}>
                                {item.position.name} - ({item.position.department.name})
                            </TableCell>
                            <TableCell style={{ width: 60 }} align="center">
                                {item.responsible.firstName} {item.responsible.lastName}
                            </TableCell>


                            <TableCell style={{ width: 160 }} align="center">
                                {item.created}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {item.userSubscribed ? <span>გაგზავნილია</span> : <span>არაა გაგზავნილი</span>}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                <Button
                                    color="primary"
                                    style={{ backgroundColor: 'white', color: 'red' }}
                                    onClick={() => {
                                        setDialog({ open: true, item, reload: false })
                                    }} >
                                    დეტალები
                                </Button>
                            </TableCell>

                        </TableRow>
                    ))}
                {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                    </TableRow>
                )}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={vacancies?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>}
    </TableContainer>)

    return <Fragment>

        <VacancyDialog onOpen={dialog.open} onClose={(c) => {
            loadVacancies()
            setDialog(c)
        }} item={dialog.item} />

        <Grid container
            style={{ textAlign: 'center', justifyContent: 'center' }}
            direction="row"
            lg={12} >
            <h2>
                ვაკანსიები
            </h2>

        </Grid>


        <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 20 }} lg={6}>
            <Grid item lg={3} style={{ textAlign: "right" }}></Grid>
            <Grid item lg={3} style={{ textAlign: "right" }}></Grid>
        </Grid>
        <Grid container lg={12}>
            <Grid lg={12}>
                {vacancies?.length > 0 ? <div>{tableContent}</div> : <div style={{ marginTop: 20 }}>
                    <Typography variant="h6">
                        ჩანაწერები არ მოიძებნა
                    </Typography>

                </div>}
            </Grid>
        </Grid>
    </Fragment >
}

const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user
    }
}

export default connect(mapStateToProps, { setSnackBar })(VacancyTableListPage)