import React, { useEffect, useState } from "react";

import { createStyles, Grid, GridList, GridListTile, GridListTileBar, ListSubheader, makeStyles, Theme } from "@material-ui/core";


import { connect } from 'react-redux'
import "moment/locale/ka"

import { chartService } from "../../models/user/service";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router";
import { QueryOptions } from "../../helpers/query.options";
import { branchEmployeeService } from '../../models/user/service'
import { User } from "../../models/user/user";
import OrgChart from "../../components/OrgChart/OrgChart";
import EmployeeCard from "../../components/Employee/EmployeeCard";
import AppsIcon from '@material-ui/icons/Apps';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ListIcon from '@material-ui/icons/List';
import BranchEmployeeTableListComponent from '../../components/Table/BranchEmployeeTable'
import { branchService } from "../../models/branch/service";
import { Branch } from "../../models/branch/branch";
import { createBrowserHistory } from "history";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        gridList: {
            width: '100%',

        },
        icon: {
            color: 'rgba(255, 255, 255, 0.54)',
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        leftcolumn: {
            padding: 10
        }
    })
);

const BranchPage = (props) => {
    let { id }: any = useParams();
    const classes = useStyles();
    const [chartData, setChartData] = useState([])
    const [view, setView] = useState("chart")
    const [branch, setBranch] = useState<Branch>()
    const [branchEmployees, setBranchEmployees] = useState<User[]>([])
    const [anyEmployee, setAnyEmployee] = useState<User>()
    const history = createBrowserHistory()

    const getEmployees = () => {
        const options = new QueryOptions()
        options.type = 'list'
        options.branch = id
        options.pageSize = 200
        branchEmployeeService.listWithCounter(options).then((employees: any) => {
            setBranchEmployees(employees.current)
            const [employee] = employees.current
            if (employee) {
                setAnyEmployee(employee)
                getTreeForChart(employee.id)
            }
        })
    }
    const getBranch = (id) => {
        const options = new QueryOptions()
        options.pageSize = 20
        branchService.read(id).then(r => {
            setBranch(r)
        })
    }

    const getTreeForChart = (employee) => {
        const options = new QueryOptions()
        options.employee = employee
        options.level = "branch"
        options.branch = id
        chartService.list(options).then(users => {
            if (users?.results) {
                setChartData(users.results[0])
            }
        })
    }

    useEffect(() => {
        getEmployees()
        getBranch(id)
    }, [])


    return <div className={classes.root}>
        {branch && <Grid container
            style={{ textAlign: 'center', justifyContent: 'center' }}
            direction="row"
            lg={12} >
            <h2>
                {branch.uid}
            </h2>
        </Grid>}
        <Grid container spacing={0}>
            <Grid item xs={6} lg={3}>

            </Grid>
            <Grid item xs={6} lg={3}>
                <b>
                    ხელმძღვანელები:
                </b>
                {branch?.managers.map((item, index) => {
                    return <p style={{ cursor: 'pointer' }} onClick={() => {
                        history.push(`/branches?manager=${item.id}`)
                        window.location.reload()
                    }}> {item.fullName}</p>
                })}
            </Grid>
            <Grid item xs={6} lg={3}>
                <b>
                    გაყიდვების მენეჯერი:
                </b>
                {branch?.salesManager &&
                    <p style={{ cursor: 'pointer' }} onClick={() => {

                    }}> {branch?.salesManager.fullName}</p>
                }
            </Grid>
            <Grid item xs={6} lg={12}>
                <AppsIcon onClick={() => {
                    setView("grid")
                }} />
                <AccountTreeIcon onClick={() => {
                    setView("chart")
                }} />
                <ListIcon onClick={() => {
                    setView("list")
                }} />

                {view === 'chart' ?
                    chartData && anyEmployee?.id ?
                        <OrgChart data={chartData} /> : <div style={{ padding: 20 }}> ამ ფილიალზე არ არის დაფიქსირებული მომხმარებელი</div> :
                    view === 'grid' ?
                        <div style={{ margin: 40 }}>
                            <GridList cellHeight={180} className={classes.gridList}>
                                <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>

                                </GridListTile>
                                {branchEmployees.map((empl, key) => {
                                    return <EmployeeCard employee={empl} key={key} />
                                })}
                            </GridList>

                        </div>
                        :
                        <div style={{ margin: 40 }}>
                            <BranchEmployeeTableListComponent branchEmployees={branchEmployees} />
                        </div>
                }
            </Grid>


        </Grid>
    </div >
}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(BranchPage)