

import { ResourceProvider } from "../../providers/resource.provider"
import { NameIdItemSerializer } from "../common/serializer"
import { CV, Degree, FieldOfStudy, Level, Skill, SpokenLanguage } from "./cv"
import { CVSerializer } from "./serializer"
export class CVProvider extends ResourceProvider<CV> {
    constructor() {
        super("rest_cv", new CVSerializer())
    }
}
export class FieldOfStudyProvider extends ResourceProvider<FieldOfStudy> {
    constructor() {
        super("rest_field_of_study", new NameIdItemSerializer())
    }
}
export class DegreeProvider extends ResourceProvider<Degree> {
    constructor() {
        super("rest_degree", new NameIdItemSerializer())
    }
}
export class SkillProvider extends ResourceProvider<Skill> {
    constructor() {
        super("rest_skill_type", new NameIdItemSerializer())
    }
}
export class LevelProvider extends ResourceProvider<Level> {
    constructor() {
        super("rest_level", new NameIdItemSerializer())
    }
}
export class SpokenLanguageProvider extends ResourceProvider<SpokenLanguage> {
    constructor() {
        super("rest_spoken_language", new NameIdItemSerializer())
    }
}

export const fieldOfStudyService = new FieldOfStudyProvider()
export const degreeService = new DegreeProvider()
export const cvService = new CVProvider()
export const skillService = new SkillProvider()
export const levelService = new LevelProvider()
export const spokenLanguageService = new SpokenLanguageProvider()
