import {
    Button,
    Grid,
    Chip,
    TextField
} from "@material-ui/core";
import { Form, Formik } from "formik"
import { connect } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from "react"
import { validationSchemas } from "../../helpers/validations"
import { setSnackBar } from '../../models/application/applicationActions'
import FaceIcon from '@material-ui/icons/Face';
import { NameIdItem } from "../../models/common/common";
import { environment } from "../../environment/environment";
import { EmployeeFileShare } from "../../models/document/document";
import { employeeFileShareService } from "../../models/document/service";

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { User } from "../../models/user/user";
import { branchEmployeeService } from "../../models/user/service";
import { QueryOptions } from "../../helpers/query.options";

const onSearch$: any = new Subject().pipe(
    debounceTime(1000)
)

const DocumentShareForm = (props) => {
    const [employees, setEmployees] = useState<User[]>([]);
    const { onClose, item, tag, tags, setSnackBar, auth } = props
    const [localValues, setLocalValues] = useState(null as any)
    const [shareEmployees, setShareEmployees] = useState<User[]>()
    const [selectedEmployees, setSelectedEmployees] = useState<User[]>([])
    const [localTags, setLocalTags] = useState<NameIdItem[]>()
    const [file, setFile] = useState(null)
    const [selectedTags, setSelectedTags] = useState<NameIdItem[]>()
    let inputElement
    useEffect(() => {
        setLocalValues({ name: '' })
    }, [item])

    const getShares = () => {
        const options = new QueryOptions()
        options.list = true
        options.file = item?.id
        employeeFileShareService.listWithCounter(options).then(r => {
            if (!r.status) {
                setShareEmployees(r.current.map(i => i.employee))
            }
        })
    }

    useEffect(() => {
        getShares()
        onSearch$.subscribe(
            debounced => {
                getEmployees(debounced)
            }
        );
    }, [])
    const getEmployees = (search) => {
        const options = new QueryOptions();
        if (search) {
            options.search = search
        }
        options.type = 'list'
        options.pageSize = 10
        branchEmployeeService.listWithCounter(options).then((employees: any) => {
            if (employees && employees.current) {
                setEmployees(employees.current.filter(t => selectedEmployees?.map(r => r.id).indexOf(t.id) < 0))
            }
        })
    }

    useEffect(() => {
        setLocalTags(tags)
        setSelectedTags([tag])
    }, [tag, tags])

    const onDrop = (event) => {
        const [file] = event.target.files
        if (file) {
            if (environment.allowedDocumentFormats.indexOf(file.type) > -1) {
                if (file.size < 500000) {
                    setFile(file)
                } else {
                    console.log("too big")
                }
            } else {
                console.log(file.type)
            }
        }
    }
    const getValues = (values) => {
        const share = new EmployeeFileShare()
        share.employees = values.employees
        share.file = values.file
        employeeFileShareService.create(share).then(r => {
            onClose(false)
            setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'დოკუმენტი გაზიარებულია', open: true })
            setTimeout(() =>{
                setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'დოკუმენტი გაზიარებულია', open: false })
            }, 4000)

        })
    }



    const formik = <Formik validationSchema={validationSchemas['shareDocument']}
        initialValues={{
            employees: null,
            file: item.id
        }}
        onSubmit={getValues}>
        {({ values, handleSubmit, handleChange, setFieldValue, handleBlur, errors }: any) => (
            <Form onSubmit={(evt) => {
                const [e] = Object.keys(errors);

                handleSubmit(evt)
            }}>
                <Grid container spacing={2} style={{}}>
                    <input
                        style={{ visibility: 'hidden' }}
                        ref={input => inputElement = input}
                        type="file" id="file" onChange={onDrop} name="image" />
                    <Grid item lg={12} >
                        <Autocomplete
                            id="combo-box-demo"
                            options={
                                employees
                                .filter(t => selectedEmployees?.map(r => r.id).indexOf(t.id) < 0)
                                .filter(r => r.id !== auth?.user?.id)
                                .filter(t => shareEmployees?.map(r => r.id).indexOf(t.id) < 0)
                            }
                            onChange={(event, value: User) => {
                                if (value) {
                                    
                                    setFieldValue("employees", [...selectedEmployees.filter(emp_ => emp_ !== value), value].map(r => r.id).toString())
                                    setSelectedEmployees([...selectedEmployees.filter(emp_ => emp_ !== value), value])
                                }
                            }}
                            getOptionLabel={(option) => option.fullName}
                            style={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="მოძებნე თანამშრომლები" style={{ padding: 2 }} onChange={(value) => {
                                if (value.target.value.length) {
                                    onSearch$.next(value.target.value);

                                } else {
                                    getEmployees(null)
                                }
                            }} />}
                        />
                    </Grid>
                   
                    <Grid item lg={12}>
                    {selectedEmployees?.map((emp: User, index) => {
                            return <Chip
                                label={emp.fullName}
                                icon={<FaceIcon />}
                                onDelete={() => {
                                    setSelectedEmployees([...selectedEmployees.filter(emp_ => emp_ !== emp)])
                                    setFieldValue("employees", [...selectedEmployees.filter(emp_ => emp_ !== emp)].map(r => r.id).toString())
                                }}
                            />
                        })}
                        
                    </Grid>
                    

                    <Grid container lg={12} sm={11} md={12} style={{ padding: 20, marginTop: 20 }}>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                            <Button color="secondary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                onClick={() => onClose(false)}>გაქმება</Button>
                        </Grid>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 10 }}>
                            <Button color="primary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                type="submit">ავტვირთოთ</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Form>
        )}
    </Formik>
    return <div style={{ padding: 20 }}>



        {localValues ? formik : <div></div>}




    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(DocumentShareForm)



