import { Button, Dialog, FormControl, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@material-ui/core";
import { ErrorMessage, Form, Formik } from "formik"
import { connect } from 'react-redux'
import React, { useEffect, useState } from "react"
import MomentUtils from '@date-io/moment'
import "moment/locale/ka"
import moment from "moment"
import { workingDayHoursOptionsService, workingDayHoursService } from '../../../models/WorkingDayHour/service'
import { validationSchemas } from "../../../helpers/validations"
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { QueryOptions } from "../../../helpers/query.options"
import { WorkingDayHours, WorkingDayHoursOption } from "../../../models/WorkingDayHour/wokringDay"
import CloseIcon from '@material-ui/icons/Close';
import { setSnackBar } from '../../../models/application/applicationActions'
import { canPerformAction } from "../../../routing";


const WorkingHoursEditForm = (props) => {
    moment.locale("ka")
    const { onClose, item, auth } = props
    const [locale, setLocale] = useState("ka")

    const [options, setOptions] = useState([{ id: "empty", name: "სტატუსის გარეშე" }] as Array<WorkingDayHoursOption>)
    const [wdh, setWdh] = useState(null as WorkingDayHours)
    const [localValues, setLocalValues] = useState(null as any)



    useEffect(() => {
        const opts = new QueryOptions()
        workingDayHoursOptionsService.list(opts).then(resp => {
            if (!resp.status) {
                setOptions(options.concat(resp.results))
            }
        })
    }, [])

    useEffect(() => {
        if (item) {
            setWdh(item)
        }
    }, [item])

    const handleClose = () => {
        setLocalValues(null)
        setWdh(null)
        onClose({ open: false, item: null, reload: true })
    }

    useEffect(() => {
        if (wdh) {
            setLocalValues(null)
            const startTime = new Date(new Date().setHours(10))
            startTime.setMinutes(0);
            startTime.setSeconds(0);
            const endTime = new Date(new Date().setHours(19))
            endTime.setMinutes(0);
            endTime.setSeconds(0);
            setLocalValues({
                branch: wdh?.branch?.uid,
                responsible: wdh.responsible.fullName,
                employee: wdh.employee.fullName,
                option: wdh?.option,
                startTime,
                endTime,
                note: wdh.note
            })
        }
    }, [wdh])

    useEffect(() => {
        if (localValues) {
            console.log(localValues.employee)


        }
    }, [localValues])

    const getValues = (values) => {
        const id = localValues.option?.id ? localValues.option.id : 'empty'
        const [option] = options.filter(opt => opt.id === id)
        const wdhObj = { ...wdh, option, startTime: localValues.startTime, endTime: localValues.endTime, note: localValues.note }
        workingDayHoursService.create(wdhObj).then((resp: any) => {
            if (!resp.status) {
                handleClose()
                props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'ჩანაწერი დაემატა წარმატებით', open: true })
                setTimeout(() => {
                    props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                }, 5000)
            } else {
                let text = 'დაფიქსირდა შეცდომა'
                if (resp.status === 408) {
                    text = 'ჩანაწერი ამ დღეზე უკვე არსებობს'
                }
                if (resp.status === 403) {
                    text = 'თქვენ არ გაქვთ შექმნის უფლება.'
                }
                props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text, open: true })
                setTimeout(() => {
                    props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                }, 5000)
            }
        })
    }

    const formik = <Formik validationSchema={validationSchemas['workingDataHours']}
        initialValues={localValues}
        onSubmit={getValues}>
        {({ values, handleSubmit, handleChange, handleBlur }: any) => (
            <Form onSubmit={handleSubmit}>
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    <Grid item lg={12}>
                        <p>
                            <b>თარიღი:</b>
                            <span style={{ marginLeft: 10 }}>
                                {moment(localValues?.startTime).format('DD MMMM YYYY')}
                            </span>
                        </p>
                        <span>
                            გუშინ
                        </span>
                        <Switch
                            disabled={!canPerformAction(auth?.user, 'past_working_day_hours', 'write')}
                            defaultChecked
                            color="default"
                            onChange={(value) => {
                                let startTime, endTime;
                                if (value.target.checked) {
                                    startTime = new Date(new Date().setHours(10))
                                    startTime.setMinutes(0)
                                    startTime.setSeconds(0)
                                    endTime = new Date(new Date().setHours(19))
                                    endTime.setMinutes(0)
                                    endTime.setSeconds(0)

                                } else {
                                    var yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
                                    startTime = new Date(yesterday.setHours(10))
                                    startTime.setMinutes(0)
                                    startTime.setSeconds(0)
                                    endTime = new Date(yesterday.setHours(19))
                                    endTime.setMinutes(0)
                                    endTime.setSeconds(0)
                                }
                                setLocalValues({ ...localValues, startTime, endTime })
                            }}
                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                        />
                        <span>
                            დღეს
                        </span>

                    </Grid>

                    <Grid item lg={6}>
                        <TextField
                            style={{ width: '100%', }}
                            id="outlined-basic"
                            label={'მაღაზია / სტ.ერთეული'}
                            variant="outlined"
                            type="text"
                            disabled={true}
                            name="branch"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.branch} />
                    </Grid>
                    <Grid item lg={6}>
                        <TextField
                            style={{ width: '100%', height: 20 }}
                            id="outlined-basic"
                            label={'განაცხადზე პასუხისმგებელი'}
                            variant="outlined"
                            type="text"
                            disabled={true}
                            name="responsible"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.responsible} />
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="branch" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="responsible" >
                            {msg => <span className="error" style={{ marginTop: 10 }}>{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6}>
                        <TextField
                            style={{ width: '100%', height: 20 }}
                            id="outlined-basic"
                            label={'თანამშრომელი'}
                            variant="outlined"
                            type="text"
                            disabled={true}
                            name="employee"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.employee} />

                    </Grid>

                    <Grid item lg={6}>
                        <FormControl variant="outlined" style={{ minWidth: '100%' }}>
                            <InputLabel id="demo-simple-select-outlined-label">სტატუსი</InputLabel>
                            <Select
                                name="option"
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={localValues?.option?.id ? localValues?.option?.id : 'empty'}
                                onChange={(event: any) => {
                                    const [option] = options.filter(opt => opt.id === event.target.value)
                                    setWdh({ ...wdh, option: option })
                                    setLocalValues({ ...localValues, option: option })
                                }}
                                label="სტატუსი"
                            >
                                {options.map((option, index) => {
                                    return <MenuItem value={option.id} key={index} style={{ color: option.id === 'empty' ? 'darkred' : 'black' }}>
                                        <em>{option.name}</em>
                                    </MenuItem>

                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="employee" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}></Grid>
                    <Grid item lg={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
                            <DateTimePicker
                                views={['hours', 'minutes']}
                                style={{ marginLeft: 0 }}
                                label="დაწყება"
                                ampm={false}
                                inputVariant="outlined"
                                value={localValues.startTime}
                                format="HH:mm"
                                onChange={(date: any) => {
                                    setLocalValues({ ...localValues, startTime: date._d })
                                }}
                            />
                            <DateTimePicker
                                views={['hours', 'minutes']}
                                style={{ marginLeft: 20 }}
                                label="დასასრული"
                                inputVariant="outlined"
                                value={localValues.endTime}
                                format="HH:mm "
                                onChange={(date: any) => {
                                    setLocalValues({ ...localValues, endTime: date._d })
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 8 }}>
                        <TextField
                            name="note"
                            style={{ width: '100%' }}
                            id="outlined-multiline-static"
                            label="შენიშვნა"
                            rows={3}
                            defaultValue={values.note}
                            onChange={(event: any) => {
                                setWdh({ ...wdh, note: event.target.value })
                                setLocalValues({ ...localValues, endTime: event.target.value })
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="startTime" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                        <Button color="secondary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                            onClick={() => onClose(false)}>გაქმება</Button>
                    </Grid>

                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 10 }}>
                        <Button
                            disabled={!canPerformAction(props.auth?.user, 'working_day_supervisor', 'write')}
                            color="primary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                            type="submit">შენახვა</Button>

                    </Grid>

                </Grid>

            </Form>
        )}
    </Formik>
    return <div>

        <Dialog
            maxWidth='md'
            style={{ padding: 20 }}
            open={props.onOpen} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <div style={{ position: 'absolute', right: 10, top: 10 }}>
                <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        handleClose()
                    }} />
            </div>
            <div style={{ padding: 20 }}>
                <Typography variant="h5" component="div">
                    ნამუშევარი საათების რეგისტრაცია
                </Typography>
                {localValues ? formik : <div></div>}

            </div>

        </Dialog>
    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(WorkingHoursEditForm)