import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TablePaginationActions } from './../components/Common/Table/Pagination'
import { QueryOptions } from '../helpers/query.options';
import { newsService } from '../models/news/service'
import "moment/locale/ka"
import moment from "moment"

import { connect } from 'react-redux'
import { Grid, TableHead, Typography } from '@material-ui/core';
import {
    BrowserRouter as Router,
    Link,
    useLocation
} from "react-router-dom";
import { environment } from '../environment/environment';
import { Button } from '@material-ui/core';
import NewsDialog from '../components/Dialogs/NewsDialog';
import { News } from '../models/news/news';


const BASE_URL = environment.basePath


const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

moment.locale("ka")

const NewsTableListPage = (props) => {
    const classes = useStyles2();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [dialog, setDialog] = useState({ open: false, item: null, reload: false, type: "news" });
    const [page, setPage] = useState(0);
    const [news, setnews] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, news.length - page * rowsPerPage);


    React.useEffect(() => {
        loadnews()
    }, [])

    const loadnews = () => {
        const options = new QueryOptions()
        options.sort = 'created'
        options.order = 'desc'
        options.pageSize = 30
        newsService.listWithCounter(options).then(r => {
            setnews(r.current)
        })
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const tableContent = (<TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
                <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>სათაური</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>ტექსტი</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>შექმნის თარიღი</TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        დეტალები
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>

                {
                    (rowsPerPage > 0
                        ? news.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : news
                    ).map((item: News) => (
                        <TableRow key={item.id}>

                            <TableCell component="th" scope="row" style={{ width: 120, flex: 1 }} >
                                {/* <img src={item.image} style={{ width: 80, marginRight: 10 }} /> */}
                                <span>
                                    {item.title}

                                </span>
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ width: 250 }} >
                                {item.description.substring(0, 80)}
                            </TableCell>
                            <TableCell style={{ width: 60 }} align="center">
                                {item.created}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                <Button
                                    color="primary"
                                    style={{ color: 'white' }}
                                    onClick={() => {
                                        setDialog({ open: true, item, reload: false, type: 'news' })
                                    }} >
                                    დეტალები
                                </Button>
                            </TableCell>

                        </TableRow>
                    ))}
                {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                    </TableRow>
                )}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={news.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>)

    return <Fragment>
        <NewsDialog onOpen={dialog.open} onClose={setDialog} item={dialog.item} type="news" />
        <Grid lg={3}></Grid>
        <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 20 }} lg={6}>
            <Grid item lg={3} style={{ textAlign: "right" }}></Grid>
            <Grid item lg={3} style={{ textAlign: "right" }}></Grid>
        </Grid>
        <Grid container lg={12}>
            <Grid lg={2}></Grid>
            <Grid lg={8}>
                <div style={{ padding: 20 }}>
                    <Typography variant="h6">
                        სიახლეები
                    </Typography>
                </div>
                {news.length > 0 ? <div>{tableContent}</div> : <div style={{ marginTop: 20 }}>
                    <Typography variant="h6">
                        ჩანაწერები არ მოიძებნა
                    </Typography>

                </div>}
            </Grid>
        </Grid>
    </Fragment >
}

const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user
    }
}

export default connect(mapStateToProps)(NewsTableListPage)