import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const ConfirmationDialog = (props) => {
  const {text, callback, open, title} = props

  return (
    <Dialog
      open={open}
      onClose={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title? title: "ავტორიზაცია"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
          </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {callback(false)}} color="primary">
          გაუქმება
          </Button>
        <Button onClick={() => {callback(true)}} color="primary" autoFocus>
          დიახ
          </Button>
      </DialogActions>
    </Dialog>
  );
}
