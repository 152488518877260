import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { canAccessPage } from '../../routing';

export default function BarSubcategoryMenu(props) {
  const {clicked, icon, items, title, user} = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item) => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button 
        style={{color: '#FFFFFF'}}
        aria-controls="simple-menu" 
        aria-haspopup="true" onClick={handleClick}
      >
        {title}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items?.length > 0 && items.filter(i => canAccessPage(user, i.permissions) || i.permissions.indexOf('public')  > -1 ).map((item, index) => {
            return <MenuItem key={index} onClick={() => {
              clicked(item);
              setAnchorEl(null);
            }}>{item.name}</MenuItem>
        })}
      </Menu>
    </div>
  );
}