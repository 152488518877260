import { Button, Dialog, Grid } from "@material-ui/core";
import { connect } from 'react-redux'
import React , {useEffect} from "react"
import CloseIcon from '@material-ui/icons/Close';
import { setSnackBar } from '../../models/application/applicationActions'




const FileExportDialog = (props) => {
    const { onClose, link, onOpen, open } = props
    
    useEffect(() => {
        console.log(link)
    }, [link, open])
    
    return <div>
        <Dialog
            maxWidth='md'
            style={{ padding: 20 }}
            open={props.onOpen} onClose={onClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <div style={{ position: 'absolute', right: 10, top: 10 }}>
                <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        onClose(false)
                    }} />
            </div>

            <Grid container spacing={2} lg={12} style={{ marginTop: 0, padding: 30 }}>
                <div style={{ paddingTop: 20, paddingLeft: 20, paddingBottom: 0 }}> რეპორტი დაგენერირებულია</div>
                <Button color="primary" variant="outlined" style={{marginTop: 20, color: 'white'}} onClick={() => {
                    console.log(link)
                }}>
                    <a href={link} target="_blank" >
                        გადმოვწეროთ დოკუმენტი
                    </a>
                </Button>
            </Grid>
        </Dialog>
    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(FileExportDialog)