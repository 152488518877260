import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SnackbarOrigin, Switch, TextField, Theme
} from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { ErrorMessage, Form, Formik, useField } from "formik"
import { connect } from 'react-redux'

import React, { useEffect, useState } from "react"
import MomentUtils from '@date-io/moment'
import { validationSchemas } from "../../helpers/validations"

import { QueryOptions } from "../../helpers/query.options"
import { setSnackBar } from '../../models/application/applicationActions'

import { CV, CVTraining } from "../../models/cv/cv";
import { countryService } from "../../models/country/service";
import { Country } from "../../models/country/country";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "moment/locale/ka"
import moment from "moment"
import { cvService } from "../../models/cv/service";


const TrainingForm = (props) => {
    moment.locale("ka")
    const [locale, setLocale] = useState("ka")
    const { onClose, item, setSnackBar } = props
    const [countries, setCountries] = useState<Array<Country>>([])
    const [mode, setMode] = useState('edit')
    const [localValues, setLocalValues] = useState(null as any)
    let error: string = null
    
    const getValues = (values) => {
        const training = new CVTraining()
        training.year = `${values.year.getFullYear()}`
        training.country = {uid: values.country, id: values.country, name: ''}
        training.id = item ? item.id : null
        training.name = values.name
        training.company = values.company
        training.hours = values.hours
        const cv = new CV()
        cv.trainings = [training]
        
        if (mode === 'create') {
            cvService.create(cv).then(resp => {
                if (resp.id) {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'ტრეინინგი წარმატებით დაემატა.', open: true })
                    setTimeout(() => {
                        setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                    }, 5000)
                    onClose()
                }
            })
        } else {
            cvService.updateNoForm(cv).then(resp => {
                if (resp.id) {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'ტრეინინგი წარმატებით განახლდა.', open: true })
                    setTimeout(() => {
                        setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                    }, 5000)
                    onClose()
                }
            })
        }
    }

    useEffect(() => {
        setLocalValues({ name: '' })
        if (!item) {
            setMode('create')
        }
    }, [item])

    useEffect(() => {
        const options = new QueryOptions()
        options.pageSize = 140
        countryService.listWithCounter(options).then(e => {
            setCountries(e.current)
        })
    }, [])

    const formik = <Formik validationSchema={validationSchemas['training']}
    initialValues={item ? {
        ...item,
        country: item.country.id,
        year: new Date(`${item.year}-01-17T03:24:00`),
    }: {
        isCurrent: false, 
        country: "georgia",
        year: new Date()}}
        onSubmit={getValues}>
        {({ values, handleSubmit, handleChange, setFieldValue, handleBlur, errors }: any) => (
            <Form onSubmit={(evt) => {
                console.log(errors)
                const [e] = Object.keys(errors);
                if (e) {
                    error = e
                } else {
                    error = null
                }
                handleSubmit(evt)}}>
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    <Grid item lg={4}>
                        <TextField
                            style={{ width: '100%', }}
                            id="outlined-basic"
                            label="ტრეინინგის დასახელება"
                            variant="outlined"
                            type="text"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.name} />
                    </Grid>
                    <Grid item lg={4}>
                        <TextField
                            style={{ width: '100%', }}
                            id="outlined-basic"
                            label="სასწავლო დაწესებულება"
                            variant="outlined"
                            type="text"
                            name="company"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.company} />
                    </Grid>
                   <Grid item lg={4} style={{}}>
                        <FormControl variant="outlined" style={{minWidth: '100%'}}>
                            <InputLabel id="select-country">ქვეყანა</InputLabel>
                            <Select
                                name="country"
                                labelId="country-label"
                                id="country"
                                value={item? item.country.id: values.country}
                                onChange={(event, value) => {
                                    const [selected] = countries.filter(option => `${option.id}` === event.target.value)
                                    if (selected) {
                                        setFieldValue("country", selected.id)
                                    }
                                }}
                                label="ქვეყანა">
                                {countries.map((option, index) => {
                                    return <MenuItem value={option.id} key={index} style={{ color: option.id === 'empty' ? 'darkred' : 'black' }}>
                                        <em>{option.name}</em>
                                    </MenuItem>

                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                   
                    
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="name" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="country" >
                            {msg => <span className="error" style={{ marginTop: 10 }}>{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={3}>
                        <TextField
                            style={{ width: '100%', }}
                            id="outlined-basic"
                            label="დასწრების საათები"
                            variant="outlined"
                            type="number"
                            name="hours"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.hours} />
                    </Grid>
                    

                    <Grid item lg={3} style={{ padding: 3, paddingLeft: 20 }}>
                        {!values.isCurrent ?
                            <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
                                <DatePicker
                                    maxDate={new Date()}
                                    minDate={new Date('1950-12-17T03:24:00')}
                                    views={["year"]}
                                    label="დასწრების წელი"
                                    value={values.year}
                                    onChange={(value) => {
                                        setFieldValue("year", value)
                                    }}
                                    animateYearScrolling
                                />
                            </MuiPickersUtilsProvider>: <></>}
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}></Grid>
                    <Grid item lg={12} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="hours" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={12} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="year" >
                            {msg => <span className="error" style={{ marginTop: 10 }}>{msg}</span>}
                        </ErrorMessage>
                    </Grid>

                    
                    <Grid lg={4} sm={1} md={1}></Grid>
                    <Grid container lg={8} sm={11} md={11} style={{padding: 20, marginTop: 20}}>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                            <Button color="secondary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                onClick={() => onClose(false)}>გაქმება</Button>
                        </Grid>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 10 }}>
                            <Button color="primary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                type="submit">გაგზავნა</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Form>
        )}
    </Formik>
    return <div style={{ padding: 20 }}>


        {localValues ? formik : <div></div>}




    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(TrainingForm)