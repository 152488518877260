import { Event } from "../event/event";
import { News } from "../news/news";
import { Resource } from "../resource";

export class Banner extends Resource{
    event:     Event;
    image:     string;
    linkType: string;
    news:      News;
    title:     string;
}