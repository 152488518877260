

import { ResourceProvider } from "../../providers/resource.provider"
import { NameIdItem } from "../common/common"
import { NameIdItemSerializer } from "../common/serializer"
import { Event, EventSubscription } from "./event"
import {EventSerializer, EventSubscriptionSerializer} from './serializer'


export class EventProvider extends ResourceProvider<Event> {
    constructor() {
        super("rest_event", new EventSerializer())
    }
}

export class EventSubscriptionProvider extends ResourceProvider<EventSubscription> {
    constructor() {
        super("rest_event_subscription", new EventSubscriptionSerializer())
    }
}

export class EventSubscriptionStatusProvider extends ResourceProvider<NameIdItem> {
    constructor() {
        super("rest_event_subscription_status", new NameIdItemSerializer())
    }
}

export const eventService = new EventProvider()
export const eventSubscriptionService = new EventSubscriptionProvider()
export const eventSubscriptionStatusService = new EventSubscriptionStatusProvider()
