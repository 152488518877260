

import { ResourceProvider } from "../../providers/resource.provider"
import { VacancySerializer, VacancySubscriptionSerializer } from "./serializer"
import { Vacancy, VacancySubscription } from "./vacancy"



export class VacancyProvider extends ResourceProvider<Vacancy> {
    constructor() {
        super("rest_vacancy", new VacancySerializer())
    }
}

export class VacancySubscriptionProvider extends ResourceProvider<VacancySubscription> {
    constructor() {
        super("rest_vacancy_subscription", new VacancySubscriptionSerializer())
    }
}

export const vacancyService = new VacancyProvider()
export const vacancySubscriptionService = new VacancySubscriptionProvider()

