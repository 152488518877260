import { CitySerializer } from '../city/serializer'
import { countrySerializer } from '../country/serializer'
import { LocationSerializer } from '../location/serializer'
import { Serializer } from '../serializer'
import { EmbeddedAddress } from '../user/user'
import { Address } from './address'

export class AddressSerializer implements Serializer {
    fromJson(json: any) {
        const address = new Address()
        const locationSerializer = new LocationSerializer()
        address.city = json.city
        address.country = countrySerializer.fromJson(json.country)
        address.location = locationSerializer.fromJson(json.location)
        address.postalCode = json.postal_code
        address.street = json.street
        address.street2 = json.street2
        return address
    }

    toJson(item: any) {
        const obj: any = {}
        return obj
    }
}
export class SimpleAddressSerializer implements Serializer {
    fromJson(json: any) {
        const address = new EmbeddedAddress()
        address.city = json.city
        address.postalCode = json.postal_code
        address.street = json.street
        address.street2 = json.street2
        return address
    }

    toJson(item: any) {
        const obj: any = {}
        return obj
    }
}