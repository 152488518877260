import { NameIdItem } from "../common/common"
import { Country } from "../country/country"
import { Resource } from "../resource"
import { User } from "../user/user"

export class CV extends Resource {
    educations:  CVEducation[]
    employee:    User
    experiences: CVExperience[]
    languages:   CVLanguage[]
    skills:      CVSkill[]
    trainings:   CVTraining[]
    isFileGenerated : boolean

}
export class FieldOfStudy extends Resource {
    name: string
}
export class SpokenLanguage extends Resource {
    name: string
}
export class Skill extends Resource {
    name: string
}
export class Level extends Resource {
    name: string
}
export class Degree extends Resource {
    name: string
}

export class CVEducation extends Resource {
    country:    Country
    degree:     NameIdItem
    fieldOfStudy: string
    endDate:   string
    grade:      string
    isCurrent: boolean
    school:     string
    startDate: string
}

export class CVExperience extends Resource {
    comment:          string
    company:          string
    country:          Country
    endDate:         string
    uid:             string
    isCurrent:       boolean
    responsibilities: string[]
    startDate:       string
    title:            string
}

export class CVLanguage  extends Resource {
    ifOther: string
    language: NameIdItem
    level:    NameIdItem
}


export class CVSkill extends Resource {
    level: NameIdItem
    skill: NameIdItem
}

export class CVTraining extends Resource {
    country: Country
    hours:   number
    company: string
    name:    string
    year:    string
}
