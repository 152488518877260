import { Serializer } from '../serializer'
import { CV, CVEducation, CVExperience, CVLanguage, CVSkill, CVTraining } from './cv'
import { nameIdItemSerializer } from '../../models/common/serializer'
import { Resource } from '../resource'
import { countrySerializer } from '../country/serializer'
import { employeeSerializer } from '../user/serializer'


export class CVSerializer implements Serializer {
    fromJson(json: any) {
        const cv = new CV()
        const educationSerializer = new CVEducationSerializer()
        const expSerializer = new CVExperienceSerializer()
        const languageSerializer = new CVLanguageSerializer()
        const skillSerializer = new CVSkillsSerializer()
        const trainingSerializer = new CVTraniningSerializer()
        cv.isFileGenerated = json.is_cv_generated
        if (json.educations) {
            cv.educations = json.educations.map(i => educationSerializer.fromJson(i))
        }
        if (json.experiences) {
            cv.experiences = json.experiences.map(i => expSerializer.fromJson(i))
        }
        if (json.languages) {
            cv.languages = json.languages.map(i => languageSerializer.fromJson(i))
        }
        if (json.skills) {
            cv.skills = json.skills.map(i => skillSerializer.fromJson(i))
        }
        if (json.trainings) {
            cv.trainings = json.trainings.map(i => trainingSerializer.fromJson(i))
        }
        if (json.employee) {
            cv.employee = employeeSerializer.fromJson(json.employee)
        }
        if (json.id) {
            cv.id = json.id
        }
        return cv
    }

    toJson(item: CV) {
        const obj: any = {}
        if (item.experiences) {
            const serializer = new CVExperienceSerializer()
            const [experience] = item.experiences
            if (experience) {
                obj.experiences = serializer.toJson(experience)
            }
        }
        if (item.educations) {
            const serializer = new CVEducationSerializer()
            const [education] = item.educations
            if (education) {
                obj.educations = serializer.toJson(education)
            }
        }
        if (item.trainings) {
            const serializer = new CVTraniningSerializer()
            const [training] = item.trainings
            if (training) {
                obj.trainings = serializer.toJson(training)
            }
        }
        if (item.skills) {
            const serializer = new CVSkillsSerializer()
            const [skill] = item.skills
            if (skill) {
                obj.skills = serializer.toJson(skill)
            }
        }
        if (item.languages) {
            const serializer = new CVLanguageSerializer()
            const [language] = item.languages
            if (language) {
                obj.languages = serializer.toJson(language)
            }
        }
        return obj
    }
}

export class CVEducationSerializer implements Serializer {
    fromJson(json: any) {
        const cve = new CVEducation()
        cve.id = json.id
        cve.country = countrySerializer.fromJson(json.country)
        cve.degree = nameIdItemSerializer.fromJson(json.degree)
        cve.fieldOfStudy = json.field_of_study
        cve.endDate = json.end_date
        cve.grade = json.grade
        cve.isCurrent = json.is_current
        cve.school = json.school
        cve.startDate = json.start_date
        return cve
    }

    toJson(item: CVEducation) {
        return {
            school: item.school,
            country: item.country.id,
            degree: item.degree.id,
            field_of_study: item.fieldOfStudy,
            start_date: item.startDate,
            grade: item.grade,
            end_date: item.endDate,
            is_current: item.isCurrent,
            uid: item.id
        }
    }
}

export class CVExperienceSerializer extends Resource {
    
    fromJson(json: any) {
        const exp = new CVExperience()
        exp.id = json.id
        exp.uid = json.id
        exp.comment = json.comment
        exp.company = json.company
        exp.country = countrySerializer.fromJson(json.country)
        exp.endDate = json.end_date
        exp.isCurrent = json.is_current
        exp.responsibilities = json.responsibilities
        exp.startDate = json.start_date
        exp.title = json.title
        return exp
    }

    toJson(item: CVExperience) {
        return {
            responsibilities: item.responsibilities,
            country: item.country.id,
            title: item.title,
            start_date: item.startDate,
            company: item.company,
            end_date: item.endDate,
            is_current: item.isCurrent,
            uid: item.id
        }
    }
}
export class CVLanguageSerializer extends Resource {
    fromJson(json: any) {
        const lang = new CVLanguage()
        lang.id = json.id
        lang.ifOther = json.if_other
        lang.language = nameIdItemSerializer.fromJson(json.language)
        lang.level = nameIdItemSerializer.fromJson(json.level)
        return lang
    }
    toJson(item: CVLanguage) {
        return {
            uid: item.id,
            language: item.language.id,
            if_other: item.ifOther,
            level: item.level.id
        }
    }
}
export class CVSkillsSerializer extends Resource {
    fromJson(json: any) {
        const skill = new CVSkill()
        skill.id = json.id
        skill.level = nameIdItemSerializer.fromJson(json.level)
        skill.skill = nameIdItemSerializer.fromJson(json.skill)
        return skill
    }
    toJson(item: CVSkill) {
        return {
            uid: item.id,
            level: item.level.id,
            skill: item.skill.id
        }
    }
}
export class CVTraniningSerializer extends Resource {
    fromJson(json: any) {
        const tr = new CVTraining()
        tr.id = json.id
        tr.country = countrySerializer.fromJson(json.country)
        tr.hours = json.hours
        tr.name = json.name
        tr.company = json.company
        tr.year = json.year
        return tr
    }
    toJson(item: CVTraining) {
        return {
            uid: item.id,
            hours: item.hours,
            company: item.company,
            country: item.country.id,
            name: item.name,
            year: item.year
        }
    }
}