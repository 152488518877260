import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import { ErrorMessage, Form, Formik } from "formik"
import { connect } from 'react-redux'
import React, { useEffect, useState } from "react"
import { validationSchemas } from "../../helpers/validations"
import { QueryOptions } from "../../helpers/query.options"
import { setSnackBar } from '../../models/application/applicationActions'
import { CV, CVSkill, Level, Skill } from "../../models/cv/cv";
import { cvService, levelService, skillService } from "../../models/cv/service";



const SkillForm = (props) => {
    const { onClose, item, existing, setSnackBar } = props
    const [levels, setLevels] = useState<Array<Level>>([])
    const [skills, setSkills] = useState<Array<Skill>>([])
    const [mode, setMode] = useState('edit')
    const [localValues, setLocalValues] = useState(null as any)
    let error: string = null
        
    const getValues = (values) => {
        const skill = new CVSkill()
        skill.skill = {id: values.skill, name: ''}
        skill.level = {id: values.level, name: ''}
        skill.id = item ? item.id : null
        
        const cv = new CV()
        cv.skills = [skill]
        
        if (mode === 'create') {
            cvService.create(cv).then(resp => {
                if (resp.id) {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'უნარი წარმატებით დაემატა.', open: true })
                    setTimeout(() => {
                        setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                    }, 5000)
                    onClose()
                }
            })
        } else {
            cvService.updateNoForm(cv).then(resp => {
                if (resp.id) {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'უნარი წარმატებით განახლდა.', open: true })
                    setTimeout(() => {
                        setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                    }, 5000)
                    onClose()
                }
            })
        }
    }

    useEffect(() => {
        
        setLocalValues({ name: '' })
        if (!item) {
            setMode('create')
        }
    }, [item])

    useEffect(() => {
        const options = new QueryOptions()
        options.pageSize = 140
        levelService.list(options).then(e => {
            setLevels(e.results)
        })
        skillService.list(options).then(e => {
            console.log(e.results)
            setSkills(e.results)
        })

    }, [])
    
    const formik = <Formik validationSchema={validationSchemas['skill']}
    initialValues={item ? {
        ...item,
        skill: item?.skill?.id,
        level: item?.level?.id
        }: {}}
        onSubmit={getValues}>
        {({ values, handleSubmit, handleChange, setFieldValue, handleBlur, errors }: any) => (
             <Form onSubmit={(evt) => {
                const [e] = Object.keys(errors);
                if (e) {
                    error = e
                } else {
                    error = null
                }
                handleSubmit(evt)}}>
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    <Grid item lg={6} style={{}}>
                        <FormControl variant="outlined" style={{minWidth: '100%'}}>
                            <InputLabel id="skill-label">უნარი</InputLabel>
                            <Select
                                name="skill"
                                labelId="skill-label"
                                id="skill"
                                value={values.skill}
                                onChange={(event, value) => {
                                    const [selected] = skills.filter(option => `${option.id}` === event.target.value)
                                    if (selected) {
                                        setFieldValue("skill", selected.id)
                                    }
                                }}
                                label="უნარი">
                                {skills.filter(s => {return mode === 'edit' ? true : existing.indexOf(s.id) < 0}).map((option, index) => {
                                    return <MenuItem value={option.id} key={index} style={{ color: option.id === 'empty' ? 'darkred' : 'black' }}>
                                        <em>{option.name}</em>
                                    </MenuItem>

                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} style={{}}></Grid>
                    <Grid item lg={6} style={{}}>
                        <FormControl variant="outlined" style={{minWidth: '100%'}}>
                            <InputLabel id="">დონე</InputLabel>
                            <Select
                                name="level"
                                id="level"
                                value={values.level}
                                onChange={(event, value) => {
                                    const [selected] = levels.filter(option => `${option.id}` === event.target.value)
                                    
                                    if (selected) {
                                        setFieldValue("level", selected.id)
                                    }
                                }}
                                label="დონე">
                                {levels.map((option, index) => {
                                    return <MenuItem value={option.id} key={index} style={{ color: option.id === 'empty' ? 'darkred' : 'black' }}>
                                        <em>{option.name}</em>
                                    </MenuItem>

                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                   
                    
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="spokenLanguage" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="level" >
                            {msg => <span className="error" style={{ marginTop: 10 }}>{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    
                    {error && <Grid container lg={12} sm={11} md={11} >
                        <span style={{ color: 'red' }}>
                            {errors[error]}
                        </span>
                    </Grid>}
                    <Grid lg={4} sm={1} md={1}></Grid>
                    <Grid container lg={8} sm={11} md={11} style={{padding: 20, marginTop: 20}}>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                            <Button color="secondary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                onClick={() => onClose(false)}>გაქმება</Button>
                        </Grid>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 10 }}>
                            <Button color="primary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                type="submit">გაგზავნა</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Form>
        )}
    </Formik>
    return <div style={{ padding: 20 }}>


        {localValues && levels.length && skills.length ? formik : <div></div>}




    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(SkillForm)