import React, { Component, useEffect, useLayoutEffect } from 'react';
import Bar from './bar';
import { connect } from 'react-redux'
import { User } from '../models/user/user';
import { login, logout as LOGOUT } from '../models/user/authActions'
import cookie from 'react-cookies'


const Header = (props) => {
    const { loggedIn, login, LOGOUT } = props?.auth

    useEffect(() => {
        const cookieInterval = setInterval(() => {
            const usr = getCookie()
            if (!usr) {
                if (loggedIn) {
                    props.LOGOUT()
                    const user = new User();
                    user.delete()
                    window.location.href = "/"
                }
            }   
        }, 5000)
        getCookie()
        return () => {
            clearInterval(cookieInterval);
        }
    }, [])
    useEffect(() => {
        const user = new User()
        user.load().then((currentUser) => {
            if (currentUser.accessToken) {
                props.login(currentUser)
            }
        })

    }, [])
    return <header>
        {
            loggedIn && <Bar />
        }
    </header>

}

const getCookie = () => {
    return cookie.load('user')
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app,
        loggedIn: state?.auth?.loggedIn
    }
}

export default connect(mapStateToProps, { login, LOGOUT })(Header)