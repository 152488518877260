import React, {useState} from "react";
import { createStyles, IconButton, makeStyles, Snackbar, Theme } from "@material-ui/core";
import { connect } from 'react-redux'
import LoginForm from "../components/forms/loginForm";
import RegisterFormDialog from "../components/Login/RegisterFormDialog";
import ForgotPasswordFormDialog from "../components/Login/ForgotPasswordFormDialog";
import CloseIcon from '@material-ui/icons/Close';
import { setSnackBar } from '../models/application/applicationActions'
import { State } from '../models/application/applicationReducer'
const LoginPage = (props) => {
  const {app} = props
  const [registerOpen, setRegisterOpen] = React.useState(false)
  const [passwordRestoreOpen, setPasswordRestoreOpen] = React.useState(false)
  const styles = useStyles()
  const [snackbar, setSnackbar] = useState<State>({ open: false, vertical: 'bottom', horizontal: 'center', text: '' })
  const { vertical, horizontal, open, text } = snackbar;
  const handleSnackbarClose = () => {
    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
  };
  React.useEffect(() => {
    setSnackbar(app.snackBar)
  }, [app.snackBar])

  return <div className={styles.container} style={{ height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
    <RegisterFormDialog onOpen={registerOpen} onClose={setRegisterOpen} />
    <ForgotPasswordFormDialog onOpen={passwordRestoreOpen} onClose={setPasswordRestoreOpen} />
    <Snackbar
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => {
            handleSnackbarClose()
          }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleSnackbarClose}
      message={text}
      key={vertical + horizontal}
    />
    <div className={styles.login} style={{ marginTop: 120, marginLeft: 'auto', marginRight: 'auto' }}>
      <br />
      {/* <span
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setRegisterOpen(true)
        }}>რეგისტრაცია</span> */}
      <br />
      <span
        style={{ cursor: 'pointer', marginLeft: 40 }}
        onClick={() => {
          setPasswordRestoreOpen(true)
        }}>პაროლის აღდგენა / ანგარიშის გააქტიურება</span>
    </div>


    <div className={styles.login} style={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}>
      <br />
      <LoginForm {...props} />
    </div>
  </div>
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app
  }
}

export default connect(mapStateToProps, {setSnackBar})(LoginPage)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100vh',
      width: '100%',
      background: '#fafafa',
      textAlign: 'center',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center', textflexDirection: 'column'
    },
    login: {
      flex: 1,
      padding: 20,
      maxWidth: 400,
      display: 'flex',
      alignSelf: 'center',
      flexDirection: 'row'
    }

  })
);
