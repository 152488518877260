import React, { Fragment, useEffect, useState } from 'react';
import "moment/locale/ka"
import moment from "moment"
import SimpleMenu from '../../../components/Menu/SimpleMenu';
import { connect } from 'react-redux'
import { createStyles, Grid, IconButton, makeStyles, Paper, Theme, Chip, TextField } from '@material-ui/core';
import { QueryOptions } from '../../../helpers/query.options';
import { branchService, branchTypeService } from '../../../models/branch/service';
import BranchCard from '../../../components/Branch/BranchCard'
import { Branch } from '../../../models/branch/branch';
import { GoogleMapContainer } from '../../../components/map/GoogleMap';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { NameIdItem } from '../../../models/common/common';
import { branchEmployeeService } from '../../../models/user/service';
import { User } from '../../../models/user/user';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createBrowserHistory } from "history";
import { useLocation } from 'react-router';

moment.locale("ka")

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            height: 28,
            margin: 4,
        },
    }),
);

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const BranchListPage = (props) => {
    let query = useQuery()
    const location = useLocation()
    const classes = useStyles();
    const [search, setSearch] = useState("")
    const [loaded, setLoaded] = useState(false)
    const [branches, setBranchces] = useState<Branch[]>([])
    const [managers, setManagers] = useState<User[]>([])
    const [managerFilterValue, setManagerFilterValue] = useState("")
    const [manager, setManager] = useState<User>()
    const [selectedTags, setSelectedTags] = useState<NameIdItem[]>([])
    const [tags, setTags] = useState<NameIdItem[]>()
    const history = createBrowserHistory()
    const getBranchTypes = () => {
        const options = new QueryOptions()

        branchTypeService.list(options).then(r => {
            setTags(r.results)
        })
    }



    const getBranches = (search, tags, managerId) => {
        const options = new QueryOptions()
        if (search) {
            options.search = search
        }
        if (tags) {
            options.tags = tags
        }
        if (managerId) {
            options.managers = managerId
        }
        options.pageSize = 24
        branchService.listWithCounter(options).then(r => {
            setBranchces(r.current)
        })
    }

    const getManagers = (id: string, search: string) => {
        const options = new QueryOptions();
        options.type = 'list'
        options.managers = true
        if (search) {
            options.search = search
        }
        options.pageSize = 10
        branchEmployeeService.listWithCounter(options).then((employees: any) => {
            if (employees && employees.current) {
                setManagers(employees.current)
                if (id) {
                    const [man] = employees.current.filter(m => m.id === id)
                    setManager(man ? man : null)
                    setLoaded(true)
                }
            }
        })
    }

    useEffect(() => {
        if (selectedTags.length) {
            getBranches("", selectedTags?.map(t => t.id).toString(), null)
        }
    }, [selectedTags])

    useEffect(() => {
        getBranchTypes()
        const managerId = query.get("manager")
        if (managerId) {
            getManagers(managerId, managerId)
        } else {
            getManagers(null, null)
            history.push(`/branches`)
            setManager(null)
            setManagerFilterValue("")
        }
        getBranches("", null, null)

    }, [])

    useEffect(() => {
        if (search.length) {
            getBranches(search, selectedTags?.map(t => t.id).toString(), null)
        }
    }, [search])

    useEffect(() => {
        if (manager) {
            setManagerFilterValue(manager?.fullName)
            getBranches("", null, manager.id)
        } else {

        }
    }, [manager])


    return <Fragment>
        <Grid container
            style={{ textAlign: 'center', justifyContent: 'center' }}
            direction="row"
            lg={12} >
            <h2>
                სტრუქტურული ერთეულები
            </h2>

        </Grid>
        <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 20 }} lg={12}>
            {tags?.length > 0 && <Grid item xs={12} lg={2} spacing={6} style={{ padding: 20 }}>
                <SimpleMenu
                    title="გავფილტროთ"
                    icon={<SearchIcon style={{ fontSize: 30 }} />}
                    items={
                        selectedTags?.length ?
                            tags?.filter(t => selectedTags?.map(st => st.id).indexOf(t.id) < 0)
                                .map(r => { return { id: r.id, fullName: r.name, name: r.name } }) :
                            tags?.map(r => { return { id: r.id, fullName: r.name, name: r.name } })
                    }
                    clicked={(item) => {
                        setSelectedTags([...selectedTags?.filter(st => st.id !== item.id), item])
                    }}
                />
            </Grid>}
            <Grid item xs={12} lg={4} spacing={6} style={{ padding: 20 }}>
                {selectedTags?.map((tag, index) => {
                    return <Chip
                        label={tag.name}
                        onDelete={() => {
                            setSelectedTags([...selectedTags.filter(t => t.id !== tag.id)])
                        }}
                    />
                })}

            </Grid>
            <Grid item xs={12} lg={4} spacing={6} style={{ padding: 20 }}>

                <Autocomplete
                    id="თანამშრომლები"
                    options={managers}
                    getOptionLabel={(option) => option.fullName}
                    inputValue={manager?.id ? manager?.fullName : managerFilterValue}
                    style={{ width: 300 }}
                    onChange={(event, value: User) => {
                        if (value && value.fullName) {

                            setManager(value)
                            history.push(`/branches?manager=${value.id}`)
                            setManagerFilterValue(value.fullName)
                        } else {
                            getManagers(null, null)
                            getBranches("", null, null)
                            history.push(`/branches`)
                            setManager(null)
                            setManagerFilterValue("")
                        }
                    }}
                    onInputChange={(event, newValue) => {
                        setManager(null)
                        console.log(newValue)
                        getManagers(null, newValue)
                        setManagerFilterValue(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} label="გავფილტროთ ხელმღვანელით" variant="outlined" />}
                />


            </Grid>
            <Grid container style={{ marginTop: 10, marginBottom: 20 }} lg={6}>
                <Paper component="form" className={classes.root} style={{ width: '100%' }}>
                    <IconButton className={classes.iconButton} aria-label="menu">
                    </IconButton>
                    <InputBase
                        className={classes.input}
                        placeholder="მოვძებნოთ სტრუქტურული ერთეული"
                        inputProps={{ 'aria-label': 'სტრუქტურული ერთეული' }}
                        onChange={(value) => {
                            setSearch(value.target.value)
                        }}
                    />
                    <IconButton className={classes.iconButton} aria-label="search" onClick={
                        () => {
                            getBranches(search, selectedTags?.map(t => t.id).toString(), null)
                        }
                    }>
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </Grid>
            <Grid lg={4} style={{}} item ></Grid>
            <Grid container style={{ marginTop: 10, marginBottom: 20 }} lg={12}>
                {branches?.map((branch, index) => {
                    return <Grid item lg={2} style={{ padding: 10 }}><BranchCard branch={branch} key={branch.id} /></Grid>
                })}
            </Grid>
            <Grid lg={2} style={{}} item ></Grid>
            <Grid container lg={8}>
                {branches?.length > 0 ?
                    <GoogleMapContainer branches={branches} /> :
                    <b>მაღაზია არ მოიძებნა საძიებო სიტყვაზე: {search}</b>}
            </Grid>
        </Grid>
    </Fragment >
}

const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user
    }
}

export default connect(mapStateToProps)(BranchListPage)