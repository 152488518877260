import { environment } from '../../environment/environment'
import { nameIdItemSerializer } from '../common/serializer'
import { Serializer } from '../serializer'
import { Event, EventSubscription } from './event'


export class EventSerializer implements Serializer {
    fromJson(json: any) {
        const event = new Event()
        event.attendanceCount = json.attendance_count
        event.created = json.created
        event.name = json.name
        event.title = json.name
        event.date = json.date
        event.description = json.description
        event.id = json.id
        if (json.image) {
            event.image = `${environment.basePath}/${environment.imagesPath}/events/${json.image}.jpg` 
        } else  {
            event.image = null
        }
        return event
    }

    toJson(item: any) {
        const obj: any = {}
        return obj
    }
}
export class EventSubscriptionSerializer implements Serializer {
    fromJson(json: any) {
        const es = new EventSubscription()
        if (json.event) {
            const eser = new EventSerializer()
            es.event = eser.fromJson(json.event)
        }
        if (json.status) {
            es.status = nameIdItemSerializer.fromJson(json.status)
        }
        es.created = json.created
        return es
    }

    toJson(item: EventSubscription) {
        
        return {
            status_id: item.status.id,
            event_id: item.event.id
        }
    }
}