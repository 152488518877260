import {
    Button,
    Checkbox,
    createStyles,
    Dialog,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    SnackbarOrigin, TextField, Theme, Typography
} from "@material-ui/core";
import { ErrorMessage, Form, Formik, useField } from "formik"
import { connect } from 'react-redux'
import React, { useEffect, useState } from "react"
import MomentUtils from '@date-io/moment'
import "moment/locale/ka"
import moment from "moment"
import { validationSchemas } from "../../helpers/validations"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { QueryOptions } from "../../helpers/query.options"
import CloseIcon from '@material-ui/icons/Close';
import { setSnackBar } from '../../models/application/applicationActions'
import { BusinessTripRequest, TemporaryTransferRequest } from "../../models/request/request";
import { tranportService, businessTripRequestService } from "../../models/request/service";
import { EmbeddedPosition } from "../../models/user/user";
import { positionService } from "../../models/user/service";
import { branchService } from "../../models/branch/service";
import { Branch } from "../../models/branch/branch";
import { NameIdItem } from "../../models/common/common";

export interface State extends SnackbarOrigin {
    open: boolean;
    text: string;
}



const MyTextArea = ({ label, ...props }) => {
    const [field, meta] = useField(props as any);
    return (
        <>
            <TextField {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </>
    );
};


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        formControl: {
            minWidth: '100%',
        },
        selectEmpty: {
            marginTop: theme.spacing(10),
        },
    }),
);

const BusinessTripRequestForm = (props) => {
    moment.locale("ka")
    const { onClose, item } = props
    const [branches, setBranches] = useState<Branch[]>([])
    const [positions, setPositions] = useState<EmbeddedPosition[]>([])
    const [ownCar, setOwnCar] = useState(false)
    const [transports, setTransports] = useState<NameIdItem[]>([])
    const [localValues, setLocalValues] = useState<any>()
    const [ttr, setTtr] = useState<TemporaryTransferRequest>()
    let error: string = null

    const handleClose = () => {
        onClose({ open: false, item: null, reload: true })
    }


    const getValues = (values) => {
        const noteArray = []
        const r = new BusinessTripRequest()
        r.fromDate = values.fromDate
        r.destination = values.destination
        r.toDate = values.toDate
        r.employee = item?.employee.id
        r.gasolineCost = values.gasolineCost ? values.gasolineCost : 0
        r.accommodationFee = values.accommodationFee ? values.accommodationFee : 0
        r.transport = values.transport
        r.transportationFee = values.transportationFee ? values.transportationFee : 0
        r.reason = values.reason
        businessTripRequestService.create(r).then((resp: any) => {
            if (!resp.status) {
                handleClose()
                props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'ჩანაწერი დაემატა წარმატებით', open: true })
                setTimeout(() => {
                    props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                }, 5000)
            } else {
                let text = 'დაფიქსირდა შეცდომა'
                props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text, open: true })
                setTimeout(() => {
                    props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                }, 5000)
            }
        })

    }

    const getPositions = (search) => {
        const options = new QueryOptions();
        if (search && search.length) {
            options.search = search
        }
        options.pageSize = 100
        options.sort = "name"
        options.order = 'asc'
        positionService.listWithCounter(options).then(r => {
            setPositions(r.current)
        })
    }
    const getBranches = (search) => {
        const options = new QueryOptions();
        if (search && search.length) {
            options.search = search
        }
        options.pageSize = 100
        options.sort = "uid"
        options.order = 'asc'
        branchService.listWithCounter(options).then(r => {
            setBranches(r.current)
        })
    }

    const getTransportTypes = () => {
        const options = new QueryOptions()
        tranportService.list(options).then(r => {
            setTransports(r.results)
        })
    }

    useEffect(() => {
        getPositions(null)
        getBranches(null)
        if (item) {
            setTtr(null)
            setTtr(item)
        }
    }, [item])

    useEffect(() => {
        getTransportTypes()
    }, [localValues])

    useEffect(() => {
        if (item) {
            setLocalValues({
                responsible: item?.responsible?.fullName,
                employee: item?.employee?.fullName,
                fromDate: new Date(),
                toDate: new Date(),
            })
        }
    }, [item])

    const formik = <Formik validationSchema={validationSchemas['businessTripRequest']}
        initialValues={localValues}
        onSubmit={getValues}>
        {({ values, handleSubmit, handleChange, handleBlur, setFieldValue, errors }: any) => (
            <Form onSubmit={(evt) => {
                console.log(errors, values)
                const [e] = Object.keys(errors);
                if (e) {
                    error = e
                } else {
                    error = null
                }
                handleSubmit(evt)
            }}>
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    {console.log(errors)}
                    <Grid item lg={6}>
                        <TextField
                            style={{ width: '100%', }}
                            id="outlined-basic"
                            label={'თანამშრომელი'}
                            variant="outlined"
                            type="text"
                            disabled={true}
                            name="employee"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.employee} />
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="employee" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="responsible" >
                            {msg => <span className="error" style={{ marginTop: 10 }}>{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={12}>
                        <TextField
                            style={{ width: '100%', height: 50 }}
                            id="outlined-basic"
                            label={'მივლინების ადგილი (ქალაქი, ქვეყანა)'}
                            variant="outlined"
                            type="text"
                            name="destination"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.destination} />
                    </Grid>
                    <Grid item lg={12}>
                        <TextField
                            style={{ width: '100%', height: 50 }}
                            id="outlined-basic"
                            label={'მივლინების მიზანი'}
                            variant="outlined"
                            type="text"
                            name="reason"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.reason} />
                    </Grid>


                    <Grid item lg={3} style={{ padding: 3, paddingLeft: 20 }}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                format="DD-MM-yyyy"
                                margin="normal"
                                name="fromDate"
                                clearable
                                id="date-picker-inline"
                                label="დაწყების თარიღი"
                                value={values.fromDate}
                                onChange={(date: any) => {
                                    try {
                                        setFieldValue("fromDate", date._d)
                                        setLocalValues({ ...localValues, fromDate: date._d })
                                    } catch {

                                    }
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item lg={3} style={{ padding: 3, paddingLeft: 20 }}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                format="DD-MM-yyyy"
                                margin="normal"
                                clearable
                                name="toDate"
                                id="date-picker-inline"
                                label="დასრულების თარიღი"
                                value={values.toDate}
                                onChange={(date: any) => {
                                    try {
                                        setFieldValue("toDate", date._d)

                                    } catch {

                                    }
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item lg={4} style={{}}>
                        <FormControl variant="outlined" style={{ minWidth: '100%' }}>
                            <InputLabel id="select-transport">ტრანსპორტი</InputLabel>
                            <Select
                                name="transport"
                                labelId="transport-label"
                                id="transport"
                                value={item ? item.transport?.id : values.transport}
                                onChange={(event, value) => {
                                    const [selected] = transports.filter(option => `${option.id}` === event.target.value)
                                    if (selected) {
                                        if (selected.name === 'საკუთარი მანქანა') {
                                            setOwnCar(true)
                                        } else {
                                            setOwnCar(false)
                                        }
                                        setFieldValue("transport", selected.id)
                                    }
                                }}
                                label="ტრანსპორტი">
                                {transports.map((option, index) => {
                                    return <MenuItem value={option.id} key={index} style={{ color: option.id === 'empty' ? 'darkred' : 'black' }}>
                                        <em>{option.name}</em>
                                    </MenuItem>

                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    {!ownCar && <Grid item lg={4}>
                        <TextField
                            style={{ width: '100%', height: 50 }}
                            id="outlined-basic"
                            label={'მგზავრობის ხარჯი (ლარებში)'}
                            variant="outlined"
                            type="number"
                            name="transportationFee"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="შეივანეთ მგზავრობის ხარჯი (ლარებში)"
                            value={values?.transportationFee} />
                    </Grid>}
                    {ownCar && <Grid item lg={4}>
                        <TextField
                            name="gasolineCost"
                            type="number"
                            style={{ width: '100%', marginTop: 4 }}
                            id="outlined-multiline-static"
                            label="საწვავის ხარჯი (ლარებში)"
                            placeholder="შეივანეთ საწვავის ხარჯი (ლარებში)"
                            rows={3}
                            variant="outlined" />

                    </Grid>}
                    {<Grid item lg={4}>
                        <TextField
                            name="accommodationFee"
                            type="number"
                            style={{ width: '100%', marginTop: 4 }}
                            id="outlined-multiline-static"
                            label="სასტუმროს ხარჯი (ლარებში)"
                            placeholder="შეივანეთ სასტუმროს ხარჯი (ლარებში)"
                            rows={3}
                            variant="outlined" />
                    </Grid>}
                    {error && <Grid item lg={12} sm={11} md={11} >
                        <span style={{ color: 'red' }}>
                            {errors[error]}
                        </span>
                    </Grid>}
                    <Grid lg={12} sm={1} md={1}></Grid>
                    <Grid lg={4} sm={1} md={1}></Grid>
                    <Grid container lg={8} sm={11} md={11} spacing={1} style={{ marginTop: 40 }}>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                            <Button color="secondary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                onClick={() => onClose(false)}>გაქმება</Button>
                        </Grid>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 10 }}>
                            <Button color="primary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                type="submit">გაგზავნა</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        )}
    </Formik>
    return <div>
        <Dialog
            maxWidth='md'
            style={{ padding: 20 }}
            open={props.onOpen} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <div style={{ position: 'absolute', right: 10, top: 10 }}>
                <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        handleClose()
                    }} />
            </div>
            <div style={{ padding: 20 }}>
                <Typography variant="h5" component="div">
                    მივლინების მოთხოვნის ფორმა
                </Typography>
                {localValues ? formik : <div></div>}
            </div>
        </Dialog>
    </div>
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(BusinessTripRequestForm)