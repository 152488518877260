import { Grid } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from 'react';
import Button from "@material-ui/core/Button";
import "moment/locale/ka"
import moment from "moment"
import { connect } from 'react-redux'
import DatePickerRange from '../components/Date/DatePickerRange'
import { vacationWorkingDaysService } from "../models/WorkingDayHour/service";
import { VacationWorkingDayHours, WorkingDayHours } from "../models/WorkingDayHour/wokringDay";
import VacationRequestTableList from "./Table/VacationRequestTableList";


moment.locale("ka")

const VacationComponent = (props) => {
    const { id } = props
    const [usedDays, setUsedDays] = useState(0)
    const [leftDays, setLeftDays] = useState(0)
    const [holidays, setHolydays] = useState(0)
    const [totalUsedDays, setTotalUsedDays] = useState<any[]>([])
    const [totalDays, setTotalDays] = useState(0)
    const [days, setDays] = useState<any[]>([])
    const [taken, setTaken] = useState<WorkingDayHours[]>([])
    const [counters, setCounters] = useState<VacationWorkingDayHours[]>([])

    useEffect(() => {
        loadVacations()
    }, [])
    useEffect(() => {
        if (totalUsedDays?.length) {
            setLeftDays(totalDays - totalUsedDays?.length)
        } else {
            setLeftDays(totalDays)
        }
    }, [usedDays, totalDays, totalUsedDays])

    useEffect(() => {
        
        if (days?.length) {
            let used = 0
            for (var i = 0; i < days.length; i++) {
                if (days[i].id === 'paid_vacation' || days[i].id === 'vacation') {
                    used = used + days[i].values.length    
                }
                if (days[i].id === 'holiday') {
                    setHolydays(days[i].values.length)
                }
            }
            setUsedDays(used)
        }

    }, [days])

    const loadVacations = () => {
        vacationWorkingDaysService.read(id).then((r: any) => {
            var arr = []
            setTotalUsedDays(r.daysUsed)
            const [vacation] = r.counters.filter(r => r.id === 'vacation')
            const [paidVacation] = r.counters.filter(r => r.id === 'paid_vacation')
            if (paidVacation) {
                for (var i = 0; i < paidVacation.values.length; i++) {
                    arr.push(paidVacation.values[i])
                }
            }
            if (vacation) {
                for (var i = 0; i < vacation.values.length; i++) {
                    arr.push(vacation.values[i])
                }
            }
            setTotalDays(r.totalVacationDays)
            setTaken(r.daysUsed)
            setDays(r.counters)
        })
    }

    return <Fragment>
        <Grid container spacing={0} lg={12} style={{}}>
            <Grid item lg={12} style={{ alignItems: 'center', textAlign: 'center' }}>
                <DatePickerRange taken={taken} />
            </Grid>
            <Grid container spacing={0} lg={12} style={{ paddingTop: 30 }} >
                <Grid item lg={3} >
                    <Button color="primary" variant="outlined" onClick={() => { }}>
                        შვებულების დღეები {totalDays}
                    </Button>
                </Grid>
                <Grid item lg={3}>
                    <Button color="primary" variant="outlined" onClick={() => { }}>
                        გამოყენებული დღეები {totalUsedDays?.length}
                    </Button>
                </Grid>
                <Grid item lg={3}>
                    <Button color="primary" variant="outlined" onClick={() => { }}>
                        დარჩენილი შვებულების დღეები {leftDays}
                    </Button>
                </Grid>
                <Grid item lg={3}>
                    <Button color="primary" variant="outlined" onClick={() => { }}>
                        დასვენების დღეებში ნამუშევარი {holidays}
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={0} lg={12} style={{ paddingTop: 30 }} >
                <VacationRequestTableList id={id}/>
            </Grid>
        </Grid>
    </Fragment >
}

const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user
    }
}

export default connect(mapStateToProps)(VacationComponent)