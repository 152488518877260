import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import "moment/locale/ka"
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import moment from "moment"
import { connect } from 'react-redux'
import { NameIdItem } from '../../models/common/common'
import { TableHead, Typography, Chip, Grid } from '@material-ui/core'
import { setSnackBar } from '../../models/application/applicationActions'
import { environment } from '../../environment/environment'
import { ConfirmationDialog } from '../Dialogs/ConfirmationDialog'
import { EmployeeFileShare } from '../../models/document/document'
import { employeeFileShareService, employeeFileTagsProvider } from "../../models/document/service"
import { QueryOptions } from '../../helpers/query.options'
import DeleteIcon from '@material-ui/icons/Delete'
import { TablePaginationActions } from '../Common/Table/Pagination'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import SimpleMenu from '../Menu/SimpleMenu'
import SearchIcon from '@material-ui/icons/Search';

const BASE_URL = environment.basePath


const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});



moment.locale("ka")

const MySharedDocumentsTableComponent = (props) => {
    const { setSnackBar } = props
    const classes = useStyles2();
    const [items, setItems] = useState<EmployeeFileShare[]>([]);
    const [tags, setTags] = useState<NameIdItem[]>()
    const [selectedTags, setSelectedTags] = useState<NameIdItem[]>([])
    const [confirmation, setConfirmation] = useState({ open: false, text: '', callback: null })
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    let emptyRows

    const getData = (tags: string) => {
        const options = new QueryOptions()
        options.list = true
        if (tags) {
            options.tags = tags
        }
        employeeFileShareService.listWithCounter(options).then(r => {
            if (!r.status) {
                setItems(r.current)
            }
        })
    }

    useEffect(() => {
        const options = new QueryOptions()
        employeeFileTagsProvider.list(options).then(r => {
            if (!r.status) {
                setTags(r.results)
            }
        })
        getData(null)
    }, [])

    useEffect(() => {
        getData(selectedTags?.map(t => t.id).toString())
    }, [selectedTags])

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    useEffect(() => {
        if (items.length) {
            emptyRows = rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);
        }
    }, [items])

    const tableContent = (<TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
                <TableRow>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        თანამშრომელი
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        სახელი
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: 'bold' }}>
                        ატვირთვის თარიღი
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: 'bold' }}>
                        გაზიარების თარიღი
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: 'bold' }}>
                        ფორმატი
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        თაგები
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>დეტალები</TableCell>
                </TableRow>
            </TableHead>
            {items?.length > 0 &&
                <TableBody>
                    {
                        (rowsPerPage > 0
                            ? items?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : items
                        ).map((item: EmployeeFileShare) => (
                            <TableRow key={item.id}>
                                <TableCell align="center">
                                    {item.employee?.fullName}
                                </TableCell>
                                <TableCell align="center">
                                    {item.file.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.file.created}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.created}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.file.ext}
                                </TableCell>
                                <TableCell align="center">
                                    {item?.file.tags?.map((t, index) => {
                                        return <Chip label={t.name} />
                                    })}
                                </TableCell>

                                <TableCell align="center">
                                    <DeleteIcon style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setConfirmation({
                                                text: 'ნამდვილად გსურთ ჩანაწერის წაშლა?', open: true, callback: (value) => {
                                                    if (value) {
                                                        employeeFileShareService.delete(item.id).then(r => {
                                                            if (!r.status) {
                                                                getData(null)
                                                                setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'ჩანაწერი წაიშალა წარმატებით', open: true })
                                                                setTimeout(() => {
                                                                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'დოკუმენტი გაზიარებულია', open: false })
                                                                }, 4000)
                                                            }
                                                        })
                                                    }
                                                    setConfirmation({ text: '', callback: null, open: false })
                                                }
                                            })
                                        }} />
                                    <CloudDownloadIcon
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            window.open(`${BASE_URL}/assets/files/documents/${item.file.ext}/${item.file.filename}.${item.file.ext}`, '_blank');
                                        }}
                                    />
                                </TableCell>

                            </TableRow>
                        ))}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>}
            {items?.length > 10 && <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={items.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />

                </TableRow>
            </TableFooter>}
        </Table>
    </TableContainer>)

    return <Fragment>
         <Grid item xs={12} lg={12} spacing={6} style={{ padding: 20 }}>
            <SimpleMenu
                title="გავფილტროთ" 
                icon={<SearchIcon style={{ fontSize: 30 }} />} 
                items={
                    selectedTags?.length ?
                    tags?.filter(t => selectedTags?.map(st =>st.id).indexOf(t.id) < 0)
                    .map(r => { return { id: r.id, fullName: r.name, name: r.name } }):
                    tags?.map(r => { return { id: r.id, fullName: r.name, name: r.name } })
                } 
                clicked={(item) => {
                    setSelectedTags([...selectedTags?.filter(st => st.id !== item.id), item])
                }} 
            />
        </Grid>
        <Grid item xs={12} lg={12} spacing={6} style={{ padding: 20 }}>
            {selectedTags?.map((tag, index) => {
                return <Chip
                    label={tag.name}
                    onDelete={() => {
                        setSelectedTags([...selectedTags.filter(t => t.id !== tag.id)])
                    }}
                />
            })}

        </Grid>
        <ConfirmationDialog
            title="ჩანაწერის წაშლა"
            open={confirmation.open}
            text={confirmation.text} callback={confirmation.callback} />

        {items.length > 0 ? <div>{tableContent}</div> : <div style={{ marginTop: 20 }}>
            <Typography variant="h6">
                ჩანაწერები არ მოიძებნა
            </Typography>

        </div>}


    </Fragment >
}

const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user
    }
}

export default connect(mapStateToProps, { setSnackBar })(MySharedDocumentsTableComponent)