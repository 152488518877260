import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Form, Formik, ErrorMessage } from "formik";
import { connect } from 'react-redux'
import { validationSchemas } from "../../helpers/validations";
import '../../components/styles.css'
import { authenticate } from '../../models/user/authActions'
import { User } from "../../models/user/user";
import { userRegistrationService } from "../../models/user/service";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';


const RegisterFormDialog = (props: any) => {
    let inputElement
    const { onOpen, onClose, register } = props
    const [image, setImage] = useState(null)
    const [imageError, setImageError] = useState(false)
    const [imageSizeError, setImageSizeError] = useState(false)
    const [file, setFile] = useState(null)
    const [phoneError, setPhoneError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [pidError, setPidError] = useState(false)
    const [open, setOpen] = useState(false as any)
    const handleCloseDialog = () => { onClose(false) }
    const handleClose = () => {
        onClose(false)
    }
    useEffect(() => {
        setImage(null)
        setFile(null)
        setOpen(onOpen)
    }, [onOpen])

    const getValues = (values) => {

        const user = new User()
        user.email = values.email.toLowerCase()
        user.password = values.password
        user.firstName = values.firstName
        user.lastName = values.lastName
        if (file) {
            user.image = file
        }
        user.personalId = values.personalId
        user.phone = values.phone
        userRegistrationService.createWithFile(user).then((resp: any) => {
            if (resp?.email) {
                onClose(false)
            } else {
                if (resp.status === 410) {
                    setPhoneError(true)
                }
                if (resp.status === 409) {
                    setEmailError(true)
                }
                if (resp.status === 408) {
                    setPidError(true)
                }
            }
        });
    }

    const onDrop = (event) => {
        setImageError(false)
        const [file] = event.target.files
        if (file) {
            if (file.type === 'image/jpeg') {
                if (file.size < 300000) {
                    setImage(URL.createObjectURL(file))
                    setFile(file)
                } else {
                    setImageSizeError(true)
                }
            } else {

                setImageError(true)

            }
        }
    }

    return (
        <Dialog open={props.onOpen} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <DialogTitle id="form-dialog-title">გაიარეთ რეგისტრაცია</DialogTitle>
            <DialogContent className="dialog">
                <Formik
                    validationSchema={validationSchemas['registration']}
                    initialValues={{ email: '', phone: '', firstName: '', lastName: '', password: '', confirmpassword: '', personalId: '', image: null }}
                    onSubmit={getValues}>
                    {({ values, handleSubmit, errors, touched, validateForm, handleChange, handleBlur }: any) => (
                        <Form onSubmit={(event) => {
                            handleSubmit(event)
                        }}>
                            <Grid container >
                                <Grid item lg={12} spacing={3}>
                                    <div style={{
                                        position: 'relative',
                                        width: image ? 90 : 200
                                    }}>
                                        {!image && <div onClick={() => {
                                            inputElement.click()
                                        }}>
                                            <AccountBoxIcon fontSize="large" />
                                            <Button size="small"
                                                style={{
                                                    position: 'relative',
                                                    top: -13
                                                }}
                                                color="primary" >ატვირთე სურათი</Button>
                                        </div>}
                                        {image && <div style={{ width: '100%' }}>
                                            <div
                                                onClick={
                                                    () => {
                                                        inputElement.click()
                                                    }
                                                }
                                                style={
                                                    {
                                                        position: 'absolute',
                                                        top: -5,
                                                        left: -10,
                                                        width: 35,
                                                        height: 35,
                                                        borderRadius: 45,
                                                        borderWidth: 1,
                                                        borderColor: 'black',
                                                        backgroundColor: '#dadada'
                                                    }}>
                                                <div style={{ position: 'relative' }}>
                                                    <EditOutlinedIcon style={{ position: 'absolute', top: 3, left: 6 }} />

                                                </div>

                                            </div>
                                            <img src={image} style={{ width: '100%' }} />
                                            <br></br>

                                        </div>}
                                        {imageError && <span className="error">
                                            მისაღებია მხოლოდ JPEG ფორმატის სურათები</span>}
                                        {imageSizeError && <span className="error">
                                            სურათის ზომა არის 300კბ-ზე მეტი</span>}
                                    </div>
                                </Grid>
                                <Grid item lg={6} spacing={3}>
                                    <TextField
                                        style={{ width: '90%', marginTop: 10 }}
                                        id="outlined-basic"
                                        label={'სახელი'}
                                        variant="outlined"
                                        type="text"
                                        name="firstName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstName}
                                    />
                                    <ErrorMessage name="firstName" >
                                        {msg => <span className="error">{msg}</span>}
                                    </ErrorMessage>
                                </Grid>
                                <Grid item lg={6} spacing={3}>
                                    <TextField
                                        style={{ width: '90%', marginTop: 10 }}
                                        id="outlined-basic"
                                        label={'გვარი'}
                                        variant="outlined"
                                        type="text"
                                        name="lastName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.lastName}
                                    />
                                    <ErrorMessage name="lastName" >
                                        {msg => <span className="error">{msg}</span>}
                                    </ErrorMessage>
                                </Grid>
                                <Grid item lg={6}>
                                    <TextField
                                        style={{ width: '90%', marginTop: 10 }}
                                        id="outlined-basic"
                                        label={'ელ.ფოსტა'}
                                        variant="outlined"
                                        type="text"
                                        name="email"
                                        onChange={(event) => {
                                            setEmailError(false)
                                            handleChange(event)
                                        }}
                                        onBlur={handleBlur}
                                        value={values.email}
                                    />
                                    <br></br>

                                    <ErrorMessage name="email" >
                                        {msg => <span className="error">{msg}</span>}
                                    </ErrorMessage>
                                    {emailError && <span className="error">
                                        ელ. ფოსტა უკვე დარეგისტრირებულია.</span>}
                                </Grid>
                                <Grid item lg={6} spacing={3}>
                                    <TextField
                                        style={{ width: '90%', marginTop: 10 }}
                                        id="outlined-basic"
                                        label={'ტელეფონი'}
                                        variant="outlined"
                                        type="text"
                                        name="phone"
                                        onChange={(event) => {
                                            setPhoneError(false)
                                            handleChange(event)
                                        }}
                                        onBlur={handleBlur}
                                        value={values.phone}
                                    />
                                    <ErrorMessage name="phone" >
                                        {msg => <span className="error">{msg}</span>}
                                    </ErrorMessage>
                                    {phoneError && <span className="error">
                                        ტელეფონის ნომერი უკვე დარეგისტრირებულია.
                                    </span>}
                                </Grid>
                                <Grid item lg={6} spacing={3}>
                                    <TextField
                                        style={{ width: '90%', marginTop: 10 }}
                                        id="outlined-basic"
                                        label={'პირადი ნომერი'}
                                        variant="outlined"
                                        type="text"
                                        name="personalId"
                                        onChange={(event) => {
                                            setPidError(false)
                                            handleChange(event)
                                        }}
                                        onBlur={handleBlur}
                                        value={values.personalId}
                                    />
                                    <ErrorMessage name="personalId" >
                                        {msg => <span className="error">{msg}</span>}
                                    </ErrorMessage>

                                    {pidError && <span className="error">
                                        პირადი ნომერი უკვე დარეგისტრირებულია.
                                    </span>}

                                </Grid>
                                <Grid item lg={6}>
                                    <div style={{ padding: 20, paddingLeft: 5 }}>


                                    </div>


                                </Grid>
                                <Grid item lg={6} spacing={3} className="text-field">
                                    <TextField
                                        style={{ width: '90%', marginTop: 10 }}
                                        id="outlined-basic"
                                        label={'პაროლი'}
                                        variant="outlined"
                                        type="password"
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                    />
                                    <ErrorMessage name="password" >
                                        {msg => <span className="error">{msg}</span>}
                                    </ErrorMessage>
                                </Grid>
                                <Grid item lg={6} spacing={3}>
                                    <TextField
                                        style={{ width: '90%', marginTop: 10 }}
                                        id="outlined-basic"
                                        label={'პაროლის დადასტურება'}
                                        variant="outlined"
                                        type="password"
                                        name="confirmpassword"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.confirmpassword}
                                    />
                                    <ErrorMessage name="confirmpassword" >
                                        {msg => <span className="error">{msg}</span>}
                                    </ErrorMessage>
                                </Grid>
                            </Grid>
                            <DialogActions>
                                <input
                                    style={{ visibility: 'hidden' }}
                                    ref={input => inputElement = input}
                                    type="file" id="file" onChange={onDrop} name="image" />
                                <Button onClick={handleCloseDialog} color="primary">
                                    გაუქმება
                                </Button>
                                <Button color="primary"
                                    type="submit">
                                    რეგისტრაცია
                                </Button>
                            </DialogActions>
                        </Form>


                    )}
                </Formik>
            </DialogContent>

        </Dialog>)
}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { authenticate })(RegisterFormDialog)