import { isEmpty } from 'lodash';
import * as Yup from "yup";
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^\d{9}$/;

export const required = ({ value, message }: any) => {
    if (typeof value === 'number') {
        return value === 0 || value ? undefined : message ? message : 'Required field';
    }
    if (typeof value)
        return !isEmpty(value) ? undefined : message ? message : 'Required field';
};


export const email = ({ value, message }: any) => {
    return emailRegex.test(value?.toLowerCase()) ? undefined : message ? message : 'Required field';

};

export const phone = ({ value, message }: any) => {
    return phoneRegex.test(value) ? undefined : message ? message : 'Invalid format';

};

export const maxLength = ({ value, message, length }: any) => {
    if (value && value.length > length) {
        return message ? message : 'Required field';
    }
}
export const minLength = ({ value, message, length }: any) => {
    if (value && value.length < length) {
        return message ? message : 'Required field';
    }
}

export const emailAlreadyExists = ({ value, message, args, showAfter }: any) => {
    if (args) {
        return message ? message : 'Required field';
    }
}

export const usernameAlreadyExists = ({ value, message, args, showAfter }: any) => {
    if (args === true) {
        return message ? message : 'Required field';
    }
}

export const Schema = Yup.object().shape({
    email: Yup.string().required("სალადებულო ველია").email("ელ.ფოსტა არასწორი ფორმატია."),
    phone: Yup.string().required("სალადებულო ველია").min(9, 'ტელეფონი უნდა იყოს 9 ნიშნა').max(9, 'ტელეფონი უნდა იყოს 9 ნიშნა')
});
/* password: Yup.string().required("field_is_required"),
confirmpassword: Yup.string().when("password", {
  is: (val: any) => (val && val.length > 0 ? true : false),
  then: Yup.string().oneOf(
    [Yup.ref("password")],
    "confirmpassword_incorrect"
  )
}) */

export const validationSchemas = {
    "uploadDocument": Yup.object().shape({
        name: Yup.string().required("დასახელება სალალდებულო ველია.").min(3, 'მისაღებია უმოკლესი 3 ნიშნა სახელი')
    }),
    "documentRequest": Yup.object().shape({
        text: Yup.string().required("ტექსტი სალალდებულო ველია.").min(3, 'მისაღებია უმოკლესი 3 ნიშნა სახელი')
    }),
    "shareDocument": Yup.object().shape({
        employees: Yup.string().required("თანამშრომლები სალალდებულო ველია."),
        file: Yup.string().required("დოკუმენტი სალალდებულო ველია."),
    }),
    "registration": Yup.object().shape({
        email: Yup.string().required("ელ.ფოსტა სალალდებულო ველია.").email("ელ.ფოსტა არასწორი ფორმატია."),
        firstName: Yup.string().required("სახელი სალალდებულო ველია.").min(2, 'მისაღებია უმოკლესი 2 ნიშნა სახელი'),
        lastName: Yup.string().required("გვარი სალალდებულო ველია.").min(2, 'მისაღებია უმოკლესი 2 ნიშნა გვარი'),
        personalId: Yup.string().required("პორადი ნომერი სალალდებულო ველია.").min(11, 'პირადი ნომერი უნდა იყოს 11 ნიშნა').max(11, 'პირადო ნომერი უნდა იყოს 11 ნიშნა'),
        phone: Yup.string().required("ტელეფონი სალადებულო ველია.").min(9, 'ტელეფონი უნდა იყოს 9 ნიშნა.').max(9, 'ტელეფონი უნდა იყოს 9 ნიშნა.'),
        password: Yup.string().required("პაროლი სავალდებულო ველია."),
        confirmpassword: Yup.string().required("პაროლის დადასტურება სავალდებულო ველია.").when("password", {
            is: (val: any) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                "პაროლები არ ემთხვევა."
            )
        })
    }),
    "exportReport": Yup.object().shape({
        month: Yup.string().required("თვე სალალდებულო ველია."),
    }),
    "restorePassword": Yup.object().shape({
        personalId: Yup.string().required("პირადი ნომერი სავალდებულო ველია"),
        token: Yup.string().required("კოდი სალალდებულო ველია.").min(2, 'მისაღებია უმოკლესი 2 ნიშნა სახელი'),
        password: Yup.string().required("პაროლი სავალდებულო ველია."),
        confirmpassword: Yup.string().required("პაროლის დადასტურება სავალდებულო ველია.").when("password", {
            is: (val: any) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                "პაროლები არ ემთხვევა."
            )
        })
    }),
    "forgotPassword": Yup.object().shape({
        personalId: Yup.string().required("პირადი ნომერი სავალდებულო ველია")
        
    }),
    "profile": Yup.object().shape({
        email: Yup.string().required("ელ.ფოსტა სალალდებულო ველია.").email("ელ.ფოსტა არასწორი ფორმატია."),
        
    }),

    "dismissRequest": Yup.object().shape({
        responsible: Yup.string().required("პასუხისმგებელი სალალდებულო ველია."),
        employee: Yup.string().required("თანამშრომელი სალალდებულო ველია."),
        dueDate: Yup.string().required("აღსრულების თარიღი სალალდებულო ველია."),
        reason: Yup.string().required("მიზეზი სალალდებულო ველია.")
    }),
    "experience": Yup.object().shape({
        title: Yup.string().required("პოსტი სალალდებულო ველია."),
        company: Yup.string().required("კომპანია სალალდებულო ველია."),
        country: Yup.string().required("ქვეყანა სალალდებულო ველია."),
        startDate: Yup.string().required("დაწყების თარიღი სალალდებულო ველია."),
        isCurrent: Yup.boolean().required("მიმდინარეა სალალდებულო ველია.")
    }),
    "education": Yup.object().shape({
        school: Yup.string().required("სასწავლო დაწესებულება სალალდებულო ველია."),
        fieldOfStudy: Yup.string().required("განხრა/ფაკულტეტი სალალდებულო ველია."),
        country: Yup.string().required("ქვეყანა სალალდებულო ველია."),
        degree: Yup.string().required("წოდება სალალდებულო ველია."),
        startDate: Yup.string().required("დაწყების თარიღი სალალდებულო ველია."),
        grade: Yup.number().required("საშუალო ქულა სალალდებულო ველია."),
        isCurrent: Yup.boolean().required("მიმდინარეა სალალდებულო ველია.")
    }),
    "training": Yup.object().shape({
        name: Yup.string().required("სახელი სალალდებულო ველია."),
        country: Yup.string().required("ქვეყანა სალალდებულო ველია."),
        year: Yup.string().required("წელი სალალდებულო ველია."),
        company: Yup.string().required("სასწავლო დაწესებულება სალალდებულო ველია."),
        hours: Yup.number().required("საათების რაოდენობა სალალდებულო ველია.")
    }),
    "vacationRequest": Yup.object().shape({
        fromDate: Yup.string().required("დაწყების თარიღი სალალდებულო ველია."),
        toDate: Yup.string().required("დასრულების თარიღი სალალდებულო ველია."),
        type: Yup.string().required("შვებულების სახეობა სალალდებულო ველია.")
    }),
    "spokenlLanguage": Yup.object().shape({
        language: Yup.string().required("სალაპარაკო ენა სალალდებულო ველია."),
        level: Yup.string().required("ცოდნის დონე სალალდებულო ველია."),
    }),
    "skill": Yup.object().shape({
        skill: Yup.string().required("უნარი სალალდებულო ველია."),
        level: Yup.string().required("დონე სალალდებულო ველია."),
    }),
    "temporaryTransferRequest": Yup.object().shape({
        responsible: Yup.string().required("პასუხისმგებელი სალალდებულო ველია."),
        employee: Yup.string().required("თანამშრომელი სალალდებულო ველია."),
        fromDate: Yup.string().required("დაწყების თარიღი სალალდებულო ველია."),
        toPosition: Yup.string().required("ახალი პოზიცია სალალდებულო ველია."),
        toBranch: Yup.string().required("ახალი პოზიცია სალალდებულო ველია."),
        salary: Yup.string().required("შრომის ანაზღაურება სავალდებულო ველია"),
    }),
    "transferRequest": Yup.object().shape({
        employee: Yup.string().required("თანამშრომელი სალალდებულო ველია."),
        dueDate: Yup.string().required("დაწყების თარიღი სალალდებულო ველია."),
        toPosition: Yup.string().required("ახალი პოზიცია სალალდებულო ველია."),
        toBranch: Yup.string().required("ახალი პოზიცია სალალდებულო ველია."),
        reason: Yup.string().required("პოზიციის ცვლილების საფუძველი სალალდებულო ველია."),
        salary: Yup.number().required("შრომის ანაზღაურება ახალ პოზიციაზე სალალდებულო ველია.")
    }),
    "businessTripRequest": Yup.object().shape({
        responsible: Yup.string().required("პასუხისმგებელი სალალდებულო ველია."),
        employee: Yup.string().required("თანამშრომელი სალალდებულო ველია."),
        fromDate: Yup.string().required("დაწყების თარიღი სალალდებულო ველია."),
        toDate: Yup.string().required("დასრულების თარიღი სალალდებულო ველია."),
        reason:Yup.string().required("მივლინების დანიშნულება სალალდებულო ველია."),
        transport:Yup.string().required("ტრანსპორტი სალალდებულო ველია.")
    }),

    "workingDataHours": Yup.object().shape({
        branch: Yup.string().required("მაღაზია სალალდებულო ველია."),
        responsible: Yup.string().required("პასუხისმგებელი სალალდებულო ველია."),
        employee: Yup.string().required("თანამშრომელი სალალდებულო ველია."),
        startTime: Yup.string().required("დაწყების დრო სალალდებულო ველია."),
        endTime: Yup.string().required("დასრულების დრო სალალდებულო ველია.")
    }),

    "login": Yup.object().shape({
        personalId: Yup.string().required("პირადი ნომერი სალადებულო ველია."),
        password: Yup.string().required("პაროლი სალადებულო ველია.")
    })
}