import React, { useState, } from "react";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { register } from "../models/user/authActions";
import { connect } from 'react-redux'
import EventsSection from '../components/Sections/EventsSection'
import "react-datepicker/dist/react-datepicker.css";
import CarouselComponent from "../components/carousel";
import BirthDaySection from "../components/Sections/BirthDaySection";
import NewEmployeesSection from "../components/Sections/NewEmployeesSerction";
import { User } from "../models/user/user";
import { Event } from "../models/event/event";
import { bannerService } from "../models/banner/service";
import { QueryOptions } from "../helpers/query.options";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    leftcolumn: {
      padding: 10
    }
  })
);

const MainPage = (props) => {
  const classes = useStyles();
  const [newEmployees, setNewEmployees] = useState<User[]>([])
  const [events, setEvents] = useState<Event[]>([])
  const [bDayEmployees, setbDayEmployees] = useState<User[]>([])



  return <div className={classes.root}>
    <Grid container spacing={0}>
      <Grid item xs={12} lg={3} spacing={6} style={{ padding: 20 }}>
        {events?.length > 0 && <h4>ღონისძიებები</h4>}
        <EventsSection setEvts={(events) => {
          setEvents(events)
        }} />
      </Grid>
      <Grid item xs={12} lg={6} style={{ padding: 10 }} >
        <CarouselComponent />
      </Grid>
      <Grid item xs={12} lg={3} spacing={2} style={{ paddingTop: 0, paddingLeft: 20 }} className={"bday"}>
        {
          bDayEmployees?.length > 0 &&
          <h4 >იუბილეები</h4>}
        <BirthDaySection
          newEmployees={newEmployees}
          setEmps={(employees) => {
            setbDayEmployees(employees)
          }}
        />
        <br />
        <br />
        <br />

        {
          newEmployees?.length > 0 &&
          <h4>ახალი თანამშრომლები</h4>}
        <NewEmployeesSection
          bdayEmployees={bDayEmployees}
          setEmps={(employees) => {
            setNewEmployees(employees)
          }}
          style={{ paddingTop: 40, marginTop: 10, paddingLeft: 20 }} />
      </Grid>
    </Grid>
  </div >
}


const mapStateToProps = (state: any) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, { register })(MainPage)