import { ResourceProvider } from "../../providers/resource.provider";
import { VacationWorkingDayHoursAdapterSerializer, WorkingDayHoursExportSerializer, WorkingDayHoursOptionSerializer, WorkingDayHoursReportSerializer, WorkingDayHoursSerializer } from "./serializer";
import { VacationWorkingDayHoursAdapter, WorkingDayHours, WorkingDayHoursOption, WorkingDayHoursReport, WorkingDaysExport } from "./wokringDay";


export class WorkingDayHoursProvider extends ResourceProvider<WorkingDayHours> {
    constructor() {
        super("rest_working_day", new WorkingDayHoursSerializer())
    }
}

export class WorkingDayHourOptionsProvider extends ResourceProvider<WorkingDayHoursOption> {
    constructor() {
        super("rest_working_day_note_option", new WorkingDayHoursOptionSerializer())
    }
}
export class VacationWorkingDayHourOptionsProvider extends ResourceProvider<VacationWorkingDayHoursAdapter> {
    constructor() {
        super("rest_employee_vacation_days", new VacationWorkingDayHoursAdapterSerializer())
    }
}
export class WorkingDayHourReportProvider extends ResourceProvider<WorkingDayHoursReport> {
    constructor() {
        super("rest_working_day_hours_report", new WorkingDayHoursReportSerializer())
    }
}
export class WorkingDayHourExportProvider extends ResourceProvider<WorkingDaysExport> {
    constructor() {
        super("rest_working_day_export", new WorkingDayHoursExportSerializer())
    }
}

export const workingDayHoursOptionsService = new WorkingDayHourOptionsProvider()
export const workingDayHoursService = new WorkingDayHoursProvider()
export const workingDayHoursReportService = new WorkingDayHourReportProvider()
export const workingDaysExportService = new WorkingDayHourExportProvider()
export const vacationWorkingDaysService = new VacationWorkingDayHourOptionsProvider()