import {
    Button,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch,
    TextField,
    Typography
} from "@material-ui/core";

import { ErrorMessage, Form, Formik } from "formik"
import { connect } from 'react-redux'

import React, { useEffect, useState } from "react"
import { validationSchemas } from "../../helpers/validations"
import { setSnackBar } from '../../models/application/applicationActions'
import { Contact, EmbeddedAddress, User } from "../../models/user/user";
import { userUpdateService } from "../../models/user/service";

const ProfileEditForm = (props) => {
    const { onClose, item, setSnackBar, auth } = props
    const [localValues, setLocalValues] = useState(null as any)
    let error: string = null

    const getValues = (values) => {
        const contact = new Contact()
        contact.personalEmail = values.email
        contact.personalMobile = values.mobile
        contact.personalPhone = values.phone
        contact.showPersonalPhone = values.showPersonalPhone
        contact.showPersonalEmail = values.showPersonalEmail
        const address = new EmbeddedAddress()
        address.city = values.city
        address.street = values.street
        address.street2 = values.details
        contact.personalAddress = address
        const user = new User()
        user.id = auth.user.id
        user.contact = contact
        console.log(address)
        userUpdateService.updateNoForm(user).then(r => {
            if (r.id) {
                setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'პირადი ინფორმაცია წარმატებით განახლდა.', open: true })
                setTimeout(() => {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                }, 5000)
                onClose()
            }
        })
    }

    useEffect(() => {
        setLocalValues({ name: '' })
    }, [item])

    const formik = <Formik validationSchema={validationSchemas['profile']}
        initialValues={item ? {
            ...item,
            email: item?.contact?.corporateEmail,
            street: item?.contact?.personalAddress?.street,
            details: item?.contact?.personalAddress?.street2,
            city: item?.contact?.personalAddress?.city,
            mobile: item?.contact?.officePhone,
            showPersonalPhone: item?.contact?.showPersonalPhone,
            showPersonalEmail: item?.contact?.showPersonalEmail
        } : {}}
        onSubmit={getValues}>
        {({ values, handleSubmit, handleChange, setFieldValue, handleBlur, errors }: any) => (
            <Form onSubmit={(evt) => {
                const [e] = Object.keys(errors);
                if (e) {
                    error = e
                } else {
                    error = null
                }
                handleSubmit(evt)
            }}>
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    <Grid item lg={4}>
                        <TextField
                            style={{ width: '100%', }}
                            id="outlined-basic"
                            label="ელ.ფოსტა"
                            variant="outlined"
                            type="email"
                            name="email"
                            onChange={(value) => {
                                setFieldValue("email", value.target.value)
                            }}
                            onBlur={handleBlur}
                            value={values?.email} />
                    </Grid>
                    {/* <Grid item lg={4}>
                        <TextField
                            style={{ width: '100%', }}
                            id="outlined-basic"
                            label="პირადი ტელეფონი"
                            variant="outlined"
                            type="number"
                            name="phone"
                            onChange={(value) => {
                                setFieldValue("phone", value.target.value)
                            }}
                            onBlur={handleBlur}
                            value={values?.phone} />
                    </Grid> */}
                    <Grid item lg={4}>
                        <TextField
                            style={{ width: '100%', }}
                            id="outlined-basic"
                            label="კორპორატიული მობილური"
                            variant="outlined"
                            type="number"
                            name="phone"
                            onChange={(value) => {
                                setFieldValue("mobile", value.target.value)
                            }}
                            onBlur={handleBlur}
                            value={values?.mobile} />
                    </Grid>

                    <Grid item lg={4} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="email" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={4} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="phone" >
                            {msg => <span className="error" style={{ marginTop: 10 }}>{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={4} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="mobile" >
                            {msg => <span className="error" style={{ marginTop: 10 }}>{msg}</span>}
                        </ErrorMessage>
                    </Grid>


                    <Grid item lg={12} style={{ padding: 3, paddingLeft: 20 }}>
                        <h5>პირადი მისამართი</h5>
                    </Grid>
                    <Grid container lg={12} spacing={2} style={{ padding: 10, }}>
                        <Grid item lg={5}>
                            <TextField
                                style={{ width: '100%', }}
                                id="outlined-basic"
                                label="მისამართი"
                                variant="outlined"
                                type="text"
                                name="street"
                                onChange={(value) => {
                                    setFieldValue("street", value.target.value)
                                }}
                                onBlur={handleBlur}
                                value={values?.street} />
                        </Grid>
                        <Grid item lg={4}>
                            <TextField
                                style={{ width: '100%', }}
                                id="outlined-basic"
                                label="მისამართის დეტალები"
                                variant="outlined"
                                type="text"
                                name="details"
                                onChange={(value) => {
                                    setFieldValue("details", value.target.value)
                                }}
                                onBlur={handleBlur}
                                value={values?.details} />
                        </Grid>
                        <Grid item lg={3}>
                            <TextField
                                style={{ width: '100%', }}
                                id="outlined-basic"
                                label="ქალაქი"
                                variant="outlined"
                                type="text"
                                name="city"
                                onChange={(value) => {
                                    setFieldValue("city", value.target.value)
                                }}
                                onBlur={handleBlur}
                                value={values?.city} />
                        </Grid>
                    </Grid>

                    <Grid item lg={2} style={{ padding: 3, paddingLeft: 20 }}></Grid>
                    <Grid item lg={12} style={{ padding: 3, paddingLeft: 20 }}>
                        <FormGroup>
                            <FormControlLabel control={<Switch
                                value={values.showPersonalPhone}
                                checked={values.showPersonalPhone}
                                onChange={(e, value) => {
                                    e.persist()
                                    setFieldValue("showPersonalPhone", value)
                                }}
                            />} label={<Typography style={{ fontSize: 12 }}>
                                გამოვაჩინოთ კორპორატიული ნომერი
                            </Typography>} />
                            {/* <FormControlLabel
                                style={{ fontSize: 6 }}
                                control={<Switch
                                    value={values.showPersonalEmail}
                                    checked={values.showPersonalEmail}
                                    onChange={(e, value) => {
                                        e.persist()
                                        setFieldValue("showPersonalEmail", value)
                                    }}
                                />} label={<Typography style={{ fontSize: 12 }}>
                                    გამოვაჩინოთ ელ.ფოსტა
                                </Typography>} />
 */}
                        </FormGroup>
                    </Grid>

                    <Grid lg={4} sm={1} md={1}></Grid>
                    <Grid container lg={8} sm={11} md={11} style={{ padding: 20, marginTop: 20 }}>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                            <Button color="secondary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                onClick={() => onClose(false)}>გაქმება</Button>
                        </Grid>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 10 }}>
                            <Button color="primary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                type="submit">გაგზავნა</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Form>
        )}
    </Formik>
    return <div style={{ padding: 20 }}>


        {localValues ? formik : <div></div>}




    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(ProfileEditForm)



