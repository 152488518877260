import {
    Button,
    createStyles,
    Dialog,
    Grid,
    makeStyles,
    SnackbarOrigin, TextField, Theme, Typography
} from "@material-ui/core";
import { ErrorMessage, Form, Formik, useField } from "formik"
import { connect } from 'react-redux'

import React, { useEffect, useState } from "react"
import MomentUtils from '@date-io/moment'
import "moment/locale/ka"
import moment from "moment"
import { validationSchemas } from "../../helpers/validations"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import CloseIcon from '@material-ui/icons/Close';
import { setSnackBar } from '../../models/application/applicationActions'
import { DismissRequest } from "../../models/request/request";
import { dismissRequestService } from "../../models/request/service";

export interface State extends SnackbarOrigin {
    open: boolean;
    text: string;
}


const MyTextArea = ({ label, ...props }) => {

    const [field, meta] = useField(props as any);
    return (
        <>
            <TextField {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </>
    );
};


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        formControl: {
            minWidth: '100%',
        },
        selectEmpty: {
            marginTop: theme.spacing(10),
        },
    }),
);

const DismissRequestForm = (props) => {
    moment.locale("ka")
    const { onOpen, onClose, item, setSnackBar } = props
    const [locale, setLocale] = useState("ka")
    const classes = useStyles()
    const [localValues, setLocalValues] = useState(null as any)
    const [dm, setDm] = useState(null as DismissRequest)


    const handleClose = () => {
        onClose({ open: false, item: null, reload: true })
    }

    const [selectedDate, setSelectedDate] = useState<Date | null>(
        new Date(),
    );


    const getValues = (values) => {
        const dmo = new DismissRequest()
        dmo.employee = dm.employee.id
        dmo.responsible = dm.responsible.id
        dmo.dueDate = selectedDate
        dmo.reason = values.reason
        dismissRequestService.create(dmo).then((resp: any) => {
            if (!resp.status) {
                handleClose()
                props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'ჩანაწერი დაემატა წარმატებით', open: true })
                setTimeout(() => {
                    props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                }, 5000)
            } else {
                let text = 'დაფიქსირდა შეცდომა'
                props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text, open: true })
                setTimeout(() => {
                    props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                }, 5000)
            }
        })

    }

    useEffect(() => {
        if (item) {
            setDm(item)
        }
    }, [item])
    useEffect(() => {

    }, [localValues])

    useEffect(() => {
        if (dm) {
            const { employee, responsible } = dm
            setLocalValues({ responsible: responsible.fullName, employee: employee.fullName, dueDate: new Date(), reason: '' })
        }
    }, [dm])

    const formik = <Formik validationSchema={validationSchemas['dismissRequest']}
        initialValues={dm ? localValues : {}}
        onSubmit={getValues}>
        {({ values, handleSubmit, handleChange, handleBlur }: any) => (
            <Form onSubmit={handleSubmit}>
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    <Grid item lg={6}>
                        <TextField
                            style={{ width: '100%', }}
                            id="outlined-basic"
                            label={'თანამშრომელი'}
                            variant="outlined"
                            type="text"
                            disabled={true}
                            name="employee"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.employee} />
                    </Grid>
                    <Grid item lg={6}>
                        <TextField
                            style={{ width: '100%', height: 20 }}
                            id="outlined-basic"
                            label={'განაცხადზე პასუხისმგებელი'}
                            variant="outlined"
                            type="text"
                            disabled={true}
                            name="responsible"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.responsible} />
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="employee" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="responsible" >
                            {msg => <span className="error" style={{ marginTop: 10 }}>{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                format="DD-MM-yyyy"
                                margin="normal"
                                name="dueDate"
                                id="date-picker-inline"
                                label="ძალაში შესვლის თარიღი"
                                value={selectedDate}
                                onChange={(date: any) => {
                                    try {
                                        setSelectedDate(date._d)
                                    } catch {

                                    }
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <MyTextArea
                            name="reason"
                            style={{ width: '100%' }}
                            id="outlined-multiline-static"
                            label="მიუთითეთ მიზეზი"
                            rows={3}
                            variant="outlined"
                        />

                    </Grid>

                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>

                        <ErrorMessage name="dueDate" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>


                    </Grid>
                    <Grid lg={4} sm={1} md={1}></Grid>
                    <Grid container lg={8} sm={11} md={11}>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                            <Button color="secondary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                onClick={() => onClose(false)}>გაქმება</Button>
                        </Grid>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 10 }}>
                            <Button color="primary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                type="submit">გაგზავნა</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Form>
        )}
    </Formik>
    return <div>

        <Dialog
            maxWidth='md'
            style={{ padding: 20 }}
            open={props.onOpen} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <div style={{ position: 'absolute', right: 10, top: 10 }}>
                <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        handleClose()
                    }} />
            </div>
            <div style={{ padding: 20 }}>
                <Typography variant="h5" component="div">
                    თანამშრომელის განთავისუფლების ფორმა
                </Typography>
                {localValues ? formik : <div></div>}

            </div>

        </Dialog>
    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(DismissRequestForm)