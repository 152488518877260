import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CreateIcon from '@material-ui/icons/Create';
import "moment/locale/ka"
import moment from "moment"

import { connect } from 'react-redux'
import { Button, Grid, TableHead, Typography } from '@material-ui/core';
import { setSnackBar } from '../../models/application/applicationActions'

import { environment } from '../../environment/environment';
import { CVEducation } from '../../models/cv/cv';
import EducationFormDialog from '../CV/EducationFormDialog';
import { ConfirmationDialog } from '../Dialogs/ConfirmationDialog';
import { cvService } from '../../models/cv/service';
import DeleteIcon from '@material-ui/icons/Delete';



const BASE_URL = environment.basePath


const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});



moment.locale("ka")

const EducationTableListComponent = (props) => {
    const { educations, onReload, setSnackBar, user, id } = props
    const classes = useStyles2();
    const [items, setItems] = useState<CVEducation[]>([]);
    const [dialog, setDialog] = useState({ open: false, item: null, reload: false });
    const [confirmation, setConfirmation] = useState({ open: false, text: '', callback: null })
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    let emptyRows

    useEffect(() => {
        if (educations) {
            setItems(educations)
        }
    }, [educations])

    useEffect(() => {
        if (items.length) {
            emptyRows = rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);
        }
    }, [items])

    const tableContent = (<TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
                <TableRow>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        დაწესებულება
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        განხრა
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        წოდება
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        დაწყების თარიღი
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        დასრულების თარიღი
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        საშუალო ქულა
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>დეტალები</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    (rowsPerPage > 0
                        ? items?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : items
                    ).map((item: CVEducation) => (
                        <TableRow key={item.id}>
                            <TableCell component="th" scope="row" style={{ width: 200 }}>
                                {item.school}
                            </TableCell>
                            <TableCell style={{ width: 60 }} align="center">
                                {item.fieldOfStudy}
                            </TableCell>
                            <TableCell style={{ width: 60 }} align="center">
                                {item.degree.name}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {item.startDate}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {item.isCurrent ? 'მიმდინარე' : item.endDate}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                {item.grade}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                <DeleteIcon style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setConfirmation({
                                            text: 'ნამდვილად გსურთ ჩანაწერის წაშლა?', open: true, callback: (value) => {
                                                if (value) {
                                                    cvService.delete(`educations_${item.id}`).then(resp => {
                                                        if (resp.id) {
                                                            onReload()
                                                            setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'განათლება წარმატებით წაიშალა.', open: true })
                                                            setTimeout(() => {
                                                                setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                                                            }, 5000)
                                                        }
                                                    })
                                                }
                                                setConfirmation({ text: '', callback: null, open: false })
                                            }
                                        })
                                    }} />
                                <CreateIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setDialog({ open: true, reload: false, item })
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </TableContainer>)

    return <Fragment>
        <ConfirmationDialog
            title="ჩანაწერის წაშლა"
            open={confirmation.open}
            text={confirmation.text} callback={confirmation.callback} />
        <EducationFormDialog onOpen={dialog.open} onClose={(open) => {
            onReload()
            setDialog({ ...dialog, open })
        }} item={dialog.item} />
        {user?.id === id && <Button color="primary" variant="outlined" onClick={() => {
            setDialog({ open: true, reload: false, item: null })
        }}>
            ახალი ჩანაწერი
        </Button>}
        {items.length > 0 ? <div>{tableContent}</div> : <div style={{ marginTop: 20 }}>
            <Typography variant="h6">
                ჩანაწერები არ მოიძებნა
            </Typography>

        </div>}


    </Fragment >
}

const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user
    }
}

export default connect(mapStateToProps, { setSnackBar })(EducationTableListComponent)