import { createStyles, Dialog, Grid, makeStyles, SnackbarOrigin, Theme, Typography, Button } from "@material-ui/core";
import { connect } from 'react-redux'
import React, { useEffect, useState } from "react"
import "moment/locale/ka"
import moment from "moment"
import CloseIcon from '@material-ui/icons/Close';
import { setSnackBar } from '../../models/application/applicationActions'
import { Vacancy, VacancySubscription } from "../../models/vacancy/vacancy";
import { vacancySubscriptionService } from "../../models/vacancy/service";


export interface State extends SnackbarOrigin {
    open: boolean;
    text: string;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        formControl: {
            minWidth: '100%',
        },
        selectEmpty: {
            marginTop: theme.spacing(10),
        },
    }),
);

const VacancyDialog = (props) => {
    moment.locale("ka")
    const { onOpen, onClose, item, setSnackBar, auth } = props
    const [locale, setLocale] = useState("ka")
    const [localItem, setLocalItem] = useState<Vacancy>()

    const classes = useStyles()
    const [localValues, setLocalValues] = useState(null as any)



    useEffect(() => {
        if (item) {
            setLocalItem(item)
        }
    }, [item])



    const handleClose = () => {
        onClose({ open: false, item: null, reload: true })
    }

    const subscribeToVacancy = () => {
        const subsc = new VacancySubscription()
        subsc.vacancy = localItem
        vacancySubscriptionService.create(subsc).then(r => {
            if (r.id) {
                setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'CV გაგზავნილი წარმატებით.', open: true })
                setTimeout(() => {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'CV გაგზავნილი წარმატებით.', open: false })
                }, 5000)
                handleClose()
            }
        })
    }

   

    return <div>
        <Dialog
            maxWidth='md'
            style={{ padding: 20 }}
            open={props.onOpen} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <div style={{ position: 'absolute', right: 10, top: 10 }}>
                <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        handleClose()
                    }} />
            </div>
            {localItem && <div style={{ width: 1800, padding: 20 }}>
                <Grid container spacing={0} lg={12} style={{}}>

                    <Grid item lg={4}>
                        <div style={{ padding: 10 }}>
                            <Typography variant="h5" component="div">
                                ვაკანსია პოზიციაზე:
                                <b> {localItem.position.name} </b>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item lg={2}>
                        { !localItem.userSubscribed ? <Button color="primary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                            onClick={() => {
                                subscribeToVacancy()
                            }}
                            >გავაგზავნოთ CV</Button>: 
                            <Typography variant="h5" component="div">
                                CV უკვე გადაგზავნილია
                            </Typography>
                            }
                    </Grid>
                </Grid>
                <Grid container spacing={0} lg={12}>
                    <Grid item lg={12}>
                        <div style={{ padding: 10 }}>
                            <Typography variant="h5" component="div">
                                <b>
                                    დეტალები:
                                </b>
                                <br />
                                <br />
                                ადგილმდებარეობა: <b>{localItem?.address?.city.name}, {localItem?.address?.street}</b>
                                <br />
                                დეპარტამენტი: <b>{localItem?.position.department.name}</b>
                                <br />
                                სამუშაო გრაფიკი: <b>{localItem.arrangementType.name}</b>
                                <br />
                                ანაზღაურება: <b>{localItem.salary.name}</b>

                            </Typography>
                            
                        </div>

                    </Grid>
                </Grid>
                <Grid container spacing={0} lg={12}>
                    <Grid item lg={12}>
                        <div style={{ padding: 10 }}>
                            <Typography variant="h5" component="div">
                                საკვალიფიკაციო მოთხოვნები:
                            </Typography>
                            {localItem?.notes?.length ? <div style={{ padding: 10 }}>
                                {localItem.notes.map((item, index) => {
                                    return <p key={index}> - <b>
                                        {item.text}</b></p>
                                })}
                            </div> : <div></div>}
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={0} lg={12}>
                    <Grid item lg={12}>
                        <div style={{ padding: 10 }}>
                            <Typography variant="h5" component="div">
                                პიროვნული თვისებები:
                            </Typography>
                            {localItem?.requiredSkills?.length ? <div style={{ padding: 10 }}>
                                {localItem.requiredSkills.map((item, index) => {
                                    return <p key={index}> - <b>
                                        {item.name}</b></p>
                                })}
                            </div> : <div></div>}
                        </div>
                    </Grid>
                </Grid>
            </div>}



        </Dialog>
    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(VacancyDialog)