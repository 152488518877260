import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";

import { ErrorMessage, Form, Formik } from "formik"
import { connect } from 'react-redux'

import React, { useEffect, useState } from "react"
import { validationSchemas } from "../../helpers/validations"
import { QueryOptions } from "../../helpers/query.options"
import { setSnackBar } from '../../models/application/applicationActions'
import { CV, CVExperience, CVLanguage, Level, SpokenLanguage } from "../../models/cv/cv";
import { cvService, levelService, spokenLanguageService } from "../../models/cv/service";



const SpokenLanguageForm = (props) => {
    const { onClose, item, setSnackBar, existing } = props
    const [levels, setLevels] = useState<Array<Level>>([])
    const [spokenLanguages, setSpokenLanguages] = useState<Array<SpokenLanguage>>([])
    const [localValues, setLocalValues] = useState(null as any)
    const [mode, setMode] = useState('edit')
    const [experience, setExperience] = useState<CVExperience>()
    let error: string = null
    
    
    const getValues = (values) => {
        const language = new CVLanguage()
        language.language = {id: values.language, name: ''}
        language.level = {id: values.level, name: ''}
        language.id = item ? item.id : null
        language.ifOther = ''
        const cv = new CV()
        cv.languages = [language]
        if (mode === 'create') {
            cvService.create(cv).then(resp => {
                if (resp.id) {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'სალაპარაკო ენა წარმატებით დაემატა.', open: true })
                    setTimeout(() => {
                        setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                    }, 5000)
                    onClose()
                }
            })
        } else {
            cvService.updateNoForm(cv).then(resp => {
                if (resp.id) {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'სალაპარაკო ენა წარმატებით განახლდა.', open: true })
                    setTimeout(() => {
                        setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                    }, 5000)
                    onClose()
                }
            })
        }
    }

    useEffect(() => {    
        setLocalValues({ name: '' })
        if (!item) {
            setMode('create')
        }
    }, [item])

    useEffect(() => {
        const options = new QueryOptions()
        options.pageSize = 140
        levelService.list(options).then(e => {
            setLevels(e.results)
        })
        spokenLanguageService.list(options).then(e => {
            setSpokenLanguages(e.results)
        })
    }, [])
    
    const formik = <Formik validationSchema={validationSchemas['spokenLanguage']}
    initialValues={item ? {
        ...item,
        language: item?.language?.id,
        level: item?.level?.id
        }: {}}
        onSubmit={getValues}>
        {({ values, handleSubmit, handleChange, setFieldValue, handleBlur, errors }: any) => (
            <Form onSubmit={(evt) => {
                const [e] = Object.keys(errors);
                if (e) {
                    error = e
                } else {
                    error = null
                }
                handleSubmit(evt)}}>
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    <Grid item lg={6} style={{}}>
                        <FormControl variant="outlined" style={{minWidth: '100%'}}>
                            <InputLabel id="language">სალაპარაკო ენა</InputLabel>
                            <Select
                                name="language"
                                labelId="language"
                                id="language"
                                value={values.language}
                                onChange={(event, value) => {
                                    const [selected] = spokenLanguages.filter(option => `${option.id}` === event.target.value)
                                    if (selected) {
                                        setFieldValue("language", selected.id)
                                    }
                                }}
                                label="სალაპარაკო ენა">
                                {spokenLanguages.filter(l => { return mode === 'edit' ? true : existing.indexOf(l.id) < 0}).map((option, index) => {
                                    return <MenuItem value={option.id} key={index} style={{ color: option.id === 'empty' ? 'darkred' : 'black' }}>
                                        <em>{option.name}</em>
                                    </MenuItem>

                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} style={{}}></Grid>
                    <Grid item lg={6} style={{}}>
                        <FormControl variant="outlined" style={{minWidth: '100%'}}>
                            <InputLabel id="">ცოდნის დონე</InputLabel>
                            <Select
                                name="level"
                                id="level"
                                value={values.level}
                                onChange={(event, value) => {
                                    const [selected] = levels.filter(option => `${option.id}` === event.target.value)  
                                    if (selected) {
                                        setFieldValue("level", selected.id)
                                    }
                                }}
                                label="ქვეყანა">
                                {levels.map((option, index) => {
                                    return <MenuItem value={option.id} key={index} style={{ color: option.id === 'empty' ? 'darkred' : 'black' }}>
                                        <em>{option.name}</em>
                                    </MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="spokenLanguage" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="level" >
                            {msg => <span className="error" style={{ marginTop: 10 }}>{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    
                    {error && <Grid container lg={12} sm={11} md={11} >
                        <span style={{ color: 'red' }}>
                            {errors[error]}
                        </span>
                    </Grid>}
                    <Grid lg={4} sm={1} md={1}></Grid>
                    <Grid container lg={8} sm={11} md={11} style={{padding: 20, marginTop: 20}}>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                            <Button color="secondary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                onClick={() => onClose(false)}>გაქმება</Button>
                        </Grid>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 10 }}>
                            <Button color="primary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                type="submit">გაგზავნა</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Form>
        )}
    </Formik>
    return <div style={{ padding: 20 }}>


        {localValues && levels.length && spokenLanguages.length ? formik : <div></div>}




    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(SpokenLanguageForm)