import React, { useEffect, useState } from "react";
import '../components/styles.css'
import { AppBar, Box, createStyles, Grid, makeStyles, Tab, Tabs, Theme } from "@material-ui/core";
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import "react-datepicker/dist/react-datepicker.css";
import { User } from "../models/user/user";
import { cvService } from "../models/cv/service";
import { CV } from "../models/cv/cv";
import EducationTableListComponent from '../components/Table/EducationTableList'
import ExperienceTableListComponent from '../components/Table/ExperienceTableList'
import SkillTableListComponent from '../components/Table/SkillTableList'
import TrainingTableListComponent from '../components/Table/TrainingTableList'
import PersonalInfo from '../components/Profile/Personalinfo'
import LanguageTableListComponent from '../components/Table/LanguageTableList'
import { userService } from "../models/user/service";
import { login } from '../models/user/authActions'
import { useLocation, useParams } from "react-router";
import VacationComponent from '../components/VacationComponent'
import { canPerformAction } from "../routing";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        leftcolumn: {
            padding: 10
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        }
    })
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ProfilePage = (props) => {
    let { id }: any = useParams();
    const { auth } = props
    let location = useLocation();
    const [user, setUser] = useState<User>()
    const [cv, setCV] = useState<CV>()
    const [loadId, setLoadId] = useState(0)
    const [loadedUserId, setLoadedUserId] = useState(id)
    const classes = useStyles();

    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const initialize = () => {
        loadUser(id)
        cvService.read(id).then(r => {
            setCV(r)
        })
    }

    useEffect(() => {
        initialize()
    }, [])


    const loadUser = (id) => {
        userService.read(id).then(user => {
            setUser(user)
            setLoadId(Math.random() * 1000)
        })
    }
    useEffect(() => {

    }, [user])

    useEffect(() => {
        if (location) {
            const userId = location.pathname.split("/")[2]
            setLoadedUserId(userId)
            loadUser(userId)
            setValue(0);
        }
    }, [location])

    const container = <div className={classes.root}>
        <Grid container spacing={0}>
            <Grid item xs={12} lg={1} spacing={6} style={{ padding: 20 }}>
            </Grid>
            <Grid item xs={12} lg={10} style={{ padding: 20 }}>
                <div className={classes.root}>
                    <AppBar position="static">
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                            <Tab label="პიდარი ინფო" {...a11yProps(0)} />
                            {(canPerformAction(auth?.user, 'working_day_supervisor', 'write') || auth?.user?.id === id) &&
                                <Tab label="გამოცდილება" {...a11yProps(1)} />
                            }
                            {(canPerformAction(auth?.user, 'working_day_supervisor', 'write') || auth?.user?.id === id) &&
                                <Tab label="განათლება" {...a11yProps(2)} />
                            }
                            {(canPerformAction(auth?.user, 'working_day_supervisor', 'write') || auth?.user?.id === id) &&
                                <Tab label="ტრეინინგები" {...a11yProps(3)} />
                            }
                            {(canPerformAction(auth?.user, 'working_day_supervisor', 'write') || auth?.user?.id === id) &&
                                <Tab label="ენები" {...a11yProps(4)} />
                            }
                            {(canPerformAction(auth?.user, 'working_day_supervisor', 'write') || auth?.user?.id === id) &&
                                <Tab label="უნარები" {...a11yProps(5)} />
                            }
                            {(canPerformAction(auth?.user, 'working_day_supervisor', 'write') || auth?.user?.id === id) &&
                                (user?.joined || loadedUserId === auth?.user?.id) &&
                                <Tab label="შვებულება" {...a11yProps(6)} />
                                }
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <PersonalInfo onReload={initialize} user={user} loadControl={loadId} id={id} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {(canPerformAction(auth?.user, 'working_day_supervisor', 'write') || auth?.user?.id === id) &&
                            <ExperienceTableListComponent experiences={cv?.experiences} id={id} onReload={initialize} />
                        }

                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {(canPerformAction(auth?.user, 'working_day_supervisor', 'write') || auth?.user?.id === id) &&
                            <EducationTableListComponent educations={cv?.educations} id={id} onReload={initialize} />
                        }
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        {(canPerformAction(auth?.user, 'working_day_supervisor', 'write') || auth?.user?.id === id) &&
                            <TrainingTableListComponent trainings={cv?.trainings} id={id} onReload={initialize} />
                        }
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        {(canPerformAction(auth?.user, 'working_day_supervisor', 'write') || auth?.user?.id === id) &&
                            <LanguageTableListComponent languages={cv?.languages} id={id} onReload={initialize} />
                        }
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        {(canPerformAction(auth?.user, 'working_day_supervisor', 'write') || auth?.user?.id === id) &&
                            <SkillTableListComponent skills={cv?.skills} id={id} onReload={initialize} />
                        }
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        {(canPerformAction(auth?.user, 'working_day_supervisor', 'write') || auth?.user?.id === id) &&
                            <VacationComponent id={id}></VacationComponent>
                        }
                    </TabPanel>
                </div>

            </Grid>
            <Grid item xs={12} lg={2}>

            </Grid>
        </Grid>
    </div>




    return container


}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { login })(ProfilePage)