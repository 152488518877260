import React, { useState, useEffect } from "react";
import { createStyles, Grid, makeStyles, Theme, AppBar, Tab, Tabs, Box, Typography } from "@material-ui/core";
import { connect } from 'react-redux'
import "react-datepicker/dist/react-datepicker.css";


import { NameIdItem } from "../models/common/common";

import EmployeeFilesTableListComponent from '../components/Table/EmployeeFilesTable'
import MySharedDocumentsTableComponent from '../components/Table/MySharedDocumentsTable'
import ReceivedSharedDocumentsTableComponent from '../components/Table/ReceivedSharedDocumentsTable'
import AllFilesTableListComponent from '../components/Table/AllDocumentsTable'
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}




const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        leftcolumn: {
            padding: 10
        }
    })
);

const DocumentsPage = (props) => {
    const [dialog, setDialog] = useState({ open: false, tag: null, tags: null, reload: false });
    const classes = useStyles();
    const [tags, setTags] = useState<NameIdItem[]>()
    const [tag, setTag] = useState<NameIdItem>()
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return <div className={classes.root}>
        <Grid container spacing={0}>
        <Grid container
            style={{ textAlign: 'center', justifyContent: 'center' }}
            direction="row"
            lg={12} >
            <h2>
                დოკუმენტები
            </h2>

        </Grid>
            <Grid item xs={12} lg={12} spacing={6} style={{ padding: 20 }}>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="საჯარო დოკუმენტები" {...a11yProps(0)} />
                        <Tab label="ჩემი დოკუმენტები" {...a11yProps(1)} />
                        <Tab label="ჩემი გაზიარებული დოკუმენტები" {...a11yProps(2)} />
                        <Tab label="მიღებული დოკუმენტები" {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <AllFilesTableListComponent />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <EmployeeFilesTableListComponent />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <MySharedDocumentsTableComponent />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <ReceivedSharedDocumentsTableComponent />
                </TabPanel>
            </Grid>
        </Grid>
    </div >
}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, {})(DocumentsPage)