

import { ResourceProvider } from "../../providers/resource.provider"
import { NameIdItem } from "../common/common"
import { NameIdItemSerializer } from "../common/serializer"
import { EmployeeFile, EmployeeFileShare} from "./document"
import { EmployeeFileSerializer, EmployeeFileShareSerializer } from "./serialize"

export class EmployeeFileTagsProvider extends ResourceProvider<NameIdItem> {
    constructor() {
        super("rest_employee_file_tag", new NameIdItemSerializer())
    }
}
export class EmployeeFileProvider extends ResourceProvider<EmployeeFile> {
    constructor() {
        super("rest_employee_file", new EmployeeFileSerializer())
    }
}
export class EmployeeFileShareProvider extends ResourceProvider<EmployeeFileShare> {
    constructor() {
        super("rest_employee_file_share", new EmployeeFileShareSerializer())
    }
}

export const employeeFileTagsProvider = new EmployeeFileTagsProvider()
export const employeeFileService = new EmployeeFileProvider()
export const employeeFileShareService = new EmployeeFileShareProvider()
