import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Card, CardActions, CardContent, CardHeader, CardMedia, createStyles, IconButton, makeStyles, Menu, MenuItem, Theme, Typography } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import { connect } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { eventSubscriptionService } from '../../models/event/service';
import { EventSubscription } from '../../models/event/event';
import { ConfirmationDialog } from '../Dialogs/ConfirmationDialog';
import { setSnackBar } from '../../models/application/applicationActions'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: 20,
            width: '100%',
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        }
    }),
);

export interface EventCardProps {
    event: any,
    statuses: any
}

export const EventCard = ({ event, statuses, setSnackBar }: EventCardProps | any) => {
    const classes = useStyles();
    const [confirmation, setConfirmation] = React.useState({ text: '', open: false, status: {}, callback: null })
    const [expanded, setExpanded] = useState(false);
    const [subscription, setSubscription] = useState<EventSubscription>();

    useEffect(() => {
        if (event.id) {
            getSubscription(event.id)
        }
    }, [event])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const updateStatus = (status) => {
        const subs = new EventSubscription()
        subs.event = event
        subs.status = status
        eventSubscriptionService.create(subs).then(r => {
            setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'გამოწერა განახლებულია', open: true })
            setTimeout(() => {
                setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
            }, 5000)
            setSubscription(subs)

        })

    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const getSubscription = (id) => {
        eventSubscriptionService.read(id).then(resp => {
            if (resp?.event?.id) {
                setSubscription(resp)
            }
        })
    }

    const handleClose = (item) => {
        setAnchorEl(null);
    };

    return <Card className={classes.root}>
        <ConfirmationDialog open={confirmation.open} text={confirmation.text} callback={(c) => {
            
            if (c) {
                updateStatus(confirmation?.status)
                setAnchorEl(null);
            } 
            setConfirmation({ text: '', open: false, callback: null } as any)
        }} />

        <CardMedia
            className={classes.media}
            image={event.image}
            title="photo"
        />
        <CardContent>
            <CardHeader

                title={event.name}
                subheader={event.date}

            />

            <CardActions>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    {subscription?.status ? subscription.status.name : 'გამოვიწეროთ'}
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {statuses?.length > 0 && statuses.map((item, index) => {
                        return <MenuItem key={index} onClick={() => {
                            setConfirmation({ text: `გამოწერის სტატუსი გახდება: ${item.name}`, open: true, status: item, callback: null })
                            setAnchorEl(null);
                        }}>{item.name}</MenuItem>
                    })}
                </Menu>

                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>

            </CardActions>
        </CardContent>


        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
                <Typography paragraph>აღწერა:</Typography>
                <Typography paragraph>
                <span dangerouslySetInnerHTML={{__html: event.description}}></span>
                </Typography>
            </CardContent>
        </Collapse>
    </Card>

}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(EventCard)