import React from "react";
import OrganizationChart from "@dabeng/react-orgchart";
import { connect } from 'react-redux'
import ChartCard from "./Template";


const OrgChart = (props) => {
    const { data } = props
    return <OrganizationChart
                collapsible={false}
                datasource={data}
                chartClass="myChart"
                NodeTemplate={ChartCard} />;
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(OrgChart)