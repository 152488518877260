import { AddressSerializer } from "../address/serializer";
import { nameIdItemSerializer } from "../common/serializer";
import { Serializer } from "../serializer";
import { employeeSerializer, UserSerializer } from "../user/serializer";
import { Branch, BranchWorkingHoursTable } from "./branch";

export class BranchSerializer implements Serializer {
  fromJson(json: any) {
    const branch = new Branch();
    const addressSerializer = new AddressSerializer();
    const userSerializer = new UserSerializer();
    branch.id = json.id;
    branch.uid = json.uid;
    branch.openTime = json.open_time;
    branch.workingDayGroup = json.working_day_group;
    branch.closeTime = json.close_time;
    branch.phone = json.phone;
    branch.type = nameIdItemSerializer.fromJson(json.type);
    branch.navId = json.nav_id;
    branch.address = addressSerializer.fromJson(json.address);
    if (json.managers) {
      branch.managers = json.managers.map((m) => userSerializer.fromJson(m));
    }
    if (json.sales_manager) {
      branch.salesManager = userSerializer.fromJson(json.sales_manager);
    }
    return branch;
  }

  toJson(item: any) {
    const obj: any = {};
    return obj;
  }
}
export class BranchWorkingHoursTableSerializer implements Serializer {
  fromJson(json: any) {
    const branch = new BranchWorkingHoursTable();
    branch.id = json.uid;
    branch.name = json.month_name;
    const employeeSerializer = new UserSerializer();
    branch.owner = employeeSerializer.fromJson(json.owner);
    return branch;
  }

  toJson(item: any) {
    const obj: any = {};
    return obj;
  }
}
