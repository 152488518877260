import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Grid, Button } from '@material-ui/core';
import logo from '../assets/images/logo.png'

const Footer = (props) => {
    const history = useHistory();
    function redirect(url: string) {
        history.push(url);
    }

    return <AppBar position="fixed" style={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
            <Grid container >
                <Grid item lg={2}></Grid>
                <Grid item lg={10}>
                    <img src={logo} 
                    style={{ 
                        cursor: 'pointer',
                        width: 80, marginRight: 40, marginTop: 8 }} 
                        onClick={() => { redirect("/") }}
                    />
                    <Button color="inherit" 
                    style={{marginTop: -15}}
                    onClick={() => { redirect("/faq") }}
                    >
                        ხშირად დასმული კითხვები
                    </Button>
                </Grid>
            </Grid>
        </Toolbar>
    </AppBar>

}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, {})(Footer)

