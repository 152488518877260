import React from 'react';

import { Card, CardContent, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 5,
            marginBottom: 20
        },
        avatar: {},
        media: {
            height: 0,
            paddingTop: '56.25%',
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        title: {
            fontSize: 13,
        },
        pos: {
            marginBottom: 12,
        },
    }),
);

export default function TrainingCard(props) {
    const classes = useStyles();

    const { item, clicked } = props
    const history = useHistory();
    return <Card className={classes.root} style={{ alignItems: 'center', paddingTop: 0 }}>
        
        <CardContent style={{ width: '100%', overflow: 'hidden', cursor: 'pointer' }}
        onClick={()=> {
            clicked(item)
        }}>
            <div style={{ height: 180, display:'grid', minHeight:100 }}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {item.name}
                </Typography>
                <img 
                style={{width: '80%', marginLeft: '5%'}}
                src={`https://img.youtube.com/vi/${item?.videoId}/default.jpg`} />
                

            </div>
        </CardContent>
    </Card>

}

