import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { authenticationReducer } from '../models/user/authReducer'
import { applicationReducer } from '../models/application/applicationReducer'

const rootReducer = combineReducers({ 
    auth: authenticationReducer,
    app: applicationReducer
})

export const store = createStore(rootReducer, applyMiddleware(thunk));
