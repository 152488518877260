import { environment } from "../../environment/environment"
import { EventSerializer } from "../event/serializer"
import { NewsSerializer } from "../news/serializer"
import { Resource } from "../resource"
import { Banner } from "./banner"



export class BannerSerializer extends Resource {
    fromJson(json: any) {
        const banner = new Banner()
        if (json.link_type === "event") {
            const eventSerializer = new EventSerializer()
            banner.event = eventSerializer.fromJson(json.event)
        } else {
            const newsSerializer = new NewsSerializer()
            banner.news = newsSerializer.fromJson(json.news)
        }
        banner.linkType = json.link_type
        banner.title = json.title
        if (json.image) {
            banner.image = `${environment.basePath}/${environment.imagesPath}/banners/${json.image}.jpg` 
        } else  {
            banner.image = null
        }
        return banner
    }
    toJson(item: Banner) {
        return {
        }
    }
}
