import React, { useEffect, useState } from "react";
import {
    FormControlLabel,
    FormGroup,
    Checkbox,
    Avatar
} from "@material-ui/core";
import { ConfirmationDialog } from "../../components/Dialogs/ConfirmationDialog";
import '../../components/styles.css'
import { Button, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Typography from '@material-ui/core/Typography';
import "react-datepicker/dist/react-datepicker.css";
import { User } from "../../models/user/user";
import { cvService } from "../../models/cv/service";
import { CV } from "../../models/cv/cv";
import { userService } from "../../models/user/service";
import { login } from '../../models/user/authActions'
import { useHistory, useParams } from "react-router";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import FileExportDialog from '../../components/Dialogs/FileExportDialog'
import ProfileEditFormDialog from '../../components/Dialogs/ProfileEditFormDialog'
import { environment } from "../../environment/environment";
import { canPerformAction } from "../../routing";
const BASE_URL = environment.basePath

const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        leftcolumn: {
            padding: 10
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        }
    })
);


const PersonalInfo = (props) => {
    let { id }: any = useParams();
    let inputElement
    let history = useHistory()
    const { auth, onReload, loadControl } = props
    const [cv, setCV] = useState<CV>()
    const classes = useStyles();
    const [user, setUser] = useState<User>()
    const [downloadDialog, setDownloadDialog] = useState({ open: false, item: null, reload: false, link: '' });
    const [imageSizeError, setImageSizeError] = useState(false)
    const [dialog, setDialog] = useState({ open: false, item: null, reload: false });
    const [value, setValue] = React.useState(0);
    const [file, setFile] = useState(null)
    const [confirmation, setConfirmation] = React.useState<any>({ text: '', open: false, callback: null })
    const [image, setImage] = useState(null)
    const [imageError, setImageError] = useState(false)


    useEffect(() => {
        if (file && confirmation.open && confirmation.callback === 'updateImage' && confirmation.confirmed) {
            updateImage(file)
        }
    }, [confirmation, file])

    const onDrop = (event) => {
        setImageError(false)
        const [file] = event.target.files
        if (file) {
            if (file.type === 'image/jpeg') {
                if (file.size < 300000) {
                    setImage(URL.createObjectURL(file))
                    setFile(file)
                    setConfirmation({ text: "ნამდვილად გსურთ სურათს შეცვლა?", open: true, callback: 'updateImage' })
                } else {
                    setImageSizeError(true)
                }
            } else {
                setImageError(true)
            }
        }
    }

    const updateImage = (file) => {
        const employee = new User()
        employee.image = file
        employee.id = user.id
        setConfirmation({ ...confirmation, open: false, callback: '', confirmed: false })
        userService.update(employee).then((r) => {
            let usr = new User()
            usr = usr.updateUser({ ...user, photo: r.photo } as User)
            props.login(usr)
        })
    }

    history.listen((location, action) => {
        if (location.pathname.indexOf("/profile/") > -1) {
            setImage(user?.photo)
        }
    });


    const initialize = () => {
        loadUser(id)
        cvService.read(id).then(r => {
            setCV(r)
        })
    }

    const loadUser = (id) => {
        userService.read(id).then(user => {
            setUser(user)
        })
    }

    useEffect(() => {
        setImage(user?.photo)
    }, [user])

    useEffect(() => {
        if (loadControl > 0) {
            initialize()
        }
    }, [loadControl])

    useEffect(() => {
        if (file && confirmation.open && confirmation.callback === 'updateImage' && confirmation.confirmed) {
            updateImage(file)
        }
    }, [confirmation, file])

    useEffect(() => {
        console.log(auth?.user)
    }, [auth?.user])


    const card = <Card className={classes.root}>
        <ConfirmationDialog open={confirmation.open} text={confirmation.text} callback={(c) => {
            if (c) {
                setConfirmation({ ...confirmation, open: true, callback: 'updateImage', confirmed: true })
            }
        }} />
        <ProfileEditFormDialog
            item={user}
            onOpen={dialog.open}
            onClose={(open) => {
                onReload()
                setDialog({ ...dialog, open })
            }}
        />
        <CardContent>
            <input
                style={{ visibility: 'hidden' }}
                ref={input => inputElement = input}
                type="file" id="file" onChange={onDrop} name="image" />
            <Grid container lg={12}>
                <Grid item lg={12}>
                    {imageError && <span className="error">
                        მისაღებია მხოლოდ JPEG ფორმატის სურათები</span>}
                    {imageSizeError && <span className="error">
                        სურათის ზომა არის 300კბ-ზე მეტი</span>}
                </Grid>
                <Grid item lg={2}>
                    <div style={{
                        position: 'relative',
                        width: image ? 90 : 200

                    }}>
                        {!image && auth?.user?.id === id && <div onClick={() => {
                            inputElement.click()
                        }}>
                            <AccountBoxIcon fontSize="large" />
                            <Button size="small"
                                style={{
                                    position: 'relative',
                                    top: -13
                                }}
                                color="primary" >ატვირთე სურათი</Button>
                        </div>}
                        {image && auth?.user?.id === id ? <div style={{ width: '100%' }}>
                            <div
                                onClick={
                                    () => {
                                        inputElement.click()
                                    }
                                }
                                style={
                                    {
                                        position: 'absolute',
                                        top: -5,
                                        left: -10,
                                        width: 35,
                                        height: 35,
                                        borderRadius: 45,
                                        borderWidth: 1,
                                        borderColor: 'black',
                                        backgroundColor: '#dadada'
                                    }}>
                                <div style={{ position: 'relative' }}>
                                    <EditOutlinedIcon style={{ position: 'absolute', top: 3, left: 6 }} />
                                </div>
                            </div>
                            <img src={image} style={{ width: '100%' }} />
                            <br></br>

                        </div> : <Avatar
                            alt={user?.fullName}

                            className="avatar"
                            style={{ width: 80, height: 80, marginLeft: 10, marginTop: 10 }} />}

                    </div>
                </Grid>
                <Grid item lg={3} style={{ height: 150, padding: 10 }}>
                    {cv && cv?.isFileGenerated && auth?.user?.id === id &&
                        <div style={{ padding: 4, height: 40, width: 140, position: 'relative', cursor: 'pointer' }}>
                            <PictureAsPdfIcon style={{ position: 'absolute', top: 2, left: 2 }} />
                            <span style={{ fontSize: 12, position: 'absolute', top: 2, right: 2 }}
                                onClick={() => {
                                    const link = `${BASE_URL}/assets/files/CV/${cv.employee.id}.pdf?${Math.random() * 10000000}`
                                    setDownloadDialog({ open: true, reload: false, link } as any)
                                }}>
                                გადმოწერე შენი CV
                            </span>
                        </div>
                    }

                    <Typography component="p" variant="h6" style={{ fontWeight: 'bold', fontSize: 15 }}>
                        {user?.firstName} {user?.lastName}
                    </Typography>
                    <Typography component="p" variant="h6" style={{ fontWeight: 'bold', fontSize: 13 }}>
                        {user?.naviEmployee?.jobTitle}
                    </Typography>
                    {user?.naviEmployee?.orgUnitName && <Typography component="p" variant="h6" style={{
                        fontWeight: 'bold',
                        fontSize: 12,
                        padding: 5,
                        marginTop: 5,
                        width: '70%',
                        color: '#b9262d',
                        borderRadius: 5,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: '#b9262d'
                    }}>
                        {user?.naviEmployee?.orgUnitName}
                    </Typography>}
                </Grid>
                {auth?.user?.id === id && <Grid item lg={4}>
                    {auth?.user?.id === id && <div onClick={() => {
                        setDialog({ open: true, reload: false, item: user })
                    }}>
                        {/*  <EditOutlinedIcon style={{ position: 'relative', top: 3, left: 6 }} /> */}
                    </div>}
                    {auth.user.id === id && <Typography component="p" variant="h6" style={{ fontSize: 12 }}>


                    </Typography>}
                    <Typography component="p" variant="h6" style={{ fontSize: 12 }}>
                        მისამართი:
                        <b style={{ marginLeft: 4 }}>
                            {user?.contact?.personalAddress?.street}
                        </b>
                    </Typography>

                    <Typography component="p" variant="h6" style={{ fontSize: 12 }}>
                        მისამართის დეტალები:
                        <b style={{ marginLeft: 4 }}>
                            {user?.contact?.personalAddress?.street2}
                        </b>
                    </Typography>
                    <Typography component="p" variant="h6" style={{ fontSize: 12 }}>
                        ქალაქი:
                        <b style={{ marginLeft: 4 }}>
                            {user?.contact?.personalAddress?.city}
                        </b>
                    </Typography>
                </Grid>}
                <Grid item lg={3}>
                    {auth?.user?.id === id && <div onClick={() => {
                        setDialog({ open: true, reload: false, item: user })
                    }}>
                        {/* <EditOutlinedIcon style={{ position: 'relative', top: 3, left: 6 }} /> */}
                    </div>}
                    {/* {auth.user.id === id && <Typography component="p" variant="h6" style={{ fontSize: 12 }}>
                        პირადი ტელეფონის ნომერი:
                        <b> {user?.contact?.personalPhone}</b>
                    </Typography>} */}
                    {user?.contact?.officePhone && <Typography component="p" variant="h6" style={{ fontSize: 12 }}>
                        კორპორატული ტელეფონის ნომერი:
                        <b>
                            {user?.contact?.officePhone}
                        </b>
                    </Typography>}

                    {user?.contact?.corporateEmail && <Typography component="p" variant="h6" style={{ fontSize: 12 }}>
                        ელ.ფოსტა:
                        <b>
                            {user?.contact?.corporateEmail}
                        </b>
                    </Typography>}

                </Grid>


            </Grid>
            <Grid container lg={12} style={{ height: 150, padding: 10 }}>
                <Grid item lg={8}>
                    {auth?.user?.id === id && <div onClick={() => {
                        setDialog({ open: true, reload: false, item: user })
                    }}>
                        <EditOutlinedIcon style={{ position: 'relative', top: 3, left: 6 }} />
                        <span style={{ marginLeft: 20, fontSize: 10 }}>პროფილის რედაქტირება</span>
                    </div>}
                    {canPerformAction(user, 'working_day_supervisor', 'delete') &&
                        <Typography component="p" variant="h6" style={{ fontWeight: 'bold', fontSize: 13 }}>
                            მუშაობის დაწყების თარიღი : {user?.joined}
                        </Typography>
                    }
                    {canPerformAction(user, 'working_day_supervisor', 'delete') &&
                        <Typography component="p" variant="h6" style={{ fontWeight: 'bold', fontSize: 13 }}>
                            დაბადების თარიღი: {user?.personalInfo?.birthDate}
                        </Typography>
                    }

                    {user?.contact && auth?.user?.id === id && <FormGroup>
                        {/* <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    disabled={auth?.user?.id === id ? false : true}
                                    checked={user?.contact?.showPersonalPhone}
                                    onChange={() => { }}
                                    color="primary"
                                />
                            }
                            label={<Typography style={{ fontSize: 13 }}>
                                გამოვაჩინოთ კორპორატიული ნომერი
                            </Typography>}

                        /> */}
                        {/* <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    disabled={auth?.user?.id === id ? false : true}
                                    checked={user?.contact?.showPersonalEmail}
                                    onChange={() => { }}
                                    color="primary"
                                />
                            }
                            label={<Typography style={{ fontSize: 13 }}>
                                გამოვაჩინოთ პირადი ელ.ფოსტა
                            </Typography>}
                        /> */}

                    </FormGroup>}
                </Grid>
                <Grid item lg={4}>
                    { }
                </Grid>
            </Grid>

        </CardContent>
    </Card>

    return <div>
        <FileExportDialog onOpen={downloadDialog.open} onClose={(open) => {
            setDownloadDialog({ ...downloadDialog, open })
        }} link={downloadDialog.link} />
        {card}</div>

}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { login })(PersonalInfo)

