import { connect } from 'react-redux'
import React, { useEffect, useState } from "react"
import { Grid } from '@material-ui/core';
import WorkiingDayHoursTable from '../../../components/Table/WorkingDayHoursTable';

export const WorkingHoursListPage = (props) => {
    return <Grid container spacing={3} style={{ marginTop: 30 }}>
        <WorkiingDayHoursTable />
    </Grid>
}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(WorkingHoursListPage)