import React, { useEffect, useState } from "react";

import { createStyles, Grid, GridList, GridListTile, GridListTileBar, ListSubheader, makeStyles, Theme } from "@material-ui/core";


import { connect } from 'react-redux'
import "moment/locale/ka"
import TextField from '@material-ui/core/TextField';
import { chartService } from "../models/user/service";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router";
import { QueryOptions } from "../helpers/query.options";
import { branchEmployeeService } from '../models/user/service'
import { User } from "../models/user/user";
import EmployeeCard from "../components/Employee/EmployeeCard";
import AppsIcon from '@material-ui/icons/Apps';
import ListIcon from '@material-ui/icons/List';
import BranchEmployeeTableListComponent from '../components/Table/BranchEmployeeTable'
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const onSearch$: any = new Subject().pipe(
    debounceTime(1500)
)



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        gridList: {
            width: '100%',
            height: 450,
        },
        icon: {
            color: 'rgba(255, 255, 255, 0.54)',
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        leftcolumn: {
            padding: 10
        }
    })
);

const EmployeesPage = (props) => {
    let { id }: any = useParams();
    const classes = useStyles();
    const [chartData, setChartData] = useState([])
    const [view, setView] = useState("chart")
    const [branchEmployees, setBranchEmployees] = useState<User[]>([])
    const [anyEmployee, setAnyEmployee] = useState<User>()

    useEffect(() => {
        onSearch$.subscribe(
            debounced => {
                getEmployees(debounced)   
            }
        );
        
    }, [])

    const getEmployees = (search) => {
        const options = new QueryOptions()
        if (search) {
            options.search = search
        }
        options.pageSize = 40
        options.type = "list"
        branchEmployeeService.listWithCounter(options).then((employees: any) => {
            if (employees && employees.current) {
                setBranchEmployees(employees.current)
                const [employee] = employees.current
                if (employee) {
                    setAnyEmployee(employee)
                    getTreeForChart(employee.id)
                }
            }
        })
    }

    const getTreeForChart = (employee) => {
        const options = new QueryOptions()
        options.employee = employee
        chartService.list(options).then(users => {
            if (users?.results) {
                setChartData(users.results[0])
            }
        })
    }

    useEffect(() => {
        getEmployees(null)
    }, [])


    return <div className={classes.root}>
        <Grid container
            style={{ textAlign: 'center', justifyContent: 'center' }}
            direction="row"
            lg={12} >
            <h2>
                თანამშრომლები
            </h2>

        </Grid>
        <Grid container spacing={0} style={{ padding: 20 }}>
            <Grid item xs={12} lg={1} spacing={0} style={{ padding: 20 }}>

            </Grid>
            <Grid item xs={6} lg={10}>
                <form noValidate autoComplete="off" >
                    <TextField id="outlined-basic" label="ფილტრი (სახელი, ტელეფონი, ელ.ფოსტა)" style={{ width: 500 }}
                        onChange={(value) => {
                            if (value.target.value.length) {
                                onSearch$.next(value.target.value);
                                
                            } else {
                                getEmployees(null)
                            }
                        }}
                    />
                </form>
                <AppsIcon onClick={() => {
                    setView("grid")
                }} />

                <ListIcon onClick={() => {
                    setView("list")
                }} />


                {
                    view === 'grid' ?
                        <GridList cellHeight={180} className={classes.gridList}>
                            <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                            </GridListTile>
                            {branchEmployees.map((empl, key) => {
                                return <EmployeeCard employee={empl} key={key} />
                            })}
                        </GridList> :
                        <BranchEmployeeTableListComponent branchEmployees={branchEmployees} />
                }
            </Grid>

            <Grid item xs={12} lg={3}>
            </Grid>
            <Grid item xs={12} lg={6} spacing={6} style={{ padding: 20 }}>


            </Grid>
        </Grid>
    </div >
}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(EmployeesPage)