

import { ResourceProvider } from "../../providers/resource.provider"
import { News } from "./news"
import { NewsSerializer } from "./serializer"

class NewsProvider extends ResourceProvider<News> {
    constructor() {
        super("rest_news", new NewsSerializer())
    }
}

export const newsService = new NewsProvider()