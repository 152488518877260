import { BranchSerializer } from "../branch/serializer";
import { Serializer } from "../serializer";
import { UserSerializer } from "../user/serializer";
import {
  VacationWorkingDayHours,
  VacationWorkingDayHoursAdapter,
  WorkingDayHours,
  WorkingDayHoursOption,
  WorkingDayHoursReport,
  WorkingDaysExport,
} from "./wokringDay";

export class WorkingDayHoursSerializer implements Serializer {
  fromJson(json: any) {
    const wdh = new WorkingDayHours();
    const branchSerializer = new BranchSerializer();
    const userSerializer = new UserSerializer();
    wdh.id = json.id;
    wdh.branch = branchSerializer.fromJson(json.branch);
    wdh.hours = json.hours;
    wdh.employee = userSerializer.fromJson(json.employee);
    wdh.responsible = userSerializer.fromJson(json.responsible);
    wdh.created = json.created;
    wdh.date = json.date;
    wdh.startTime = json.start_time;
    wdh.endTime = json.end_time;
    wdh.option = json.note_option;
    wdh.note = json.note;
    return wdh;
  }

  toJson(item: WorkingDayHours) {
    const start_time_f = item.startTime.toISOString().split("T")[0];
    const end_time_f = item.endTime.toISOString().split("T")[0];
    const obj: any = {
      branch: item.branch.id,
      employee: item.employee.id,
      responsible: item.responsible.id,
      start_time_ts: item.startTime.getTime(),
      end_time_ts: item.endTime.getTime(),
      start_time: `${start_time_f}T${item.startTime.toLocaleTimeString()}.100Z`,
      end_time: `${end_time_f}T${item.endTime.toLocaleTimeString()}.100Z`,
      note_option: item.option ? item.option.id : null,
      note: item.note,
    };
    return obj;
  }
}
export class VacationWorkingDayHoursSerializer implements Serializer {
  fromJson(json: any) {
    const wdh = new VacationWorkingDayHours();
    wdh.id = json.id;
    const serializer = new WorkingDayHoursSerializer();
    wdh.values = json.values.map((r) => serializer.fromJson(r));
    return wdh;
  }
  toJson(item: any) {
    return {};
  }
}
export class VacationWorkingDayHoursAdapterSerializer implements Serializer {
  fromJson(json: any) {
    const wdh = new VacationWorkingDayHoursAdapter();
    const serializer = new VacationWorkingDayHoursSerializer();
    const daysSerializer = new WorkingDayHoursSerializer();
    wdh.counters = json.counters.map((i) => serializer.fromJson(i));
    wdh.days = json.days.map((d) => daysSerializer.fromJson(d));
    wdh.daysUsed = json.total_used_vacation_days
    wdh.totalVacationDays = json.total_vacation_days;
    return wdh;
  }
  toJson(item: any) {
    return {};
  }
}

export class WorkingDayHoursOptionSerializer implements Serializer {
  fromJson(json: any) {
    const wdho = new WorkingDayHoursOption();
    wdho.name = json.name;
    wdho.id = json.id;
    return wdho;
  }

  toJson(item: WorkingDayHours) {
    const obj: any = {
      employee: item.employee.id,
      responsible: item.responsible.id,
      startTime: item.startTime.toISOString(),
      endTime: item.endTime.toISOString(),
      option: item.option.id,
    };
    return obj;
  }
}
export class WorkingDayHoursExportSerializer implements Serializer {
  fromJson(json: any) {
    const wde = new WorkingDaysExport();
    wde.id = json.id;
    return wde;
  }

  toJson(item: any) {
    const obj: any = {};
    return obj;
  }
}
export class WorkingDayHoursReportSerializer implements Serializer {
  fromJson(json: any) {
    const wdhr = new WorkingDayHoursReport();
    wdhr.id = json.id;
    wdhr.hasFile = json.has_file;

    return wdhr;
  }
  toJson(item: WorkingDayHoursReport) {
    return {
      employees: item.employees,
      month: item.month,
    };
  }
}
