import { City } from '../city/city'
import { Country } from '../country/country'
import { Resource } from '../resource'
import {Location} from '../location/location'

export class Address extends Resource {
    location: Location
    street: string
    street2: string
    postalCode: string
    city: City
    country: Country
}