import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { Avatar, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useHistory } from 'react-router';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import "moment/locale/ka"
import moment from "moment"

moment.locale("ka")

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 5,
            marginBottom: 20
        },
        avatar: {},
        media: {
            height: 0,
            paddingTop: '56.25%',
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    }),
);

export default function EmployeeRow(props) {
    const classes = useStyles();
    const { clicked, icon, employee, bday } = props
    const history = useHistory();
    return <ListItem style={{ cursor: 'pointer', margin: !bday ? 3 : 0 }}
        onClick={() => {
            window.location.href = `/profile/${employee.id}`
        }}>
        <ListItemAvatar>
            <Avatar
                alt={employee.fullName}
                src={employee.photo}
            />
        </ListItemAvatar>
        <ListItemText
            primary={employee.fullName} secondary={employee?.naviEmployee?.jobTitle} />

        {bday &&
            <ListItemText primary={moment(employee?.naviEmployee?.birthDay, 'DD-MM').format('DD MMM')} />
        }
        {!bday &&
            <InfoIcon style={{ cursor: 'pointer' }}
                onClick={() => {
                    window.location.href = `/profile/${employee.id}`
                }}
            />
        }
    </ListItem>


}

