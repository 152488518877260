


import { ResourceProvider } from "../../providers/resource.provider"
import { FAQ } from "./faq"
import { FAQSerializer } from "./serializer"

export class FAQProvider extends ResourceProvider<FAQ> {
    constructor() {
        super("rest_faq", new FAQSerializer())
    }
}

export const faqService = new FAQProvider()

