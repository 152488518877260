import {
    Button,
    Checkbox,
    createStyles,
    Dialog,
    FormControlLabel,
    FormGroup,
    Grid,
    makeStyles,
    SnackbarOrigin, TextField, Theme, Typography
} from "@material-ui/core";
import { ErrorMessage, Form, Formik, useField } from "formik"
import { connect } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from "react"
import MomentUtils from '@date-io/moment'
import "moment/locale/ka"
import moment from "moment"
import { validationSchemas } from "../../helpers/validations"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { QueryOptions } from "../../helpers/query.options"
import CloseIcon from '@material-ui/icons/Close';
import { setSnackBar } from '../../models/application/applicationActions'
import { TemporaryTransferRequest, TransferRequest } from "../../models/request/request";
import { transferRequestService } from "../../models/request/service";
import { EmbeddedPosition } from "../../models/user/user";
import { positionService } from "../../models/user/service";
import { branchService } from "../../models/branch/service";
import { Branch } from "../../models/branch/branch";



export interface State extends SnackbarOrigin {
    open: boolean;
    text: string;
}



const MyTextArea = ({ label, ...props }) => {
    const [field, meta] = useField(props as any);
    return (
        <>
            <TextField {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </>
    );
};


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        formControl: {
            minWidth: '100%',
        },
        selectEmpty: {
            marginTop: theme.spacing(10),
        },
    }),
);

const TransferRequestForm = (props) => {
    moment.locale("ka")
    const { onOpen, onClose, item, setSnackBar } = props
    const [branches, setBranches] = useState<Branch[]>([])
    const [branch, setBranch] = useState<Branch>()
    const [positions, setPositions] = useState<EmbeddedPosition[]>([])
    const [position, setPosition] = useState<EmbeddedPosition | null>()
    const classes = useStyles()
    const [localValues, setLocalValues] = useState<any>()
    const [ttr, setTtr] = useState<TemporaryTransferRequest>()


    const handleClose = () => {
        onClose({ open: false, item: null, reload: true })
    }

    const [fromDate, setFromDate] = useState<Date | null>(
        null
    );
    const [toDate, setToDate] = useState<Date | null>(
        null
    );
    useEffect(() => {
        console.log()
    }, [item])


    const getValues = (values) => {
        console.log(values)

        const ttro = new TransferRequest()
        ttro.employee = ttr.employee.id
        ttro.responsible = ttr.responsible.id
        ttro.dueDate = values.dueDate
        ttro.toPosition = values.toPosition
        ttro.fromPosition = ttr.employee.position.id
        ttro.reason = values.reason
        ttro.salary = values.salary
        ttro.fromBranch = ttr.employee.position.branch.id
        ttro.toBranch = values.toBranch
        transferRequestService.create(ttro).then((resp: any) => {
            if (!resp.status) {
                handleClose()
                props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'ჩანაწერი დაემატა წარმატებით', open: true })
                setTimeout(() => {
                    props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                }, 5000)
            } else {
                let text = 'დაფიქსირდა შეცდომა'
                props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text, open: true })
                setTimeout(() => {
                    props.setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
                }, 5000)
            }
        })

    }

    const getPositions = (search) => {
        const options = new QueryOptions();
        if (search && search.length) {
            options.search = search
        }
        options.pageSize = 100
        options.sort = "name"
        options.order = 'asc'
        positionService.listWithCounter(options).then(r => {
            setPositions(r.current)
        })
    }
    const getBranches = (search) => {
        const options = new QueryOptions();
        if (search && search.length) {
            options.search = search
        }
        options.pageSize = 100
        options.sort = "uid"
        options.order = 'asc'
        branchService.listWithCounter(options).then(r => {
            setBranches(r.current)
        })
    }

    useEffect(() => {
        getPositions(null)
        getBranches(null)
        if (item) {
            setTtr(null)
            setTtr(item)
        }
    }, [item])

    useEffect(() => {

    }, [localValues])

    useEffect(() => {
        if (item) {
            setLocalValues({
                employee: item?.employee?.fullName,
                dueDate: new Date(),
                fromPosition: `${item?.employee?.position?.currentPosition?.name} (${item?.employee?.position?.currentPosition?.department?.name})`,
                fromBranch: `${item.employee?.position?.branch?.uid}`,
                toBranch: "",
                toPosition: ""

            })
        }
    }, [item])

    const formik = <Formik validationSchema={validationSchemas['transferRequest']}
        initialValues={localValues}
        onSubmit={getValues}>
        {({ values, handleSubmit, handleChange, handleBlur, setFieldValue, errors }: any) => (
            <Form onSubmit={handleSubmit}>
                <Grid container spacing={2} style={{ marginTop: 10 }}>
                    {console.log(errors)}
                    <Grid item lg={6}>
                        <TextField
                            style={{ width: '100%', }}
                            id="outlined-basic"
                            label={'თანამშრომელი'}
                            variant="outlined"
                            type="text"
                            disabled={true}
                            name="employee"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.employee} />
                    </Grid>
                    <Grid item lg={6}>

                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="employee" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="responsible" >
                            {msg => <span className="error" style={{ marginTop: 10 }}>{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6}>
                        <TextField
                            style={{ width: '100%', height: 20 }}
                            id="outlined-basic"
                            label={'მიმდინარე ფილიალი'}
                            variant="outlined"
                            type="text"
                            disabled={true}
                            name="from_branch"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={`${values.fromBranch}`} />
                    </Grid>
                    <Grid item lg={6} style={{ marginTop: -16 }}>
                        {positions?.length &&
                            <Autocomplete

                                onInputChange={(event, value) => {
                                    setPosition(null)
                                }}
                                onChange={(event, value) => {
                                    const [selected] = branches.filter(option => `${option.uid}` === value)
                                    if (selected) {
                                        console.log(selected.id)
                                        console.log(values)
                                        setFieldValue("toBranch", selected.id)

                                    } else {
                                        setBranch(null)
                                    }
                                }}
                                freeSolo
                                disableClearable
                                options={branches?.map((option) => `${option.uid}`)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="ახალი ფილიალი"
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{ ...params.InputProps, type: 'search' }}
                                    />
                                )}
                            />
                        }
                    </Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}></Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="toBranch" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={6}>
                        <TextField
                            style={{ width: '100%', height: 20 }}
                            id="outlined-basic"
                            label={'მიმდინარე პოზიცია'}
                            variant="outlined"
                            type="text"
                            disabled={true}
                            name="from_position"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={`${values.fromPosition}`} />
                    </Grid>
                    <Grid item lg={6} style={{ marginTop: -16 }}>
                        {positions?.length &&
                            <Autocomplete
                                onInputChange={(event, value) => {
                                    setPosition(null)
                                }}
                                onChange={(event, value) => {
                                    const [selected] = positions.filter(option => `${option.name} (${option.department.name})` === value)
                                    if (selected) {
                                        setFieldValue("toPosition", selected.id)
                                        setPosition(selected)
                                    } else {
                                        setPosition(null)
                                    }
                                }}
                                freeSolo
                                disableClearable
                                options={positions?.map((option) => `${option.name} (${option.department.name})`)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="toPosition"
                                        label="ახალი პოზიცია"
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{ ...params.InputProps, type: 'search' }}
                                    />
                                )}
                            />
                        }
                    </Grid>

                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}></Grid>
                    <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                        <ErrorMessage name="toPosition" >
                            {msg => <span className="error">{msg}</span>}
                        </ErrorMessage>
                    </Grid>
                    <Grid item lg={9}>
                        <TextField
                            style={{ width: '100%', height: 50 }}
                            id="outlined-basic"
                            label={'პოზიციის ცვლილების საფუძველი'}
                            variant="outlined"
                            type="text"
                            name="reason"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.reason} />
                    </Grid>
                    <Grid item lg={3}>
                        <TextField
                            style={{ width: '100%', height: 50 }}
                            id="outlined-basic"
                            label={'ანაზღაურება'}
                            variant="outlined"
                            type="number"
                            name="salary"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.salary} />
                    </Grid>
                    <Grid item lg={3} style={{ padding: 3, paddingLeft: 20 }}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                format="DD-MM-yyyy"
                                margin="normal"
                                name="dueDate"
                                clearable
                                id="date-picker-inline"
                                label="დაწყების თარიღი"
                                value={values.dueDate}
                                onChange={(date: any) => {
                                    try {
                                        setFieldValue("dueDate", date._d)
                                        setLocalValues({ ...localValues, fromDate: date._d })
                                        setFromDate(date._d)
                                    } catch {

                                    }
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>


                    <Grid lg={12} sm={1} md={1}></Grid>
                    <Grid lg={4} sm={1} md={1}></Grid>
                    <Grid container lg={8} sm={11} md={11} spacing={1} style={{ marginTop: 40 }}>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                            <Button color="secondary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                onClick={() => onClose(false)}>გაქმება</Button>
                        </Grid>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 10 }}>
                            <Button color="primary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                type="submit">გაგზავნა</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Form>
        )}
    </Formik>
    return <div>

        <Dialog
            maxWidth='md'
            style={{ padding: 20 }}
            open={props.onOpen} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <div style={{ position: 'absolute', right: 10, top: 10 }}>
                <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        handleClose()
                    }} />
            </div>
            <div style={{ padding: 20 }}>
                <Typography variant="h5" component="div">
                    პოზიციის დროებითი ცვლილების/შეთავსების ფორმა
                </Typography>
                {localValues ? formik : <div></div>}

            </div>

        </Dialog>
    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(TransferRequestForm)