import { AddressSerializer } from '../address/serializer'
import { nameIdItemSerializer } from '../common/serializer'
import { Serializer } from '../serializer'
import { EmbeddedPositionSerializer, UserSerializer } from '../user/serializer'
import { Vacancy, VacancySubscription } from './vacancy'


export class VacancySerializer implements Serializer {
    fromJson(json: any) {
        const vacancy = new Vacancy()
        const positionSerializer = new EmbeddedPositionSerializer()
        const userSerializer = new UserSerializer()
        const addressSerializer = new AddressSerializer()
        vacancy.position = positionSerializer.fromJson(json.position)
        vacancy.responsible = userSerializer.fromJson(json.responsible)
        vacancy.created = json.created
        vacancy.userSubscribed = json.user_subscribed
        vacancy.address = addressSerializer.fromJson(json.address)
        vacancy.id = json.id
        vacancy.arrangementType = nameIdItemSerializer.fromJson(json.arrangement_type)
        vacancy.requiredSkills = json.required_skills.map(s => nameIdItemSerializer.fromJson(s))
        vacancy.salary = nameIdItemSerializer.fromJson(json.salary)
        vacancy.salaryAmount = json.salary_amount
        if (json.notes) {
            vacancy.notes = json.notes
        }
        return vacancy
    }

    toJson(item: any) {
        const obj: any = {}        
        return obj
    }
}
export class VacancySubscriptionSerializer implements Serializer {
    fromJson(json: any) {
        
        const vsub = new VacancySubscription()
        if (json.user) {
            const userSerializer = new UserSerializer()
            vsub.empooyee = userSerializer.fromJson(json.empooyee)
        }
        if (json.vacancy) {
            const vs = new VacancySerializer()
            vsub.vacancy = vs.fromJson(json.vacancy)
        }
        if (json.created) {
            vsub.created = json.created
        }
        vsub.id = json.id
        return vsub
    }

    toJson(item: VacancySubscription) {
        const obj: any = {
            vacancy_id: item.vacancy.id
        }        
        return obj
    }
}