import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { QueryOptions } from "../../helpers/query.options"
import "react-datepicker/dist/react-datepicker.css";
import { NameIdItem } from "../../models/common/common";
import { User } from "../../models/user/user";
import { branchEmployeeService } from "../../models/user/service";
import { createStyles, GridList, makeStyles, Theme } from "@material-ui/core";
import EmployeeRow from "../Employee/EmployeeRow";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridList: {
            paddingTop: 10,
            width: '100%',
        }
    })
);

const NewEmployeesSection = (props) => {
    const { setEmps, bdayEmployees } = props
    const classes = useStyles();
    const [events, setEvents] = useState<Event[]>([])
    const [employees, setEmployees] = useState<User[]>([])
    const [statuses, setStatuses] = useState<NameIdItem[]>([])

    useEffect(() => {
        getEmployees()
    }, [])
    const getEmployees = () => {
        const options = new QueryOptions()
        options.type = 'list'
        options.new = true
        options.sort = 'joined'
        options.order = 'desc'
        options.pageSize = 200
        branchEmployeeService.listWithCounter(options).then((employees: any) => {
            setEmployees(employees.current)
            setEmps(employees.current)

        })
    }

    return <GridList cellHeight={500} className={classes.gridList} style={{ height: employees?.length > 2 ? bdayEmployees?.length > 0 ? 240 : window.innerHeight - 260 : 'auto' }}>
        {employees?.map((empl, key) => {
            return <EmployeeRow employee={empl} key={key} bday={false} />
        })}
    </GridList>


}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, {})(NewEmployeesSection)