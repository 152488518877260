import React, { useEffect } from "react"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom"
import MainPage from "./pages/main"
import AboutPage from "./pages/about"
import { WorkingHoursListPage } from "./pages/Branch/WorkingHours/list"
import OrganizationChart from "./pages/organisational_chart"
import { connect } from 'react-redux'
import GuardedRoute from "./GuardedRoute"
import LoginPage from './pages/login'
import ProfilePage from './pages/profile'
import DismissRequestsTablePage from "./pages/Requests/DismissRequestsTablePage"
import DocumentRequestsTablePage from "./pages/Requests/DocumentRequestTablePage"
import TemporaryTransferRequestsTablePage from "./pages/Requests/TemporaryTransferRequestsTablePage"
import DocumentsPage from "./pages/documents"
import TemporaryTransferRequestDetailsPage from "./pages/Requests/TemporaryTransferRequestDetailsPage"
import BranchListPage from "./pages/Branch/BranchList/list"
import VacancyTableListPage from './pages/Vacancy/TableList'
import NewsTableListPage from './pages/news'
import EventsTableListPage from './pages/events'
import BranchPage from "./pages/Branch/BranchPage"
import VacationRequestTableList from "./components/Table/VacationRequestTableList"
import TransferRequestsTablePage from "./pages/Requests/TransferRequestsTablePage"
import EmployeesPage from "./pages/employees"
import BusinessTripRequestsTablePage from "./pages/Requests/BusinessTripRequestsTablePage"
import FaqPage from "./pages/FaqPage"
import Trainings from "./pages/Trainings"

export const canPerformAction = (user, permission, action): boolean => {
    if (user && user.roles) {
        const [model] = user?.roles?.filter(r => r.name === permission)
        if (model) {
            if (model.permissions[action]) {
                return true
            }
        }
    }
    return false
}

export const canAccessPage = (user, roles): boolean => {
    if (user && user.roles) {
        const [model] = user?.roles?.filter(r => roles.indexOf(r.name) > -1)
        if (model) {
            if (model.permissions.read) {
                return true
            } else {
                return false
            }
        }
        else {
            return false
        }
    }
    return false
}

const Routing = (props) => {
    let match = useRouteMatch()
    const { user, loggedIn } = props



    useEffect(() => {

    }, [user])
    return <Switch>
        <GuardedRoute auth={loggedIn} component={MainPage} path="/" exact />
        {/* <Route path="/" component={MainPage} exact />  */}
        <Route path="/about" component={AboutPage} exact />
        <Route path="/login" component={LoginPage} exact />
        <Route path="/faq" component={FaqPage} exact />
        <GuardedRoute access={canAccessPage(user, ['chart'])} auth={loggedIn} path="/organisational_chart" component={OrganizationChart} exact />
        {/* <Route path="/organisational_chart" component={OrganizationChart} exact /> */}
        <GuardedRoute
            access={canAccessPage(user, ['employee_profile'])}
            auth={loggedIn}
            path="/workingDayHoursForm"
            component={WorkingHoursListPage} exact />
        <GuardedRoute
            access={canAccessPage(user, ['employee_profile'])}
            auth={loggedIn}
            path="/profile/:id"
            component={ProfilePage} exact />
        <GuardedRoute
            access={canAccessPage(user, ['employee_profile'])}
            auth={loggedIn}
            path="/trainings"
            component={Trainings} exact />
        <GuardedRoute
            access={canAccessPage(user, ['employee_profile'])}
            auth={loggedIn}
            path="/employees"
            component={EmployeesPage} exact />
        <GuardedRoute access={canAccessPage(user, ['dismiss_request'])} auth={loggedIn} path="/dismissRequests" component={DismissRequestsTablePage} exact />
        <GuardedRoute access={canAccessPage(user, ['dismiss_request'])} auth={loggedIn} path="/documentRequests" component={DocumentRequestsTablePage} exact />
        <GuardedRoute access={canAccessPage(user, ['employee_profile'])} auth={loggedIn} path="/documents" component={DocumentsPage} exact />
        <GuardedRoute access={canAccessPage(user, ['temporary_transfer_request'])} auth={loggedIn} path="/temporaryTransferRequests" component={TemporaryTransferRequestsTablePage} exact />
        <GuardedRoute access={canAccessPage(user, ['transfer_request'])} auth={loggedIn} path="/transferRequests" component={TransferRequestsTablePage} exact />
        <GuardedRoute access={canAccessPage(user, ['vacation_request'])} auth={loggedIn} path="/vacationRequests" component={VacationRequestTableList} exact />
        <GuardedRoute access={canAccessPage(user, ['business_trip_request'])} auth={loggedIn} path="/businessTripRequests" component={BusinessTripRequestsTablePage} exact />
        <GuardedRoute access={canAccessPage(user, ['temporary_transfer_request'])} auth={loggedIn} path={`/temporaryTransferRequests/:id`} component={TemporaryTransferRequestDetailsPage} exact />
        <GuardedRoute
            access={true}
            auth={true}
            path={`/news`}
            component={NewsTableListPage} exact />
        <GuardedRoute
            access={true}
            auth={true}
            path={`/events`}
            component={EventsTableListPage} exact />
        <GuardedRoute access={canAccessPage(user, ['branch'])} auth={loggedIn} path={`/branches`} component={BranchListPage} exact />
        <GuardedRoute access={canAccessPage(user, ['branch'])} auth={loggedIn} path={`/branch/:id`} component={BranchPage} exact />
        <GuardedRoute
            access={canAccessPage(user, ['vacancy'])}
            auth={loggedIn}
            path={`/vacancies`}
            component={VacancyTableListPage} exact />

    </Switch>
}

const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user,
        loggedIn: state?.auth?.loggedIn
    }
}

export default connect(mapStateToProps)(Routing)