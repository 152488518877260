import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from "@material-ui/core/IconButton";
import { connect } from 'react-redux'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Drawer from "@material-ui/core/Drawer";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { useHistory } from "react-router-dom";
import FaceIcon from '@material-ui/icons/Face';
import RegisterFormDialog from "./Login/RegisterFormDialog";
import LoginFormDialog from "./Login/LoginFormDialog";
import { User } from "../models/user/user";
import { login, logout as LOGOUT } from '../models/user/authActions'
import { ConfirmationDialog } from '../components/Dialogs/ConfirmationDialog'
import { branchEmployeeService, logout } from "../models/user/service";
import { Snackbar } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { State } from '../models/application/applicationReducer'
import { setSnackBar } from '../models/application/applicationActions'
import { canAccessPage } from "../routing";
import BarSubcategoryMenu from "./Menu/BarSubcategoryMenu";
import { QueryOptions } from "../helpers/query.options";

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import logo from '../assets/images/logo.png'

const onSearch$: any = new Subject().pipe(
  debounceTime(1000)
)

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}



const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

type Anchor = "top" | "left" | "bottom" | "right";

const Bar = (props: any) => {
  let user;
  const { auth, login, LOGOUT, app } = props
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [openLogin, setOpenLogin] = React.useState(false);
  const [snackbar, setSnackbar] = useState<State>({ open: false, vertical: 'bottom', horizontal: 'center', text: '' })
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [employees, setEmployees] = useState<User[]>([]);
  const history = useHistory();

  const [confirmation, setConfirmation] = React.useState({ text: '', open: false })
  const { vertical, horizontal, open, text } = snackbar;


  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    onSearch$.subscribe(
      debounced => {
        getEmployees(debounced)
      }
    );

  }, [])

  useEffect(() => {
    if (auth.loggedIn) {
      setOpenLogin(false)
    }
  }, [auth])

  useEffect(() => {
    setSnackbar(app.snackBar)
  }, [app.snackBar])

  useEffect(() => {
    //getEmployees(null)
  }, [])



  const handleLogout = () => {
    setConfirmation({ text: 'ნამდვილად გსურთ სისტემიდან გამოსვლა?', open: true })
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  const [registerOpen, setRegisterOpen] = React.useState(false)
  const classesA = styles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    console.log(anchor)
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    console.log([anchor])
    setState({ ...state, [anchor]: open });
  };

  function redirect(url: string) {
    history.push(url);
  }

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"

      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="menu">
        <ListItem button onClick={() => {
          setState({ ...state, left: false });
          redirect("/")
        }}>
          <ListItemText >
            მთავარი

          </ListItemText>
        </ListItem>
        <ListItem button onClick={() => {
          setState({ ...state, left: false });
          redirect("/news")
        }}>
          <ListItemText>
            სიახლეები
          </ListItemText>
        </ListItem>

        <ListItem button onClick={() => {
          setState({ ...state, left: false });
          redirect("/events")
        }}>
          <ListItemText>
            ღონისძიებები
          </ListItemText>
        </ListItem>
        <ListItem button onClick={() => {
          setState({ ...state, left: false });
          redirect("/trainings")
        }}>
          <ListItemText>
            ტრენინგები
          </ListItemText>
        </ListItem>
        <ListItem button onClick={() => {
          setState({ ...state, left: false });
          redirect("/vacancies")
        }}>
          <ListItemText>
            ვაკანსიები
          </ListItemText>
        </ListItem>

        <ListItem button >
          <ListItemText>
            <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography >კომპანია</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography onClick={() => {
                  setState({ ...state, left: false });
                  redirect("/branches")
                }}>
                  სტრუქტურული ერთეულები
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography onClick={() => {
                  setState({ ...state, left: false });
                  redirect("/employees")
                }}>
                  თანამშრომლები
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography onClick={() => {
                  setState({ ...state, left: false });
                  redirect("/documents")
                }}>
                  დოკუმენტები
                </Typography>
              </AccordionDetails>


            </Accordion>
          </ListItemText>
        </ListItem>

        <ListItem button >
          <ListItemText>
            <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography >სერვისები</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography onClick={() => {
                  setState({ ...state, left: false });
                  redirect("/workingDayHoursForm")
                }}>
                  სამუშაო დროის აღრიცხვა
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography onClick={() => {
                  setState({ ...state, left: false });
                  redirect("/documentRequests")
                }}>
                  ცნობის მოთხოვნა
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography onClick={() => {
                  setState({ ...state, left: false });
                  redirect("/dismissRequests")
                }}>
                  გათავისუფლების მოთხოვნა
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography onClick={() => {
                  setState({ ...state, left: false });
                  redirect("/transferRequests")
                }}>
                  გადაყვანის მოთხოვნა
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography onClick={() => {
                  setState({ ...state, left: false });
                  redirect("/temporaryTransferRequests")
                }}>
                  დროებით გადაყვანის მოთხოვნა
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography onClick={() => {
                  setState({ ...state, left: false });
                  redirect("/vacationRequests")
                }}>
                  შვებულების მოთხოვნა
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography onClick={() => {
                  setState({ ...state, left: false });
                  redirect("/businessTripRequests")
                }}>
                  მივლინების მოთხოვნა
                </Typography>
              </AccordionDetails>
            </Accordion>
          </ListItemText>
        </ListItem>

      </List>
      <Divider />
    </div>
  );



  const handleSnackbarClose = () => {
    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: '', open: false })
  };


  const handleClickOpen = () => {
    setOpenLogin(true);
  };

  const getEmployees = (search) => {
    const options = new QueryOptions();
    if (search) {
      options.search = search
    }
    options.type = 'list'
    options.pageSize = 10
    branchEmployeeService.listWithCounter(options).then((employees: any) => {
      if (employees && employees.current) {
        setEmployees(employees.current)
      }
    })
  }


  return (
    <AppBar position="static">
      <Toolbar style={{ paddingLeft: '5%' }}>
        {windowDimensions.width < 1200 &&
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={toggleDrawer("left", true)}
            edge="start"
            className={classesA.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
        }
        {windowDimensions.width < 1200 &&
          <Drawer
            anchor="left"
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {list("left")}
          </Drawer>}

        <ConfirmationDialog open={confirmation.open} text={confirmation.text} callback={(c) => {
          setConfirmation({ text: '', open: false })
          if (c) {
            logout().then(r => {
              const user = new User();
              user.delete()
              LOGOUT()
              window.location.href = "/"
            })
          }
        }} />
        <RegisterFormDialog onOpen={registerOpen} onClose={setRegisterOpen} />
        <LoginFormDialog onOpen={openLogin} onClose={setOpenLogin} goToRegister={setRegisterOpen} />

        <img src={logo} style={{ width: 100, marginRight: 40, cursor: 'pointer' }}
          onClick={() => { redirect("/") }}
        />
        {windowDimensions.width > 1200 &&
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '65%' }}>


            <Button color="inherit" onClick={() => { redirect("/news") }}>
              სიახლეები
            </Button>
            <Button color="inherit" onClick={() => { redirect("/events") }}>
              ღონისძიებები
            </Button>
            <Button color="inherit" onClick={() => { redirect("/trainings") }}>
              ტრენინგები
            </Button>
            <Button color="inherit" onClick={() => { redirect("/vacancies") }}>ვაკანსიები</Button>






            <BarSubcategoryMenu title="კომპანია"
              user={auth?.user}
              items={
                [
                  {
                    target_url: "/branches",
                    name: "სტრუქტურული ერთეულები",
                    permissions: ['public']
                  },
                  {
                    target_url: "/employees",
                    name: "თანამშრომლები",
                    permissions: ['public']
                  },
                  {
                    target_url: "/documents",
                    name: "დოკუმენტები",
                    permissions: ['public']
                  }

                ]}
              clicked={(item) => {
                redirect(item.target_url)
              }} />

            {canAccessPage(auth?.user, ['dismiss_request', 'temporary_transfer_requests', 'working_day_hours']) &&
              <BarSubcategoryMenu title="სერვისები"
                user={auth?.user}
                items={
                  [

                    {
                      target_url: "/workingDayHoursForm",
                      name: "სამუშაო დროის აღრიცხვა",
                      permissions: ['public']
                    },
                    {
                      target_url: "/documentRequests",
                      name: "ცნობის მოთხოვნა",
                      permissions: ['dismiss_request']
                    },
                    {
                      target_url: "/dismissRequests",
                      name: "გათავისუფლების მოთხოვნა",
                      permissions: ['dismiss_request']
                    },
                    {
                      target_url: "/transferRequests",
                      name: "გადაყვანის მოთხოვნა",
                      permissions: ['transfer_request']
                    },
                    {
                      target_url: "/temporaryTransferRequests",
                      name: "დროებით გადაყვანის მოთხოვნა",
                      permissions: ['temporary_transfer_request']
                    },
                    {
                      target_url: "/vacationRequests",
                      name: "შვებულების მოთხოვნა",
                      permissions: ['vacation_request']
                    },
                    {
                      target_url: "/businessTripRequests",
                      name: "მივლინების მოთხოვნა",
                      permissions: ['business_trip_request']
                    }
                  ]}
                clicked={(item) => {
                  redirect(item.target_url)
                }} />
            }

          </div>}

        <div style={{ position: 'absolute', right: '0%', width: 200, display: 'inherit' }}>
          <div style={{ width: 35, height: 35, borderRadius: 30, overflow: 'hidden', cursor: 'pointer' }} onClick={() => {
            window.location.href = `/profile/${auth?.user.id}`
          }}>

            {auth?.user?.photo ? <img src={auth?.user?.photo} style={{ width: 35 }} /> : <FaceIcon />}

          </div>
          {!auth?.loggedIn && <Button color="inherit" onClick={() => { handleClickOpen() }}>
            <span className="material-icons">login</span>
          </Button>}
          {auth?.loggedIn && <Button color="inherit" onClick={() => { handleLogout() }}>
            <span className="material-icons">logout</span>
          </Button>}
        </div>


      </Toolbar>
      <Snackbar
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => {
              handleSnackbarClose()
            }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleSnackbarClose}
        message={text}
        key={vertical + horizontal}
      />
    </AppBar>
  );
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app
  }
}

export default connect(mapStateToProps, { login, LOGOUT, setSnackBar })(Bar)