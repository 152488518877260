import { SnackbarOrigin } from '@material-ui/core'
import { SET_SNACK_BAR} from './applicationActions'

export interface State extends SnackbarOrigin {
    open: boolean;
    text: string;
}



const initialState = {
    snackBar: { open: false, vertical: 'bottom', horizontal: 'center', text: '' } as State
}

export const applicationReducer = (state = initialState, action: { type: any; content:any}) => {
    switch (action.type) {
        case SET_SNACK_BAR:{
            const { snackBar } = action.content
            return { ...state, snackBar }
        }
        default:
            return state
    }
}