import { NameIdItem } from "../common/common";
import { Resource } from "../resource";

export class EmployeeFile extends Resource {
    name: string
    tags: any
    file: any
    employee: any
    filename: string
    created: string
    ext: string
}
export class EmployeeFileShare extends Resource {
    file: EmployeeFile | any
    employee: any
    employees: any
    created: string

}