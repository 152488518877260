import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FileExportDialog from '../../components/Dialogs/FileExportDialog'
import "moment/locale/ka"
import moment from "moment"

import { connect } from 'react-redux'
import { Checkbox, IconButton, TableHead, Typography } from '@material-ui/core';
import { setSnackBar } from '../../models/application/applicationActions'
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import { environment } from '../../environment/environment';

import { User } from '../../models/user/user';
import { useHistory } from 'react-router';
import { workingDayHoursReportService } from '../../models/WorkingDayHour/service';
import { WorkingDayHoursReport } from '../../models/WorkingDayHour/wokringDay';
import ExportReportFormDialog from '../Dialogs/ExportReportFormDialog';
import InfoIcon from '@material-ui/icons/Info';
import { canAccessPage } from '../../routing';
const BASE_URL = environment.basePath


const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});



moment.locale("ka")

const BranchEmployeeTableListComponent = (props) => {
    let history = useHistory()
    const { branchEmployees, setSnackBar, user } = props
    const classes = useStyles2();
    const [report, setReport] = useState<WorkingDayHoursReport>()
    const [reportMonth, setReportMonth] = useState<any>()
    const [dialog, setDialog] = useState({ open: false, item: null, reload: false, link: '' });
    const [dateDialog, setDateDialog] = useState({ open: false, item: null, reload: false });
    
    const [items, setItems] = useState<User[]>([]);
    const [selectedItems, setSelectedItems] = useState<User[]>([]);
    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(40);
    let emptyRows

    const ITEM_HEIGHT = 30;

    const options = [
        'ნამუშევარი საათების ექსპორტი'
    ];

    const [shown, setShown] = useState(false)
    const selectNode = () => {
        setShown(true)
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    useEffect(() => {
        setItems(branchEmployees)
    }, [branchEmployees])

    useEffect(() => {
        
    }, [selectedItems])
    useEffect(() => {
        if (reportMonth){
            const rep = report
            rep.month = reportMonth
            workingDayHoursReportService.create(report).then(r => {
                if (r.id && r.hasFile) {
                    const link = `${BASE_URL}/assets/files/reports/${r.id}.xls`
                    setDialog({ open: true, item: null, reload: false, link })
                } else {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'ჩანაწერები არ მოიძებნა.', open: true })
                    setTimeout(()=> {
                        setSnackBar({ vertical: 'bottom', horizontal: 'center', text: 'ჩანაწერები არ მოიძებნა.', open: false })
                    }, 3000)
                }
            })
            setDateDialog({ ...dateDialog, open: false})
        }
    }, [reportMonth])

    useEffect(() => {
        if (items.length) {
            
            emptyRows = rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);
        }
    }, [items])


    const tableContent = (<TableContainer component={Paper}>
        <FileExportDialog onOpen={dialog.open} onClose={(open) => {
            setDialog({ ...dialog, open })
        }}  link={dialog.link} />
        <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
                <TableRow>

                    <TableCell align="center" style={{ fontWeight: 'bold', width: 30, position: 'relative' }}>
                        {canAccessPage(user, ['working_day_supervisor']) && items.filter(i => i.selected).length > 0 &&
                            <div style={{ position: 'absolute', left: 22, top: 15 }}>
                                <IconButton
                                    color="primary"
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    size="small"

                                    onClick={handleClick}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5,
                                            width: 340,
                                        },
                                    }}
                                >
                                    {options.map((option) => (
                                        <MenuItem key={option} selected={option === 'დეტალები'} onClick={() => {
                                            const ids = selectedItems.map(i => i.id).toString()
                                            const report = new WorkingDayHoursReport()
                                            report.employees = ids
                                            setReport(report)
                                            setDateDialog({...dateDialog, open: true})
                                            handleClose()
                                        }} style={{ fontSize: 12 }}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>
                        }
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        სახელი და გვარი
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        პოზიცია
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        ორგ. ერთეული
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                        დეტალები
                    </TableCell>

                </TableRow>
            </TableHead>
            <TableBody>
                {
                    (rowsPerPage > 0
                        ? items?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort((a, b) => (a.id > b.id) ? 1 : -1)
                        : items.sort((a, b) => (a.id > b.id) ? 1 : -1)
                    ).map((item: User) => (
                        <TableRow key={item.id}>
                            <TableCell component="th" scope="row" style={{ width: 30 }}>
                                <Checkbox
                                    onChange={() => {
                                        item.selected = !item.selected
                                        const current = selectedItems.filter(s => s.id !== item.id)
                                        if (item.selected) {
                                            current.push(item)
                                        }
                                        setSelectedItems(current)
                                    }}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ width: 200 }}>
                                {item.fullName}
                            </TableCell>
                            <TableCell style={{ width: 60 }} align="center">
                                {item.naviEmployee?.jobTitleCode.name}
                            </TableCell>
                            <TableCell style={{ width: 60 }} align="center">
                                {item.naviEmployee?.orgUnitName}
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="center">
                                <InfoIcon style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            window.location.href = `/profile/${item.id}`  
                                        }}
                                    />
                            </TableCell>

                        </TableRow>
                    ))}
                {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </TableContainer >)

    return <Fragment>
        <ExportReportFormDialog 
        onOpen={dateDialog.open} onClose={(open) => {
            
            setDateDialog({ ...dateDialog, open })
            
        }}
        onSelected={(date)=> {
            setReportMonth(date)
        }}/>
        {items.length > 0 ? <div>{tableContent}</div> : <div style={{ marginTop: 20 }}>
            <Typography variant="h6">
                ჩანაწერები არ მოიძებნა
                </Typography>
        </div>}


    </Fragment >
}

const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user
    }
}

export default connect(mapStateToProps, { setSnackBar })(BranchEmployeeTableListComponent)