import { Branch } from '../branch/branch';
import { Resource } from '../resource'
import { User } from '../user/user';

export class WorkingDayHoursOption extends Resource {
    name: string;
}

export class WorkingDaysExport extends Resource {
    
}
export class WorkingDayHours extends Resource {
    responsible: User
    branch: Branch
    hours: number
    employee: User
    date: string
    option: WorkingDayHoursOption
    startTime: Date
    endTime: Date
    created: Date
    note: string
}
export class VacationWorkingDayHoursAdapter extends Resource {
    counters: VacationWorkingDayHours
    days: WorkingDayHours[]
    totalDaysCount: number
    daysUsed: Date[]
    totalVacationDays: number
}
export class VacationWorkingDayHours extends Resource {
    values: WorkingDayHours[]
}

export class WorkingDayHoursReport extends Resource {
    employees: string
    month: Date
    hasFile: boolean
}