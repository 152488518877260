import { Branch } from "../branch/branch";
import { NameIdItem } from "../common/common";
import { Resource } from "../resource";
import { EmbeddedPosition, User } from "../user/user";
import { WorkingDayHours } from "../WorkingDayHour/wokringDay";

export class DismissRequest extends Resource {
  created: Date;
  dueDate: Date;
  confirmationStatus: NameIdItem;
  reason: string;
  fromPosition: FromPosition;
  employee: User;
  responsible: User;
}
export class DocumentRequest extends Resource {
  created: Date;
  text: string;
  employee: User;
  responsible: User;
  withSalary: boolean;
}

export class Current extends Resource {
  created: string;
  due_date: string;
  from_position: FromPosition;
  reason: string;
}

export class FromPosition extends Resource {
  branch: Branch;
  currentPosition: EmbeddedPosition;
  supervisor: User;
  type: string;
}

export class TemporaryTransferRequest extends Resource {
  created: Date;
  fromDate: Date;
  toDate: Date;
  confirmationStatus: NameIdItem;
  notes: string;
  salary: number;
  fromPosition: FromPosition;
  toPosition: FromPosition;
  fromBranch: Branch;
  toBranch: Branch;
  responsibilityDischarge: boolean;
  carriesOutBothDuties: boolean;
  employee: User;
  responsible: User;
}
export class TransferRequest extends Resource {
  created: Date;
  dueDate: Date;
  confirmationStatus: NameIdItem;
  salary: number;
  reason: string;
  fromPosition: FromPosition;
  toPosition: FromPosition;
  fromBranch: Branch;
  toBranch: Branch;
  employee: User;
  responsible: User;
}
export class BusinessTripRequest extends Resource {
  created: Date;
  confirmationStatus: NameIdItem;
  fromDate: Date;
  toDate: Date;
  employee: User;
  responsible: User;
  destination: string;
  reason: string;
  accommodationFee: number;
  gasolineCost: number;
  transport: NameIdItem;
  transportationFee: number;
}
export class RequestConfirmation extends Resource {
  status: string;
  requestId: string;
}

export class VacationRequest extends Resource {
  created: string;
  employee: User;
  confirmationStatus: NameIdItem;
  validation: boolean;
  fromDate: number;
  toDate: number;
  requestedDays: string[];
  type: NameIdItem;
  workingDays: number;
  totalDays: number;
  daysLeft: number;
  chosenDays: string;
  usedHolidays: WorkingDayHours[];
}

export class RequestedVacationDay extends Resource {
  date: string;
  dayTimeStamp: string;
  isCanceled: boolean;
  isConfirmed: boolean;
  isConsumed: boolean;
}
