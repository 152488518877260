import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import "moment/locale/ka"
import moment from "moment"
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux'
import SimpleMenu from '../components/Menu/SimpleMenu';
import { Grid, Typography, Chip } from '@material-ui/core';
import {
    useLocation
} from "react-router-dom";
import { environment } from '../environment/environment';
import TrainingDialog from '../components/Dialogs/TrainingDialog';
import { QueryOptions } from '../helpers/query.options';
import { trainingCategoryService, trainingService } from '../models/training/service';
import { Training } from '../models/training/training';
import TrainingCard from '../components/TrainingCard'
import { NameIdItem } from '../models/common/common';

const BASE_URL = environment.basePath


const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

moment.locale("ka")

const TrainingsPage = (props) => {
    const [dialog, setDialog] = useState({ open: false, item: null, reload: false });
    const [page, setPage] = useState(0);
    const [items, setItems] = useState<Training[]>([]);
    const [news, setnews] = useState([]);
    const [selectedTags, setSelectedTags] = useState<NameIdItem[]>([])
    const [tags, setTags] = useState<NameIdItem[]>()
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, news.length - page * rowsPerPage);


    const getData = (category: string) => {
        const options = new QueryOptions()
        if (category) {
            options.tags = category
        }
        options.sort = 'sort'
        options.order = 'asc'
        trainingService.listWithCounter(options).then(r => {
            setItems(r.current)
        })
    }

    useEffect(() => {
        getData(null)
        const options = new QueryOptions()
        trainingCategoryService.list(options).then(r => {
            if (!r.status) {
                setTags(r.results)
            }
        })
    }, [])

    useEffect(() => {
        getData(selectedTags?.map(t => t.id).toString())
    }, [selectedTags])


    return <Fragment>

        <TrainingDialog onOpen={dialog.open} onClose={(open) => {
            setDialog({ ...dialog, open: false })
        }} item={dialog.item} />
        <Grid lg={3}></Grid>
        <Grid container spacing={2} style={{ marginTop: 10, marginBottom: 20 }} lg={6}>
            <Grid item lg={3} style={{ textAlign: "right" }}></Grid>
            <Grid item lg={3} style={{ textAlign: "right" }}></Grid>
        </Grid>
        <Grid container lg={12}>
            <Grid item lg={10} spacing={2} style={{padding: 20}}>
                <h4>ტრენინგები</h4>

            </Grid>
            
            
            {tags?.length > 0 && <Grid item xs={12} lg={3} spacing={6} style={{ padding: 20 }}>
                <SimpleMenu
                    title="გავფილტროთ"
                    icon={<SearchIcon style={{ fontSize: 30 }} />}
                    items={
                        selectedTags?.length ?
                            tags?.filter(t => selectedTags?.map(st => st.id).indexOf(t.id) < 0)
                                .map(r => { return { id: r.id, fullName: r.name, name: r.name } }) :
                            tags?.map(r => { return { id: r.id, fullName: r.name, name: r.name } })
                    }
                    clicked={(item) => {
                        setSelectedTags([...selectedTags?.filter(st => st.id !== item.id), item])
                    }}
                />
            </Grid>}
            <Grid item xs={12} lg={12} spacing={6} style={{ padding: 20 }}>
                {selectedTags?.map((tag, index) => {
                    return <Chip
                        label={tag.name}
                        onDelete={() => {
                            setSelectedTags([...selectedTags.filter(t => t.id !== tag.id)])
                        }}
                    />
                })}

            </Grid>

            {items?.length ? <Grid container lg={12}>

                {items.map((i, index) => {
                    return <Grid item lg={2} md={3} sm={4} xs={6}>
                        <TrainingCard item={i} clicked={(item) => {
                            setDialog({ ...dialog, open: true, item })
                        }} />
                    </Grid>
                })}
            </Grid> :
                <Grid lg={8}>
                    <Typography variant="h6">
                        ჩანაწერები არ მოიძებნა
                    </Typography>
                </Grid>}
        </Grid>
    </Fragment >
}

const mapStateToProps = (state: any) => {
    return {
        user: state?.auth?.user
    }
}

export default connect(mapStateToProps)(TrainingsPage)