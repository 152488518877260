import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { QueryOptions } from "../../helpers/query.options"
import "react-datepicker/dist/react-datepicker.css";
import { User } from "../../models/user/user";
import { branchEmployeeService } from "../../models/user/service";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import EmployeeRow from "../Employee/EmployeeRow";


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridList: {
            paddingTop: 20,
            width: '100%'
        },
        scroll: { height: 230, overflowY: 'scroll', width: '100%' },
        scroll_only: { height: window.innerHeight - 260, overflowY: 'scroll', width: '100%' },
        noScroll: { height: 230, width: '100%' },
        noScroll_only: { height: 230, width: '100%' }
    })
);


const BDaySection = (props) => {
    const classes = useStyles();
    const [employees, setEmployees] = useState<User[]>([])
    const { setEmps, newEmployees } = props

    useEffect(() => {
        getEmployees()
    }, [])
    useEffect(() => {
        console.log(newEmployees)
    }, [newEmployees])
    const getEmployees = () => {
        const options = new QueryOptions()
        options.type = 'list'
        options.sort = 'peronal_info__birth_date'
        options.order = 'desc'
        options.birthDate = "true"
        options.pageSize = 200
        branchEmployeeService.listWithCounter(options).then((employees: any) => {
            if (employees && employees.current) {
                setEmployees([...employees.current])
                setEmps(employees.current)
            }
        })
    }

    return <List className={employees?.length > 3 ? newEmployees?.length > 0 ? classes.scroll : classes.scroll_only : classes.noScroll}>

        {employees?.sort(function (a, b) {
            if (a.birthDayYear > b.birthDayYear) {
                return 1;
            }
            if (a.birthDayYear < b.birthDayYear) {
                return -1;
            }
            return 0;
        }).map((empl, key) => {
            return <ListItem>
                <EmployeeRow employee={empl} key={key} bday={true} />
            </ListItem>

        })}
    </List>


}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, {})(BDaySection)