const production = true;
export const environment = {
  production: false,
  basePath: production ? "https://api.icr.ge" : "http://localhost:8060",
  apiKey: "app_key",
  apiSecret: "app_secret",
  GooleMapsKey: "AIzaSyBqwF8rkrutCvJBq90YW7w1idb7uYMnQuM",
  imagesPath: "static/images",
  allowedDocumentFormats: [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "image/png",
    "image/jpeg",
  ],
};
