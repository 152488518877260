import {
    Button,
    Grid
} from "@material-ui/core";
import {Form, Formik } from "formik"
import { connect } from 'react-redux'
import 'date-fns';
import MomentUtils from '@date-io/moment'

import "moment/locale/ka"
import moment from "moment"

import React, { useEffect, useState } from "react"
import { validationSchemas } from "../../helpers/validations"
import { setSnackBar } from '../../models/application/applicationActions'
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const ExportReportForm = (props) => {
    moment.locale("ka")
    const [locale, setLocale] = useState("ka")
    const { onClose, item, onSelected } = props
    const [localValues, setLocalValues] = useState(null as any)
    


   

    useEffect(() => {
        setLocalValues({ name: '' })
    }, [item])

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClose = (item) => {
        setAnchorEl(null);
    };
    const getValues = (values) => {
        onSelected(values.month)
    }
   
    useEffect(() => {
        
        
    }, [])

    const formik = <Formik validationSchema={validationSchemas['exportReport']}
        initialValues={{

        }}
        onSubmit={getValues}>
        {({ values, handleSubmit, handleChange, setFieldValue, handleBlur, errors }: any) => (
            <Form onSubmit={(evt) => {
                console.log(errors, values)
                const [e] = Object.keys(errors);
                
                handleSubmit(evt)
            }}>
                <Grid container spacing={2} style={{}}>

                    <Grid item lg={12}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Grid container justify="space-around">
                                <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
                                    <DatePicker
                                        name="month"
                                        maxDate={new Date()}
                                        minDate={new Date('2021-05-01T03:24:00')}
                                        views={['year', "month"]}
                                        label="აირჩიეთ თვე"
                                        value={values.month}
                                        onChange={(value) => {
                                            setFieldValue("month", value.toDate())
                                        }}
                                        animateYearScrolling
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>


                    <Grid lg={4} sm={1} md={1}></Grid>
                    <Grid container lg={8} sm={11} md={11} style={{ padding: 20, marginTop: 20 }}>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 20 }}>
                            <Button color="secondary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                onClick={() => onClose(false)}>გაქმება</Button>
                        </Grid>
                        <Grid item lg={6} style={{ padding: 3, paddingLeft: 10 }}>
                            <Button color="primary" variant="contained" style={{ width: '98%', marginLeft: '0%', height: 50 }}
                                type="submit">ავირჩიოთ</Button>
                        </Grid>
                    </Grid>

                </Grid>

            </Form>
        )}
    </Formik>
    return <div style={{ padding: 20 }}>
        


        {localValues ? formik : <div></div>}




    </div>


}

const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        app: state.app
    }
}

export default connect(mapStateToProps, { setSnackBar })(ExportReportForm)



